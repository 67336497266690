import React from "react";
import ShortListedCandidateList from "./ShortListedCandidateList";

export default function Shortlist() {
  return (
    <div className="mydiv">
      <ShortListedCandidateList />
    </div>
  );
}
