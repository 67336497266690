import React, { useContext, useEffect, useState } from "react";
import Select, { Option } from "react-select";
import { experienceOption, specialityOptions } from "../../utils/jsonData";
import {
  getApi,
  getApiWithoutToken,
  postApi,
  putApi,
} from "../../utils/ApiMethods";
import { skillsCandidateOptions, timeZonesOption } from "../../utils/jsonData";

import Helmet from "react-helmet";
import { Loader } from "../../Components/Loader";
import MyModal from "./MyModal";
import csc from "country-state-city";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { validationSchema } from "./AccountDetailsFormValidation";

// sync with linkedin

export default function AccountDetails() {
  // linkedin sync
  // const [code, setCode] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [userAccountDetails, setUserAccountDetails] = useState({});
  const [image, setImage] = useState("");
  const [profile, setProfile] = useState("");
  const user = useSelector((state) => state?.user);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: "",

      lastName: "",
      //   email: userData.email,
      phone: "",
      bio: "",
      skills: "",
      github: "",
      stackoverflow: "",
      hackerrank: "",
      leetcode: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
      address: "",
      address2: "",
      speciality: "",
      timeZone: "",
      experience: "",
      profilePic: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const updateUser = await handleUpdateAccount(values);

        if (updateUser.status === 200) {
          toast.success("Account details updated successfully");
        } else {
        }
      } catch (error) {}
    },
  });

  const getUserAccountDetails = async () => {
    const response = await getApi(`accountDetails/userDetails`);
    const data = response.data;
    // console.log("get user account details",data);
    setUserAccountDetails(data.data);
  };

  useEffect(() => {
    // if(!userData.status) {
    //   console.log("hello")
    //     localStorage.removeItem("token");
    //     localStorage.removeItem("ltoken");
    //     setIsLoggedIn(false);
    //     navigate("/sign-up");
    // }
    if (user?.email) getUserAccountDetails();
  }, [user?.email]);
  useEffect(() => {
    // console.log(userAccountDetails["accountDetails.userProfile"])
    if (userAccountDetails) {
      const cscCountry = csc
        .getAllCountries()
        .find(
          (country) =>
            country.name == userAccountDetails["accountDetails.country"]
        );
      const cscState = csc
        .getStatesOfCountry(cscCountry && cscCountry.id)
        .find(
          (state) => state.name == userAccountDetails["accountDetails.state"]
        );
      const cscCity = csc
        .getCitiesOfState(cscState && cscState.id)
        .find((city) => city.name == userAccountDetails["accountDetails.city"]);

      setImage(userAccountDetails["accountDetails.userProfile"]);
      formik.setFieldValue(
        "firstName",
        userAccountDetails["accountDetails.firstName"]
      );
      formik.setFieldValue(
        "lastName",
        userAccountDetails["accountDetails.lastName"]
      );
      // formik.setFieldValue("email",userData.email)
      formik.setFieldValue("phone", userAccountDetails["accountDetails.phone"]);
      formik.setFieldValue("bio", userAccountDetails["accountDetails.bio"]);
      formik.setFieldValue(
        "skills",
        userAccountDetails["accountDetails.skills"] &&
          userAccountDetails["accountDetails.skills"].map((el) => ({
            value: el,
            label: el,
          }))
      );
      formik.setFieldValue(
        "github",
        userAccountDetails["accountDetails.github"]
      );
      formik.setFieldValue(
        "stackoverflow",
        userAccountDetails["accountDetails.stackOverFlow"]
      );
      formik.setFieldValue(
        "hackerrank",
        userAccountDetails["accountDetails.hackerRank"]
      );
      formik.setFieldValue(
        "leetcode",
        userAccountDetails["accountDetails.leetCode"]
      );
      // formik.setFieldValue(
      //   "country",
      //   userAccountDetails["accountDetails.country"]
      // );
      formik.setFieldValue(
        "country",
        userAccountDetails["accountDetails.country"]
          ? {
              ...cscCountry,
              value: cscCountry && cscCountry.id,
              label: cscCountry && cscCountry.name,
            }
          : userAccountDetails["accountDetails.country"]
      );
      formik.setFieldValue(
        "state",
        userAccountDetails["accountDetails.state"]
          ? {
              ...cscState,
              value: cscState && cscState.id,
              label: cscState && cscState.name,
            }
          : userAccountDetails["accountDetails.state"]
      );
      formik.setFieldValue(
        "city",
        userAccountDetails["accountDetails.city"]
          ? {
              ...cscCity,
              value: cscCity && cscCity.id,
              label: cscCity && cscCity.name,
            }
          : userAccountDetails["accountDetails.city"]
      );
      formik.setFieldValue(
        "zipcode",
        userAccountDetails["accountDetails.zipCode"]
      );
      formik.setFieldValue(
        "address",
        userAccountDetails["accountDetails.addressLine1"]
      );
      formik.setFieldValue(
        "address2",
        userAccountDetails["accountDetails.addressLine2"]
      );
      formik.setFieldValue("timeZone", userAccountDetails.timeZone);
      formik.setFieldValue("speciality", userAccountDetails.speciality);
      formik.setFieldValue("experience", userAccountDetails.experience);
      formik.setFieldValue("profilePic", userAccountDetails.profilePic);
    }
  }, [userAccountDetails]);

  const handleUpdateAccount = async (values) => {
    let result = false;
    try {
      setLoading(true);
      const response = await putApi(`accountDetails/updateAccountDetails`, {
        // "id":userData.id,
        userProfile: image,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        bio: values.bio,
        skills: values.skills.map((el) => el.value),
        github: values.github,
        stackOverFlow: values.stackoverflow,
        hackerRank: values.hackerrank,
        leetCode: values.leetcode,
        country: values.country.label,
        state: values.state.label,
        city: values.city.label,
        zipCode: values.zipcode,
        addressLine1: values.address,
        addressLine2: values.address2,
        timeZone: values.timeZone,
        speciality: values.speciality,
        experience: values.experience,
        profilePic: values.profilePic,
      });

      const data = response.data;
      if (data.status == 1) {
        toast.success(data.message);
        result = true;
      } else {
        toast.success(data.message);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error("Something went wrong");
    }
    setLoading(false);
    setImage("");
    return result;
  };

  // image cross button logic
  // const [delImage, setDelImage] = useState(false);

  const handleDeleteImage = () => {
    setImage("");
    setProfile({});
  };

  const handleFilechange = async (e) => {
    try {
      let file = e.target.files[0];
      if (!file) {
        return;
      }
      const type = file.type.split("/")[1];
      const imageType = ["jpeg", "jpg", "png"];
      const validImageType = imageType.includes(type);
      if (!validImageType) {
        formik.setErrors({
          profilePic: "upload a valid image : jpeg, png, jpg",
        });
        return;
      } else {
        if (file.size > 1000001) {
          formik.setErrors({ profilePic: "image size is more than 1 MB" });
          setProfile("");
          return;
        } else {
          let fr = new FileReader();
          console.log(typeof fr.result);
          fr.readAsDataURL(file);
          fr.onload = function () {
            setProfile(fr.result);
          };
          formik.setErrors({});
        }

        formik.setErrors({});
      }

      const formdata = new FormData();
      formdata.append("file", file);
      const response = await postApi(
        "testDetail/createTestDetailsUploadImage",
        formdata
      );
      const data = response.data;

      // console.log("onchange image 11111111111111111",data)
      // console.log(typeof data.Image);
      setImage(data.Image);
    } catch (err) {
      toast.error("Something went Wrong");
    }
  };

  const handleSkillsChange = (selectedOption) => {
    formik.setFieldValue("skills", selectedOption);
  };

  // sync with linkedin
  // console.log(profile, 'urllll')

  const getToken = async (token) => {
    try {
      const data = await getApiWithoutToken(
        `syncLinkedlnProfile?ltoken=${token}`
      );
      const firstName = data?.data?.data?.localizedFirstName;
      const lastName = data?.data?.data?.localizedLastName;
      const profile =
        data?.data?.data?.profilePicture?.["displayImage~"]?.elements[1]
          ?.identifiers[0]?.identifier;
      formik.setFieldValue("firstName", firstName);
      formik.setFieldValue("lastName", lastName);
      console.log(profile);
      if (profile) {
        setImage(profile);
        setProfile(profile);
      }

      toast.success("Synced successfully");
    } catch (e) {
      toast.error("Something went wrong.");
    }
  };
  // console.log("profile pic",profile);
  const syncWithLinkedin = async () => {
    const ltoken = localStorage.getItem("ltoken");
    if (Boolean(ltoken)) {
      getToken(ltoken);
    } else {
      window.location.href = `${process.env.REACT_APP_BaseUrl}auth/linkedin`;
    }
  };

  const countries = csc.getAllCountries();
  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));
  const updatedStates = (countryId) => {
    return csc
      .getStatesOfCountry(countryId)
      .map((state) => ({ label: state.name, value: state.id, ...state }));
  };
  const updatedCities = (stateId) => {
    return csc
      .getCitiesOfState(stateId)
      .map((city) => ({ label: city.name, value: city.id, ...city }));
  };
  return (
    <>
      {/* <Header /> */}
      <Helmet>
        <script>initializeJSRoot()</script>
      </Helmet>
      <section className="container">
        <div className="row">
          <div className="col-md-8 offset-lg-1 pb-5 mb-lg-2 pt-md-2 mt-n3 mt-md-0">
            <div className="ps-md-3 ps-lg-0 mt-md-2 pt-md-4 pb-md-2">
              {/* <h1 className="h2 pt-xl-1 pb-3 d-flex align-items-center">
                Account Details{" "}
                <span
                  onClick={syncWithLinkedin}
                  className="badge bg-success text-white fs-sm text-decoration-none ms-4 p-2 d-inline-block"
                  style={{ cursor: "pointer" }}
                >
                  Sync with LinkedIn
                </span>
              </h1> */}
              {/* Basic info */}
              <h2 className="h5 text-primary mb-4">Basic info</h2>
              <form
                className="needs-validation border-bottom pb-3 mb-4 pb-lg-4"
                noValidate=""
                onSubmit={formik.handleSubmit}
              >
                <div className="col-sm-6 mb-4">
                  <label htmlFor="profile" className="form-label fs-base">
                    Profile Image{" "}
                    <small className="text-muted">
                      <br />
                      (optional: upload only jpeg, png, jpg and less than 1 mb)
                    </small>
                  </label>

                  <input
                    value={formik.values.profilePic}
                    // value=""
                    id="profileImage"
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    name="profile"
                    className="form-control"
                    onChange={handleFilechange}
                    hidden={true}
                    inputProps={{ accept: "image/*" }}
                  />
                  <div className="text-danger">{formik.errors.profilePic}</div>
                </div>
                <div
                  style={{ width: "200px", height: "200px" }}
                  className="cu-18"
                >
                  <label
                    className="col-sm-4 bg-repeat-0 bg-position-center bg-size-cover"
                    htmlFor="profileImage"
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "0.3px solid #191313",
                      cursor: "pointer",
                      borderRadius: "10px",
                      backgroundImage: `${
                        profile
                          ? `url(${encodeURI(profile)})`
                          : userAccountDetails["accountDetails.userProfile"]
                          ? userAccountDetails[
                              "accountDetails.userProfile"
                            ].startsWith("http")
                            ? "url(" +
                              encodeURI(
                                userAccountDetails["accountDetails.userProfile"]
                              ) +
                              ")"
                            : "url(" +
                              encodeURI(
                                process.env.REACT_APP_BaseUrl +
                                  userAccountDetails[
                                    "accountDetails.userProfile"
                                  ]
                              ) +
                              ")"
                          : "url(/placeholder.jpg)"
                      }`,
                    }}
                  ></label>

                  <i
                    onClick={handleDeleteImage}
                    className="bx bxs-trash fs-xl me-2"
                  ></i>
                </div>
                <div className="row pb-2">
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="fn" className="form-label fs-base">
                      First name
                    </label>
                    <input
                      type="text"
                      id="fn"
                      className="form-control form-control-lg"
                      // defaultValue="John"
                      name="firstName"
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                    />

                    <div className="text-danger">
                      {formik.touched.firstName && formik.errors.firstName}
                    </div>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="sn" className="form-label fs-base">
                      Last name
                    </label>
                    <input
                      type="text"
                      id="sn"
                      className="form-control form-control-lg"
                      name="lastName"
                      onChange={formik.handleChange}
                      value={formik.values.lastName}
                    />
                    <div className="text-danger">
                      {formik.touched.lastName && formik.errors.lastName}
                    </div>
                    {/* <div className="invalid-feedback">
                                    Please enter your second name!
                                </div> */}
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="email" className="form-label fs-base">
                      Email address
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control form-control-lg"
                      placeholder="jonny@email.com"
                      value={user?.email}
                      disabled
                    />
                    <div className="text-danger">
                      {formik?.touched?.email && formik?.errors?.email}
                    </div>
                    {/* <div className="invalid-feedback">
                                    Please provide a valid email address!
                                </div> */}
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="phone" className="form-label fs-base">
                      Phone <small className="text-muted">(optional)</small>
                    </label>
                    <input
                      name="phone"
                      type="text"
                      id="phone"
                      className="form-control form-control-lg"
                      data-format="custom"
                      data-delimiter="-"
                      data-blocks="2 3 4"
                      data-numeral={9}
                      placeholder="00-000-0000"
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                    />
                    <div className="text-danger">
                      {formik.touched.phone && formik.errors.phone}
                    </div>
                  </div>

                  <div className="col-12 mb-4">
                    <label htmlFor="bio" className="form-label fs-base">
                      Bio <small className="text-muted">(optional)</small>
                    </label>
                    <textarea
                      id="bio"
                      name="bio"
                      className="form-control form-control-lg"
                      rows={4}
                      placeholder="Add a short bio..."
                      // defaultValue={""}
                      onChange={formik.handleChange}
                      value={formik.values.bio}
                    />
                    <div className="text-danger">
                      {formik.touched.bio && formik.errors.bio}
                    </div>
                  </div>
                  <div className="col-12 mb-4">
                    <label htmlFor="bio" className="form-label fs-base">
                      Skills
                    </label>

                    <Select
                      className="w-100"
                      placeholder={<div>Skills</div>}
                      name="skills"
                      options={skillsCandidateOptions}
                      isMulti={true}
                      value={formik.values.skills}
                      onChange={handleSkillsChange}
                    />
                    <div className="text-danger">
                      {formik.touched.skills && formik.errors.skills}
                    </div>
                  </div>
                </div>
                {/* <div className="d-flex mb-3">
                                <button type="reset" className="btn btn-secondary me-3">
                                Cancel
                                </button>
                                <button type="submit" className="btn btn-primary">
                                Save changes
                                </button>
                            </div> */}
                <div className="row">
                  <div className="col-12 col-sm-6 col-lg-6 mb-3 mb-lg-3 ">
                    <label htmlFor="speciality" className="form-label fs-base">
                      Speciality
                    </label>
                    <Select
                      placeholder={<div>Speciality</div>}
                      name="speciality"
                      options={specialityOptions}
                      value={{
                        value: formik.values.speciality,
                        label: formik.values.speciality,
                      }}
                      onChange={(option) =>
                        formik.setFieldValue("speciality", option.value)
                      }
                    />
                    <div className="text-danger">
                      {formik.touched.speciality && formik.errors.speciality}
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-lg-6 mb-3 mb-lg-0">
                    <label htmlFor="experience" className="form-label fs-base">
                      Experience
                    </label>
                    <input
                      type="number"
                      placeholder="Enter your experience"
                      name="experience"
                      className="form-control"
                      value={formik.values.experience}
                      onWheel={(e) => e.target.blur()}
                      min={0}
                      max={50}
                      onChange={formik.handleChange}
                    />
                    <div className="text-danger">
                      {formik.touched.experience && formik.errors.experience}
                    </div>
                  </div>
                </div>
                {/* <div className="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-3 ">
            <label htmlFor="specialization" className="form-label fs-base">
                                    Specialization 
                                </label>
                <input type='text' className='form-control' name='specialization'/>
              </div> */}

                {/* Social info */}

                <h2 className="h5 text-primary mb-4">Social profiles</h2>
                {/* <form
                            className="needs-validation border-bottom pb-3 pb-lg-4"
                            noValidate=""
                            > */}
                <div className="row pb-2">
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="fn" className="form-label fs-base">
                      Github
                    </label>
                    <input
                      name="github"
                      type="text"
                      id="fn"
                      className="form-control form-control-lg"
                      onChange={formik.handleChange}
                      value={formik.values.github}
                    />
                    <div className="text-danger">
                      {formik.touched.github && formik.errors.github}
                    </div>
                    {/* <div className="invalid-feedback">
                                        Please enter your first name!
                                    </div> */}
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="fn" className="form-label fs-base">
                      Stack Overflow
                    </label>
                    <input
                      name="stackoverflow"
                      type="text"
                      id="fn"
                      className="form-control form-control-lg"
                      onChange={formik.handleChange}
                      value={formik.values.stackoverflow}
                    />
                    <div className="text-danger">
                      {formik.touched.stackoverflow &&
                        formik.errors.stackoverflow}
                    </div>
                    {/* <div className="invalid-feedback">
                                        Please enter your first name!
                                    </div> */}
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="fn" className="form-label fs-base">
                      HackerRank
                    </label>
                    <input
                      name="hackerrank"
                      type="text"
                      id="fn"
                      className="form-control form-control-lg"
                      onChange={formik.handleChange}
                      value={formik.values.hackerrank}
                    />
                    <div className="text-danger">
                      {formik.touched.hackerrank && formik.errors.hackerrank}
                    </div>
                    {/* <div className="invalid-feedback">
                                        Please enter your first name!
                                    </div> */}
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="fn" className="form-label fs-base">
                      Leet Code
                    </label>
                    <input
                      name="leetcode"
                      type="text"
                      id="fn"
                      className="form-control form-control-lg"
                      onChange={formik.handleChange}
                      value={formik.values.leetcode}
                    />
                    <div className="text-danger">
                      {formik.touched.leetcode && formik.errors.leetcode}
                    </div>
                    {/* <div className="invalid-feedback">
                                        Please enter your first name!
                                    </div> */}
                  </div>
                </div>
                {/* <div className="d-flex mb-3">
                                <button type="reset" className="btn btn-secondary me-3">
                                Cancel
                                </button>
                                <button type="submit" className="btn btn-primary">
                                Save changes
                                </button>
                            </div> */}
                {/* </form> */}
                {/* Address */}
                <h2 className="h5 text-primary pt-1 pt-lg-3 my-4">Address</h2>
                {/* <form
                            className="needs-validation border-bottom pb-2 pb-lg-4"
                            noValidate=""
                            > */}
                <div className="row pb-2">
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="country" className="form-label fs-base">
                      Country
                    </label>

                    <Select
                      id="country"
                      placeholder={<div>Choose Country</div>}
                      name="country"
                      label="country"
                      options={updatedCountries}
                      value={formik.values.country ? formik.values.country : ""}
                      onChange={(value) => {
                        formik.setFieldValue("country", {
                          ...value,
                          value: value.value,
                          label: value.label,
                        });
                      }}
                    />
                    <div className="text-danger">
                      {formik.touched.country && formik.errors.country}
                    </div>
                    {/* <div className="invalid-feedback">
                                    Please choose your country!
                                </div> */}
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="state" className="form-label fs-base">
                      State
                    </label>
                    {/* <select
                      id="state"
                      className="form-select form-select-lg"
                      onChange={formik.handleChange}
                      value={formik.values.state}
                      // value={{
                      //   label: formik.values.state,
                      //   value: formik.values.state,
                      // }}
                      name="state"
                    >
                      <option value="" disabled="">
                        Choose state
                      </option>
                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                      <option value="Arunachal Pradesh">
                        Arunachal Pradesh
                      </option>
                      <option value="Assam">Assam</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Goa">Goa</option>

                      {/* <option value='Arizona'>Arizona</option>
                      <option value='California'>California</option>
                      <option value='Iowa'>Iowa</option>
                      <option value='Florida'>Florida</option>
                      <option value='Michigan'>Michigan</option>
                      <option value='Texas'>Texas</option> */}
                    {/* </select> */}
                    <Select
                      id="state"
                      name="state"
                      placeholder={<div>Choose State</div>}
                      options={updatedStates(
                        formik.values.country
                          ? formik.values.country.value
                          : null
                      )}
                      value={formik.values.state ? formik.values.state : ""}
                      onChange={(value) => {
                        formik.setFieldValue("state", {
                          ...value,
                          value: value.value,
                          label: value.label,
                        });
                      }}
                    />

                    <div className="text-danger">
                      {formik.touched.state && formik.errors.state}
                    </div>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="city" className="form-label fs-base">
                      City
                    </label>
                    {/* <select
                      id="city"
                      name="city"
                      className="form-select form-select-lg"
                      onChange={formik.handleChange}
                      value={formik.values.city}
                    >
                      <option value="" disabled="">
                        Choose city
                      </option>
                      <option value="Boston">Boston</option>
                      <option value="Chicago">Chicago</option>
                      <option value="Los Angeles">Los Angeles</option>
                      <option value="Miami" selected="">
                        Miami
                      </option>
                      <option value="New York">New York</option>
                      <option value="Philadelphia">Philadelphia</option>
                    </select> */}
                    <Select
                      id="city"
                      name="city"
                      placeholder={<div>Choose City</div>}
                      options={updatedCities(
                        formik.values.state ? formik.values.state.value : null
                      )}
                      value={formik.values.city}
                      onChange={(value) => {
                        formik.setFieldValue("city", value);
                      }}
                    />
                    <div className="text-danger">
                      {formik.touched.city && formik.errors.city}
                    </div>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="zip" className="form-label fs-base">
                      ZIP code
                    </label>
                    <input
                      type="text"
                      name="zipcode"
                      id="zip"
                      className="form-control form-control-lg"
                      onChange={formik.handleChange}
                      value={formik.values.zipcode}
                    />
                    <div className="text-danger">
                      {formik.touched.zipcode && formik.errors.zipcode}
                    </div>
                    {/* <div className="invalid-feedback">
                                    Please enter your ZIP code!
                                </div> */}
                  </div>

                  <div className="col-sm-6 mb-4">
                    <label htmlFor="timeZone" className="form-label fs-base">
                      Time zone
                    </label>
                    <Select
                      className="w-100 my-2"
                      placeholder={<div>Time Zone</div>}
                      name="timeZone"
                      options={timeZonesOption}
                      value={{
                        value: formik.values.timeZone,
                        label: formik.values.timeZone,
                      }}
                      onChange={(option) =>
                        formik.setFieldValue("timeZone", option.value)
                      }
                    />
                    <div className="text-danger">
                      {formik.touched.timeZone && formik.errors.timeZone}
                    </div>
                    {/* <div className="invalid-feedback">
                                    Please enter your ZIP code!
                                </div> */}
                  </div>

                  <div className="col-12 mb-4">
                    <label htmlFor="address1" className="form-label fs-base">
                      Address line 1
                    </label>
                    <input
                      id="address1"
                      name="address"
                      className="form-control form-control-lg"
                      onChange={formik.handleChange}
                      value={formik.values.address}
                    />
                    <div className="text-danger">
                      {formik.touched.address && formik.errors.address}
                    </div>
                  </div>
                  <div className="col-12 mb-4">
                    <label htmlFor="address2" className="form-label fs-base">
                      Address line 2{" "}
                      <small className="text-muted">(optional)</small>
                    </label>
                    <input
                      id="address2"
                      name="address2"
                      className="form-control form-control-lg"
                      onChange={formik.handleChange}
                      value={formik.values.address2}
                    />
                    <div className="text-danger">
                      {formik.touched.address2 && formik.errors.address2}
                    </div>
                  </div>
                </div>
                <hr className="mb-4" />
                <div className="d-flex mb-3">
                  {/* <button type="reset" className="btn btn-secondary me-3">
                    Cancel
                  </button> */}
                  {/* <button type="submit" className="btn btn-primary">
                                Save changes
                                </button> */}
                  <div className="d-grid">
                    {loading ? (
                      <button disabled={true} className="btn btn-primary me-3">
                        <Loader style={{ width: "1rem", height: "1rem" }} />
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-primary ">
                        Save Changes
                      </button>
                    )}
                  </div>
                  <div></div>
                </div>
              </form>
              {/* Delete account */}
              <h2 className="h5 text-primary pt-1 pt-lg-3 mt-4">
                Delete account
              </h2>
              <p>
                When you delete your account, your public profile will be
                deactivated immediately. If you change your mind before the 14
                days are up, sign in with your email and password, and we’ll
                send you a link to reactivate your account.
              </p>
              <div className="form-check mb-4">
                <input
                  type="checkbox"
                  id="delete-account"
                  className="form-check-input"
                />
                <label
                  htmlFor="delete-account"
                  className="form-check-label fs-base"
                >
                  Yes, I want to delete my account
                </label>
              </div>
              <button type="button" className="btn btn-danger">
                Delete
              </button>
            </div>
          </div>
        </div>
        {/* <Button
          onClick={() => {
            dispatch({ type: SET_MODAL, payload: { mymodal: true } });
          }}
        >
          clickME
        </Button> */}
        <MyModal />
      </section>
      {/* <Footer /> */}
    </>
  );
}
