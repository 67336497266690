import "./steps.scss";

import React from "react";

function Steps({
  count = 1,
  isActive = false,
  style = { backgroundColor: "#FFFFFF", color: "#6366f1" },
}) {
  return (
    <div>
      <div
        className="steps d-flex justify-content-center align-items-center"
        style={isActive ? { backgroundColor: "#6366F1" } : style}
      >
        <label style={isActive ? { color: "#FFFFFF" } : {}}>{count}</label>
      </div>
    </div>
  );
}

export default Steps;
