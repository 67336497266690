import {} from "../../../Redux/Actions/types";

import { Button } from "react-bootstrap";
import Modal from "../../../Components/common/modal/Modal";
import React from "react";
import confirmImg from "../../../Assets/img/employer/confirm-modal.png";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

function ConfirmModal(props) {
  const currentModal = "employer_review";
  const modal = useSelector((state) => state.modal);
  const show = modal[currentModal];
  const navigate = useNavigate();
  const onHandleClose = () => {
    navigate("/employer/calendly/");
  };
  const Content = () => {
    return (
      <div className="d-flex flex-column align-items-center">
        <img src={confirmImg} alt="none" />
        <h6 className="text-center my-1">
          Your Call with Visceral is Successfully placed
        </h6>
        <label className="text-center my-1">
          We’ve sent the details on your provided email address
        </label>
        <Button className="" onClick={() => onHandleClose()}>
          Back to Calendly
        </Button>
      </div>
    );
  };
  const { createModal, ModalSize } = Modal;

  return createModal({
    Content: <Content />,
    modalConfig: { size: ModalSize?.sm },
    onHandleClose,
    show: show ? true : false,
  });
}

export default ConfirmModal;
