import * as Yup from "yup";
export const contactFormValidation = Yup.object().shape({
  name: Yup.string()
    .max(50, "Must be 50 characters or less")
    .required("Please enter your name!"),
  email: Yup.string()
    .email("Invalid Email address")
    .required("Please enter your email!"),
  phone: Yup.number("Must be a valid phone no.")
    .typeError("Must be valid phone no.")
    .nullable()
    .required("Please enter your phone no.")
    .min(1000000000, "Must be a valid phone no.")
    .max(9999999999, "Must be a valid phone no."),
  company: Yup.string()
    .max(50, "Must be 50 characters or less")
    .required("Please enter your company name!"),
});
