import {
  RESET_EMPLOYER_SUITABLE_CANDIDATE,
  SET_EMPLOYER_SUITABLE_CANDIDATE,
} from "../../Actions/types";

import initialState from "../../InitialState/initialState";

export default function suitablecandidateReducer(
  state = initialState?.employer?.suitablecandidate,
  { type, payload }
) {
  switch (type) {
    case SET_EMPLOYER_SUITABLE_CANDIDATE:
      return { ...state, ...payload };
    case RESET_EMPLOYER_SUITABLE_CANDIDATE:
      return initialState?.employer?.suitablecandidate;
    default:
      return state;
  }
}
