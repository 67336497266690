import React from "react"
import { Link, useLocation, useSearchParams } from "react-router-dom"
import ThankImage from "../../Assets/img/thankyou/thankyou.svg"
import Footer from "../../Components/Footer/Footer"
import Header from "../../Components/Header/Header"

export default function ThankYou() {
  const routerLocation = useLocation()
  const [searchParams] = useSearchParams()
  const skills = searchParams.get("skills").split(",")
  const experience = searchParams.get("experience")
  const timeZone = searchParams.get("timeZone")
  const name = searchParams.get("name").split("__")
  return (
    <>
      <Header />
      <section className='thankyou_wrapper py-5'>
        <div className='container pt-5'>
          <div className='row'>
            <div className='col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 text-center'>
              <div className='text-center'>
                <img src={ThankImage} className='img-fluid' />
              </div>
              <div className=''>
                <p className='fs-5 py-5 mb-0'>
                  Thank you for showing interest in candidates skilled in{" "}
                  {skills.map((el, i) => {
                    return (
                      <b>
                        {i == 0 ? "" : ", "}
                        {el}
                      </b>
                    )
                  })}{" "}
                  with experience of{" "}
                  <b>
                    {experience} {experience == "1" ? `Yr` : `Yrs`}
                  </b>{" "}
                  from <b>{timeZone}</b>. We will get you in touch with{" "}
                  {name.map((el, i) => {
                    return (
                      <b>
                        {i == 0 ? "" : " "}
                        {el}
                      </b>
                    )
                  })}{" "}
                  and other suitable candidates alike.
                </p>
                <Link to='/' className='btn btn-primary shadow-primary btn-lg d-lg-inline-flex w-100 mb-4 thank_btn'>
                  <a>BACK HOME PAGE</a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
