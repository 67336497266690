import {
  RESET_EMPLOYER_DETAILS,
  SET_EMPLOYER_DETAILS,
} from "../../Actions/types";

import initialState from "../../InitialState/initialState";

export default function employerDetailsReducer(
  state = initialState?.employer?.employerDetails,
  { type, payload }
) {
  switch (type) {
    case SET_EMPLOYER_DETAILS:
      return { ...state, ...payload };
    case RESET_EMPLOYER_DETAILS:
      return initialState?.employer?.employerDetails;
    default:
      return state;
  }
}
