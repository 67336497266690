import "./monetry.scss";

import React from "react";

function Monetry({ currentEarning, expectedEarning, handleChange }) {
  return (
    <div className="monetry">
      <div className="title">
        <div className="dotSymbol"></div>
        <label>Tell Us About your Monetary Expectations (USD/Year)</label>
      </div>
      <div className="earning">
        <form>
          <label htmlFor="fn" className="form-label fs-base">
            What Is Your Current Earning ? *
          </label>
          <input
            value={currentEarning && currentEarning}
            type="number"
            id="fn"
            className="form-control form-control-lg"
            placeholder="per year"
            name="portfolio"
            onChange={(event) =>
              handleChange({
                name: "currentEarning",
                value: event?.currentTarget?.value,
              })
            }
          />
        </form>
      </div>
      <div className="earning">
        <form>
          <label htmlFor="fn" className="form-label fs-base">
            What Is Your Expected Earning ? *
          </label>
          <input
            value={expectedEarning && expectedEarning}
            type="number"
            id="fn"
            className="form-control form-control-lg"
            placeholder="per year"
            name="portfolio"
            onChange={(event) =>
              handleChange({
                name: "expectedEarning",
                value: event?.currentTarget?.value,
              })
            }
          />
        </form>
      </div>
    </div>
  );
}

export default Monetry;
