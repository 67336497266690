import "./sidebar.scss";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import MenuItem from "./menuItem/MenuItem";
import { Nav } from "react-bootstrap";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";

export default function SidebarNew({ currentTab }) {
  const navigate = useNavigate();
  const { isProfileComplete } = useSelector((state) => state.user);
  const [isActive, setActive] = useState({ [currentTab]: true });
  useEffect(() => {
    if (currentTab) {
      if (!isProfileComplete && currentTab === "/test") {
        navigate("/dashboard");
      } else {
        setActive({ [currentTab]: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, isProfileComplete]);
  const listMenuItems = [
    {
      name: "Dashboard",
      value: "/dashboard",
      isDisabled: false,
      iconClass: isActive["/dashboard"]
        ? "dashboard_active"
        : "dashboard_black",
    },
    {
      name: "Tests",
      value: "/test",
      isDisabled: !isProfileComplete,
      iconClass: "bi-pencil-square",
    },
    // {
    //   name: "Jobs",
    //   value: "/jobs",
    //   isDisabled: false,
    //   iconClass: "bi-briefcase",
    // },
    // {
    //   name: "Message",
    //   value: "/message",
    //   isDisabled: false,
    //   iconClass: "bi-chat-left",
    // },
    {
      name: "Account",
      value: "/account-details",
      isDisabled: false,
      iconClass: "bi-gear",
    },
  ];
  return (
    <Nav
      className="flex-column sidebar"
      onSelect={(selectedKey, a, v) => {
        setActive({ [a?.currentTarget?.attributes?.value?.value]: true });
        navigate(selectedKey);
      }}
    >
      {listMenuItems?.map(({ name, value, isDisabled, iconClass }, index) => (
        <MenuItem
          key={index + name}
          isDisabled={isDisabled}
          name={name}
          value={value}
          eventKey={value}
          isActive={isActive[value]}
          iconClass={iconClass}
        />
      ))}
    </Nav>
  );
}
