import "./CandidateCard.scss";

import { Card } from "react-bootstrap";
import React from "react";
import { encodeURI2 } from "../../utils/helperFunctions";

function CandidateCard({ item, index, setSelectedUserData }) {
  return (
    <>
      <div
        className=" candidatecard card border-0 shadow-sm overflow-hidden mb-4"
        key={index}
        hidden={item.id == 1}
      >
        <div className="row g-0">
          <a
            className="col-lg-4 col-sm-12 bg-repeat-0 bg-position-center bg-size-cover"
            style={{
              backgroundImage: `${
                item.accountDetails[0].userProfile
                  ? item.accountDetails[0].userProfile.startsWith("http")
                    ? "url(" +
                      encodeURI(item.accountDetails[0].userProfile) +
                      ")"
                    : "url(" +
                      encodeURI2(item.accountDetails[0].userProfile) +
                      ")"
                  : "url(/placeholder.jpg)"
              }`,
              minHeight: "11rem",
            }}
          />

          <div className="col-lg-8">
            <div className="candidate-body card-body">
              <div className="fs-sm text-muted mb-1">{item.state}</div>
              <h2 className="h4 pb-1 mb-2">
                <a href="" className="text-capitalize">
                  {item.accountDetails[0] &&
                    item.accountDetails[0].firstName +
                      " " +
                      item.accountDetails[0].lastName}
                </a>
              </h2>
              {/* <div className="d-flex align-items-center card-description my-1">
                <i className="role "></i>
                <p className="description">
                  Mid-level Unreal Engine Generalist
                </p>
              </div> */}
              <div className="d-flex align-items-center card-description  my-2">
                <i className="note "></i>
                <p className="description">{`${item?.experience} Years`}</p>
              </div>
              <div className="d-flex align-items-center card-description  my-2">
                <i className="star "></i>
                <p className="description">{item?.speciality}</p>
              </div>
              <div className="d-flex align-items-center card-description  my-2">
                <i className="lock "></i>
                <p className="description">{item?.email}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CandidateCard;
