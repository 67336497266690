import createCustomAxios from "./Axios";

export const postApiWithoutToken = (path, data) => {
  return createCustomAxios()?.post(path, data);
};

export const postApiWithCustomAccessToken = (path, data, accessToken) => {
  return createCustomAxios().post(path, data);
};

export const putApiWithCustomAccessToken = (path, data, accessToken) => {
  return createCustomAxios().put(path, data);
};

export const putApiWithoutToken = (path, data) => {
  return createCustomAxios()?.put(path, data);
};

export const getApiWithoutToken = (path) => {
  return createCustomAxios()?.get(path);
};

export const deleteApiWithoutToken = (path, data) => {
  return createCustomAxios()?.delete(path, {
    data: data,
  });
};

export const postApi = (path, data) => {
  return createCustomAxios()?.post(path, data);
};
export const postApiForUnrealEngine = (path, data) => {
  return createCustomAxios({ timeout: 300000 })?.post(path, data);
};

export const deleteApi = (path, data) => {
  return createCustomAxios()?.delete(path, {
    data: data,
  });
};

export const getApi = (path) => {
  return createCustomAxios()?.get(path);
};

export const getApiWithData = async (path, data) => {
  return createCustomAxios()?.get(path, {
    params: data,
  });
};

export const putApi = (path, data) => {
  return createCustomAxios()?.put(path, data);
};
