import "./commoncomponetCSS.scss";
import "react-phone-input-2/lib/style.css";

import React from "react";

function BodyTemplate({
  content,
  title,
  subtitle,
  isClose = false,
  dispatch,
  navigate,
}) {
  return (
    <div className="bodyTemplate">
      {title && <label className="title">{title}</label>}
      {isClose && (
        <i
          class="bi bi-x close-icon"
          onClick={() => {
            navigate("/employer/dashboard/calls");
            // dispatch({ type: SET_MODAL, payload: { employer_review: true } });
          }}
        ></i>
      )}
      {subtitle && <p className="subtitle">{subtitle}</p>}
      {content && <div className="content">{content}</div>}
    </div>
  );
}

export default BodyTemplate;
