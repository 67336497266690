import { applyMiddleware, compose, createStore } from "redux";

import rootReducer from "../Reducers/IndexReducer";
import thunk from "redux-thunk";

export default function store(initialState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const middleWare = [thunk];
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleWare))
  );
}
