import {
  RESET_EMPLOYER_CONTACT,
  RESET_EMPLOYER_HIRING,
  RESET_EMPLOYER_SUITABLE_CANDIDATE,
  RESET_EMPLOYER_TELL_US_MORE,
} from "../../Redux/Actions/types";
import React, { useEffect, useState } from "react";
import {
  areaOptions,
  experienceOption,
  skillsCandidateOptions,
  specialityOptions,
} from "../../utils/jsonData";
import { useLocation, useNavigate } from "react-router-dom";

import ContactForm from "../../Pages/Candidate/ContactForm";
import HeroImage from "../../Assets/img/landing/digital-agency/hero-bg.svg";
import { Link } from "react-router-dom";
import Lottie from "react-lottie-player";
import LottieJson from "../../Assets/json/animation-digital-agency.json";
import Select from "react-select";
import { useDispatch } from "react-redux";

// import ImageJson from '../../Assets/json/animation-digital-agency.json';

export default function LandingSectionOne() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [skills, setSkills] = useState([]);
  const [speciality, setSpeciality] = useState("");
  const [experience, setExperience] = useState("");
  const [timeZone, setTimeZone] = useState("");

  const handleSkillsChange = (selectedOption) => {
    let temp = [];
    for (let x of selectedOption) {
      temp.push(x.value);
    }
    setSkills(temp);
  };
  const disabled = skills?.length == 0 && !experience && !speciality;
  const onSubmit = () => {
    navigate(
      `/employer/contact?skills=${skills}&speciality=${speciality}&experienceFrom=${
        experience
          ? experience !== "10+ yrs"
            ? experience.split(" - ")[0]
            : experience.split("+")[0]
          : ""
      }&experienceTo=${
        experience
          ? experience !== "10+ yrs"
            ? experience.split(" - ")[1].split(" ")[0]
            : "50"
          : ""
      }&timeZone=${timeZone}`
    );
  };

  useEffect(() => {
    dispatch({
      type: RESET_EMPLOYER_CONTACT,
    });
    dispatch({
      type: RESET_EMPLOYER_TELL_US_MORE,
    });
    dispatch({
      type: RESET_EMPLOYER_HIRING,
    });
    dispatch({
      type: RESET_EMPLOYER_SUITABLE_CANDIDATE,
    });
  }, []);

  return (
    <>
      <section
        className="jarallax position-relative d-flex align-items-center min-vh-100 bg-light mb-5 py-lg-5 pt-5"
        style={{
          backgroundImage: `url(${HeroImage})`,
        }}
        data-jarallax=""
        data-img-position="0% 100%"
        data-speed="0.5"
      >
        <div className="container position-relative zindex-5 py-5 py-md-4 py-lg-5">
          <div className="row justify-content-md-start justify-content-center">
            <div className="col-md-6 col-sm-12 order-md-1 order-2 d-flex flex-column justify-content-between mt-md-4 pt-2 text-md-start text-center">
              <div className="mb-4">
                {/* Video popup btn */}
                {/* <div className="d-inline-flex align-items-center position-relative mb-4 mb-md-3">
                  <a
                    href="#"
                    className="btn btn-video btn-icon btn-sm flex-shrink-0 stretched-link" 
                  >
                    <i className="bx bx-play" />
                  </a>
                  <h5 className="mb-0 ms-2">Hiring Platform</h5>
                </div> */}
                {/* Text */}
                <h1 className="display-2 mob-display-2 mb-md-1 mb-1 pb-3">
                  <span className="text-gradient-primary"> Hire </span>for
                  Unreal Engine
                  <br className="d-none d-lg-block" />
                </h1>
                <p style={{ fontWeight: "bold", textTransform: "capitalize" }}>
                  Search for talent
                </p>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="input-group row mb-5 d-flex align-items-center justify-content-center me-0 mx-0">
                      <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        {/* <Select className="" placeholder={<div>Speciality</div>} name="speciality" options={specialityOptions} isMulti /> */}
                      </div>
                      <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        {/* <Select className="" placeholder={<div>Area</div>} name="area" options={areaOptions} isMulti /> */}
                      </div>
                      <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        {/* <Select className="" placeholder={<div>Experience</div>} name="experience" options={experienceOption} isMulti/> */}
                      </div>
                      <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        {/* <Link to="/candidate" className="btn btn-primary flex-shrink-0 me-md-4 mb-md-0 mb-sm-4 mb-3 mob-w-100" style={{height:"40px"}}>
                          <span className="px-3"> Hire </span>
                        </Link> */}
                      </div>
                    </div>
                  </div>
                  <p className="mt-50 mob-mt-215px">
                    Looking to get hired?
                    <Link
                      to="/candidate-home"
                      className="text-decoration-none font-weight-600 ps-1"
                    >
                      Get Tested
                    </Link>
                  </p>
                </div>
                <div className="d-md-flex align-items-md-start">
                  {/* <Link to="/candidate" className="btn btn-lg btn-primary flex-shrink-0 me-md-4 mb-md-0 mb-sm-4 mb-3">
                    <a> Hire Now  </a>
                  </Link> */}

                  <p className="d-lg-block d-none mb-0 ">
                    Visceral’s testing platform is built by the best, ranging
                    from experts from companies such as Pixar, Epic, Unity,
                    Netflix, Google amongst others.
                  </p>
                </div>
              </div>
              {/* Scroll down btn */}
              <div className="d-inline-flex align-items-center justify-content-center justify-content-md-start position-relative">
                <a
                  href="#benefits"
                  data-scroll=""
                  data-scroll-offset={100}
                  className="btn btn-video btn-icon rounded-circle shadow-sm flex-shrink-0 stretched-link me-3"
                >
                  <i className="bx bx-chevron-down" />
                </a>
                <span className="fs-sm">Discover more</span>
              </div>
            </div>
            {/* Animated gfx */}
            <div className="col-sm-6 col-sm-6 col-9 order-md-2 order-1">
              {/* <lottie-player
                className="mx-auto"
                src='../../Assets/json/animation-digital-agency.json' 
                background="transparent"
                speed={1}
                loop=""
                autoPlay=""
              /> */}
              <div className="mob_position_banner">
                <Lottie loop animationData={LottieJson} play speed={1} />
              </div>
            </div>

            <div className="row position-absolute select-box-row">
              <div className="col-sm-6 col-lg-4 mb-3 mb-lg-0">
                <Select
                  placeholder={<div>Search Skills</div>}
                  name="area"
                  options={skillsCandidateOptions}
                  isMulti
                  onChange={(selectedOption) =>
                    handleSkillsChange(selectedOption)
                  }
                />
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0">
                <Select
                  placeholder={<div>Speciality</div>}
                  name="speciality"
                  options={specialityOptions}
                  onChange={(selectedOption) =>
                    setSpeciality(selectedOption.value)
                  }
                />
              </div>
              <div className="col-12 col-sm-6 col-lg-2 mb-3 mb-lg-0">
                <Select
                  placeholder={<div>Experience</div>}
                  name="experience"
                  options={experienceOption}
                  onChange={(selectedOption) =>
                    setExperience(selectedOption.value)
                  }
                />
              </div>
              <div className="col-sm-6 col-lg-2 mt-4 mt-sm-0 mb-3 mb-lg-0">
                <span
                  onClick={onSubmit}
                  className={`btn btn-primary flex-shrink-0 me-md-4 mb-md-0 mb-sm-4 mb-3 mob-w-100  ${
                    disabled ? "disabled" : ""
                  }`}
                  style={{ height: "40px" }}
                >
                  <span className="px-3"> Hire</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <ContactForm /> */}
    </>
  );
}
