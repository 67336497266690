import {
  RESET_EMPLOYER_TELL_US_MORE,
  SET_EMPLOYER_TELL_US_MORE,
} from "../../Actions/types";

import initialState from "../../InitialState/initialState";

export default function tellusmoreeducer(
  state = initialState?.employer?.tellusmore,
  { type, payload }
) {
  switch (type) {
    case SET_EMPLOYER_TELL_US_MORE:
      return { ...state, ...payload };
    case RESET_EMPLOYER_TELL_US_MORE:
      return initialState?.employer?.tellusmore;
    default:
      return state;
  }
}
