import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import AccountDetails from "../Pages/Account/AccountDetails";
import Calendly from "../Pages/employer/calendly/Calendly";
import Candidate from "../Pages/Candidate/New_CandidateList";
import CandidateHome from "../Pages/CandidateHome/index";
import CandidateProfile from "../Pages/employer/dashboard/CandidateProfile";
import CompleteProfile from "../Pages/CompleteProfile/CompleteProfile";
import Contact from "../Pages/employer/contact/Contact";
import ContactForm from "../Pages/Candidate/ContactForm";
import Dashboard from "../Pages/dashboard/Dashboard";
import DashboardEmployer from "../Pages/employer/dashboard/Dashboard";
import EmployerReviewLandingPage from "../Pages/employer/OTPModal/EmployerReviewLandingPage";
import EmployerWrapper from "../Components/MenuWrapper/EmployerWrapper";
import Hiring from "../Pages/employer/hiringRequirement/Hiring";
import Home from "../Pages/Home/index";
import Jobs from "../Pages/jobs/Jobs";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import LoginLinkedIN from "../Pages/LoginLinkedIN";
import MainBoard from "../Components/mainboard/MainBoard";
import MainMenuWrapper from "../Components/MenuWrapper/MainMenuWrapper";
import Messages from "../Components/messages/Messages";
import Privacy from "../Pages/privacy policy/Privacy";
import PrivateRoute from "./PrivateRoute";
import React from "react";
import ReviewLandingPage from "../Pages/Candidate/ReviewLandingPage";
import ShortListCandidateList from "../Pages/employer/shortlist/Shortlist";
import SignIn from "../Pages/Account/SignIn";
import SignUp from "../Pages/Account/SignUp";
import SuitableCandidate from "../Pages/employer/suitableCandidate/SuitableCandidate";
import TellUsMore from "../Pages/employer/tellusmore/TellUsMore";
import Terms from "../Pages/terms/terms";
import TestDetails from "../Pages/CandidateTest/TestDetails";
import TestList from "../Pages/CandidateTest/TestList";
import ThankYou from "../Pages/Candidate/ThankYou";
import { useSelector } from "react-redux";

function MainRouter() {
  const { isProfileComplete } = useSelector((state) => state.user);
  return (
    <Router>
      <Routes>
        {/* ------------------------    Public Routes     ------------------------------ */}
        <Route exact path="/" element={<Navigate to="/home" replace />} />
        <Route
          path="/home"
          element={
            <EmployerWrapper>
              <Home />
            </EmployerWrapper>
          }
        />
        <Route path="/candidate-home" element={<CandidateHome />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route
          path="/terms"
          element={
            <MainBoard>
              <Terms />
            </MainBoard>
          }
        />
        <Route
          path="/privacy_policy"
          element={
            <MainBoard>
              <Privacy />
            </MainBoard>
          }
        />
        {/* <Route path="/candidate" element={<Candidate />} /> */}
        <Route path="/login-linkedin" element={<LoginLinkedIN />} />
        {/* ----------------------------    Private Routes    ------------------------------- */}
        <Route
          exact
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/completeprofile"
          element={
            <PrivateRoute>
              {isProfileComplete ? (
                <Navigate to="/dashboard" />
              ) : (
                <CompleteProfile />
              )}
            </PrivateRoute>
          }
        />
        <Route
          path="/candidate"
          element={
            <PrivateRoute>
              <Candidate />
            </PrivateRoute>
          }
        />

        <Route exact path="/linkedin" element={<LinkedInCallback />} />

        <Route path="employer">
          <Route
            path="contact"
            element={
              <MainBoard>
                <Contact />
              </MainBoard>
            }
          />
          <Route
            path="tellusmore"
            element={
              <MainBoard>
                <TellUsMore />
              </MainBoard>
            }
          />
          <Route
            path="hiring"
            element={
              <MainBoard>
                <Hiring />
              </MainBoard>
            }
          />
          <Route
            path="suitableCandidate"
            element={
              <MainBoard>
                <SuitableCandidate />
              </MainBoard>
            }
          />

          <Route
            path="calendly"
            element={
              <MainBoard>
                <Calendly />
              </MainBoard>
            }
          />
          <Route
            path="profilereview"
            element={
              <PrivateRoute>
                <EmployerReviewLandingPage />
              </PrivateRoute>
            }
          />
          <Route path="dashboard">
            <Route
              path="candidate"
              element={
                <MainBoard>
                  <MainMenuWrapper employer={true} currentTab="/candidate">
                    <DashboardEmployer />
                  </MainMenuWrapper>
                </MainBoard>
              }
            />
            <Route
              path="shortlists"
              element={
                <MainBoard>
                  <MainMenuWrapper employer={true} currentTab="/shortlists">
                    <ShortListCandidateList />
                  </MainMenuWrapper>
                </MainBoard>
              }
            />
            <Route
              path="profile/:ID"
              element={
                <MainBoard>
                  <CandidateProfile />
                </MainBoard>
              }
            />
            <Route
              path="calls"
              element={
                <MainBoard>
                  <MainMenuWrapper employer={true} currentTab="/calls">
                    <EmployerReviewLandingPage />
                  </MainMenuWrapper>
                </MainBoard>
              }
            />
          </Route>
        </Route>

        <Route path="/contact-form" element={<ContactForm />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route
          path="test/test-detail/:id"
          key="test"
          element={
            <PrivateRoute>
              <MainMenuWrapper currentTab="/test">
                <TestDetails />
              </MainMenuWrapper>
            </PrivateRoute>
          }
        />
        <Route
          path="test"
          element={
            <PrivateRoute>
              <MainMenuWrapper currentTab="/test">
                <TestList />
              </MainMenuWrapper>
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <MainMenuWrapper currentTab="/dashboard">
                <Dashboard />
              </MainMenuWrapper>
            </PrivateRoute>
          }
        />
        <Route
          path="/jobs"
          element={
            <PrivateRoute>
              <MainMenuWrapper currentTab="/jobs">
                <Jobs />
              </MainMenuWrapper>
            </PrivateRoute>
          }
        />
        <Route
          path="/message"
          element={
            <PrivateRoute>
              <MainMenuWrapper currentTab="/message">
                <Messages />
              </MainMenuWrapper>
            </PrivateRoute>
          }
        />
        <Route
          path="/test-detail"
          element={
            <PrivateRoute>
              <TestDetails />
            </PrivateRoute>
          }
        />

        <Route
          path="/account-details"
          element={
            <PrivateRoute>
              <MainMenuWrapper currentTab="/account-details">
                <AccountDetails />
              </MainMenuWrapper>
            </PrivateRoute>
          }
        />
        <Route
          path="profilereview"
          element={
            <PrivateRoute>
              <ReviewLandingPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}
export default MainRouter;
