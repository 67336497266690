import "./contact.scss";

import React, { useState } from "react";
import {
  SET_EMPLOYER_APPROVAL,
  SET_EMPLOYER_CONTACT,
  SET_MODAL,
} from "../../../Redux/Actions/types";
import { postApi, postApiWithoutToken } from "../../../utils/ApiMethods";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button } from "react-bootstrap";
import CustomCard from "../../../Components/common/card/CustomCard";
import PhoneInput from "react-phone-input-2";
import bodyTemp from "../commomComponent/BodyTemplate";
import { getValidation } from "../../../utils/helperFunctions";
import headerTemp from "../commomComponent/HeaderTemplate";
import { toast } from "react-toastify";

function Contact(props) {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState({
    email: { errorMsg: "", isError: false },
    fullName: { errorMsg: "", isError: false },
    conatct: { errorMsg: "", isError: false },
  });
  const [countryCode, setCountryCode] = useState("91");
  // const userId = searchParams.get("id");
  const name = searchParams.get("name")?.split("__")[0];
  const experience = searchParams.get("experience");
  const skill = searchParams.get("skills");
  const dispatch = useDispatch();
  const { email, fullName, contact } = useSelector(
    (state) => state?.employer?.contact
  );

  const onChange = (e) => {
    dispatch({
      type: SET_EMPLOYER_CONTACT,
      payload: { [e?.currentTarget?.name]: e?.currentTarget?.value },
    });
    setError({
      ...error,
      [e?.currentTarget?.name]: getValidation(e?.currentTarget),
    });
  };
  const handleContactClick = async (values) => {
    try {
      const response = await postApiWithoutToken(`users/employerLogin`, {
        email,
      });

      const { data, status } = response;

      if (status === 200) {
        if (data.status === 1) {
          dispatch({ type: SET_MODAL, payload: { employer_model: true } });
          dispatch({
            type: SET_EMPLOYER_APPROVAL,
            payload: { isApproved: data?.data?.isApproved },
          });
        } else {
          navigate(`/employer/tellusmore`);
        }
      } else {
      }
    } catch (error) {
      console.error(error);

      toast.error("Something went wrong");
    }
    // ;
    // try {
    //   const response1 = await postApi("corporate/createCorporateDetails", {
    //     name: corporateName,
    //     email: corporateEmail,
    //     phone: `+${countryCode}-${corporatePhone}`,
    //   });
    //   const data1 = response1.data;
    //   if (data1.status == 1) {
    //   } else {
    //     toast.error("May be an issue arise. Please Try later");
    //   }
    //   const response = await postApi("users/corporateContactCandidate", {
    //     id: userId,
    //     name,
    //     experience,
    //     skill,
    //     email: values.email,
    //   });
    //   const data = response.data;
    //   if (data.status == 1) {
    //     navigate(`/tellusmore}`);
    //     setLoading(false);
    //   } else {
    //     toast.error("May be an issue arise. Please Try later");
    //     setLoading(false);
    //   }
    // } catch (err) {
    //   setLoading(false);
    //   toast.error("Something went Wrong");
    // }
  };
  let isDisabled =
    !email ||
    !fullName ||
    !contact ||
    Object.keys(error).some((d) => error[d]?.isError);
  const navigate = useNavigate();
  const content = (
    <form className="needs-validation" noValidate="">
      <div className="row">
        <div className="col-12">
          <div className="position-relative mb-4">
            <label htmlFor="name" className="form-label fs-base">
              Full Name
            </label>
            <input
              type="text"
              id="name"
              name="fullName"
              className={`form-control form-control-lg $`}
              required=""
              onChange={onChange}
              value={fullName}
            />
            <div className="invalid-feedback position-absolute start-0 top-100 d-block">
              {error?.fullName?.isError && error?.fullName?.errorMsg}
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="position-relative mb-4">
            <label htmlFor="email" className="form-label fs-base">
              Email
            </label>
            <input
              type="text"
              id="email"
              name="email"
              className={`form-control form-control-lg `}
              required={true}
              onChange={onChange}
              value={email}
            />
            <div className="invalid-feedback position-absolute start-0 top-100 d-block">
              {error?.email?.isError && error?.email?.errorMsg}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="position-relative mb-4">
            <label htmlFor="email" className="form-label fs-base">
              Phone Number
            </label>
            <div className="d-flex ">
              <PhoneInput
                className="react-phone-1"
                country={"in"}
                value={countryCode}
                onChange={(code) => setCountryCode(code)}
              />
              <input
                type="number"
                id="phonenumber"
                name="contact"
                className={`form-control form-control-lg  ml-1`}
                required={true}
                onChange={onChange}
                value={contact}
              />
              <div className="invalid-feedback position-absolute start-0 top-100 d-block">
                {error?.contact?.isError && error?.contact?.errorMsg}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Button
            className="w-100"
            onClick={handleContactClick}
            disabled={isDisabled}
          >
            Continue
          </Button>
        </div>
      </div>
    </form>
  );
  const body = bodyTemp({ content: content, title: "Contact us" });
  const header = headerTemp({
    naviagteTo: "/",
    title:
      " 20+ Vetted Unreal Engine Professionals Have the Skills Your Employment needs...",
  });

  return <CustomCard CardBody={body} Header={header} />;
}

export default Contact;
