import React, { useContext, useState } from "react";
import { SET_AUTH, SET_SIGNUP_CREDS } from "../../Redux/Actions/types";
import { getApi, postApiWithoutToken } from "../../utils/ApiMethods";
import { useLocation, useNavigate } from "react-router-dom";

import { Loader } from "../../Components/Loader";
import Modal from "react-modal";
import OtpInput from "react-otp-input";
import { USER } from "../../constant";
import UserContext from "../../context/user/UserContext";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0",
    background: "transparent",
  },
  overlay: {
    background: "rgba(0,0,0,0.8)",
    backdrop: "static",
    keyboard: false,
  },
};

const OTPModal = (props) => {
  const { modalIsOpen, setIsOpen, email } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [otp, setOTP] = useState("");
  const location = useLocation();
  const context = useContext(UserContext);
  const { setIsLoggedIn } = context;

  const navigate = useNavigate();

  function closeModal() {
    setIsOpen(false);
    setOTP("");
    setErrorMsg("");
    setIsWrongOtp("");
  }

  // const initialValues = {
  //     digit1:"",
  //     digit2:"",
  //     digit3:"",
  //     digit4:"",
  //     digit5:"",
  //     digit6:""
  // }
  // const [values,setValues] = useState(initialValues);

  // const handleInputChange = (e) => {
  //     setIsWrongOtp(false)
  //     const { value, name } = e.target;

  //     if(isNaN(value)){
  //         return
  //       }

  //         setValues({
  //             ...values,
  //             [name]: value,
  //         });
  //     };

  // --------------------------------        Code for moving focus to next input when enter a value  -------------------------
  // const  inputfocus = (elmnt) => {
  //   if(elmnt.key==='Enter'){
  //     onVerifyOtp()
  //   }

  //     if(isNaN(elmnt.target.value)){
  //         return
  //       }
  //     let numArray = ['1', '2', '3', '4','5','6','7','8','9','0']

  //     if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
  //         const prev = elmnt.target.tabIndex - 2;
  //         //
  //       if (prev > -1) {

  //         elmnt.target.form.elements[prev].focus()
  //       }
  //     }

  //     else if((numArray.includes(elmnt.key))) {
  //     //

  //       const next = elmnt.target.tabIndex;
  //       if (next < 6) {
  //           elmnt.target.form.elements[next].focus()
  //         }
  //     }

  //   }

  const resendOtp = async () => {
    try {
      const response = await postApiWithoutToken(`users/UserLogin`, {
        email,
      });
      const data = response.data;
    } catch (error) {
      toast.error(error);
    }
  };
  // const getUserAccountDetails = async () => {
  //   const

  // };
  const getUserAccountDetails = async () => {
    const response = await getApi(`accountDetails/userDetails`);
    if (response?.data?.status === 1) {
      const { data } = response?.data;
      dispatch({
        type: SET_SIGNUP_CREDS,
        payload: {
          firstName: data?.["accountDetails.firstName"],
          lastName: data?.["accountDetails.lastName"],
          isProfileComplete: data?.isProfileComplete,
          isProfile: data?.isProfile,
          isConfirm: data?.isConfirm,
          candidateId: data?.id,
        },
      });

      data?.isProfileComplete
        ? navigate("/test")
        : location.pathname === "/sign-up"
        ? navigate("/completeprofile")
        : navigate("/dashboard");
    }
    setIsOpen(false);
  };
  const onVerifyOtp = async () => {
    try {
      setLoading(true);
      // let propertyValues = Object.values(values);
      // let code = propertyValues.join('')
      //
      if (otp.length < 6) {
        // toast.error("Otp is not of 6 digits")
        setIsWrongOtp(true);
        setErrorMsg("Please Enter 6 digits otp");
      } else {
        const response = await postApiWithoutToken(`users/otpVerification`, {
          email: email,
          otp: otp,
        });
        const data = response.data;

        if (data.status == 1) {
          localStorage.setItem("token", data.data.token);
          dispatch({
            type: SET_AUTH,
            payload: { token: data.data.token, type: USER },
          });
          dispatch({
            type: SET_SIGNUP_CREDS,
            payload: { email },
          });
          setIsLoggedIn(true);

          getUserAccountDetails();
          // loaction?.pathname.includes("sign-in")
          //   ? navigate("/test")
          //   : navigate("/completeprofile");
          // window.location.href="/test"
        } else {
          setIsWrongOtp(true);
          setErrorMsg("Wrong OTP");
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error("Something Went Wrong");
    }
    setLoading(false);
  };

  const handleOtpInput = (otp) => {
    setIsWrongOtp(false);
    setErrorMsg("");
    setOTP(otp);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {/* <div className="modal fade" id="modalId" tabIndex={-1} role="dialog"> */}
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body p-4 p-sm-5">
              <div className="text-end">
                <button
                  className="btn-close"
                  style={{ display: "none" }}
                  id="dissmissModal"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="wrapper">
                <div className="col-sm-12 text-center">
                  <form className="rounded bg-white">
                    <h3 className="text-uppercase text-dark fw-bolder fs-3 mb-2">
                      Email Verification
                    </h3>
                    <div className="fw-normal text-muted mb-2">
                      Enter the verification code we sent to
                    </div>
                    <div className="mb-4">
                      <a className="text-decoration-none">{email}</a>
                    </div>
                    <div className="otp_input text-start mb-2">
                      <label htmlFor="digit">
                        Type your 6 digit security code
                      </label>
                      {/* <div className="d-flex align-items-center justify-content-between mt-2"> */}
                      {/* <input type="text" className={`form-control me-2 text-center fs-3 fw-bold ${isWrongOtp?"border-danger":""}`} placeholder="" name="digit1" value={values.digit1} maxLength={1} tabIndex="1" onChange={handleInputChange} onKeyUp={e => inputfocus(e)}/>
                                            <input type="text" className={`form-control me-2 text-center fs-3 fw-bold ${isWrongOtp?"border-danger":""}`} placeholder="" name="digit2" value={values.digit2} maxLength={1} tabIndex="2" onChange={handleInputChange} onKeyUp={e => inputfocus(e)}/>
                                            <input type="text" className={`form-control me-2 text-center fs-3 fw-bold ${isWrongOtp?"border-danger":""}`} placeholder="" name="digit3" value={values.digit3} maxLength={1} tabIndex="3" onChange={handleInputChange} onKeyUp={e => inputfocus(e)}/>
                                            <input type="text" className={`form-control me-2 text-center fs-3 fw-bold ${isWrongOtp?"border-danger":""}`} placeholder="" name="digit4" value={values.digit4} maxLength={1} tabIndex="4" onChange={handleInputChange} onKeyUp={e => inputfocus(e)}/>
                                            <input type="text" className={`form-control me-2 text-center fs-3 fw-bold ${isWrongOtp?"border-danger":""}`} placeholder="" name="digit5" value={values.digit5} maxLength={1} tabIndex="5" onChange={handleInputChange} onKeyUp={e => inputfocus(e)}/>
                                            <input type="text" className={`form-control me-2 text-center fs-3 fw-bold ${isWrongOtp?"border-danger":""}`} placeholder="" name="digit6" value={values.digit6} maxLength={1} tabIndex="6" onChange={handleInputChange} onKeyUp={e => inputfocus(e)}/> */}

                      {/* </div> */}
                      <div className="text-center mt-2 ">
                        <OtpInput
                          inputStyle={{
                            maxHeight: 60,
                            maxWidth: 60,
                            width: "2.8rem",
                            outline: 0,
                          }}
                          className={`me-2 text-center fs-3  w-100 `}
                          errorStyle={{ border: "solid 1px red" }}
                          hasErrored={isWrongOtp}
                          isInputNum
                          value={otp}
                          onChange={(otp) => handleOtpInput(otp)}
                          numInputs={6}
                          separator={<span> </span>}
                        />
                      </div>
                      {isWrongOtp && (
                        <div className="text-danger">{errorMsg}</div>
                      )}
                    </div>
                    {/* <a href={`${location.pathname === "/contact-form" ? '/thankyou' : '/test' }`} > */}

                    <div
                      className="btn btn-primary submit_btn my-4"
                      onClick={onVerifyOtp}
                    >
                      {loading ? <Loader /> : "Submit"}
                    </div>

                    {/* </a> */}
                    <div className="fw-normal text-muted mb-2">
                      Didn’t get the code ?{" "}
                      <a
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={resendOtp}
                      >
                        Resend
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div>  */}
      </Modal>
    </>
  );
  //   Modal.setAppElement(OTPModal);
};

export default OTPModal;
