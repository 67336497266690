import React from 'react';
import StepOne from '../../Assets/img/landing/online-courses/steps/01-dark.svg';
import StepTwo from '../../Assets/img/landing/online-courses/steps/01-light.svg';
import StepThree from '../../Assets/img/landing/online-courses/steps/02-dark.svg';
import StepFour from '../../Assets/img/landing/online-courses/steps/02-light.svg';
import StepFive from '../../Assets/img/landing/online-courses/steps/03-dark.svg';
import StepSix from '../../Assets/img/landing/online-courses/steps/03-light.svg';
import StepSeven from '../../Assets/img/landing/online-courses/steps/04-dark.svg';
import StepEight from '../../Assets/img/landing/online-courses/steps/04-light.svg';

export default function CandidateLandingThree() {
    return (
        <>
            <section className="mt-5 container pt-4 pt-lg-0 pb-4 pb-lg-5">
                <h2 className="h1 text-center pb-3 pb-md-0 mb-md-5">How Does It Work?</h2>
                <div className="steps">
                    <div className="step pt-0 pt-md-3 pb-5">
                        <div className="step-number">
                            <div className="step-number-inner">1</div>
                        </div>
                        <div className="step-body d-flex align-items-center ps-xl-5">
                            <div
                                className="rellax d-none d-lg-block flex-shrink-0 mx-4 mx-xl-5"
                                data-rellax-percentage="0.5"
                                data-rellax-speed="-0.3"
                                data-disable-parallax-down="lg"
                            >
                                <img
                                    src={StepOne}
                                    className="d-dark-mode-none"
                                    width={306}
                                    alt="Illustration"
                                />
                                <img
                                    src={StepTwo}
                                    className="d-none d-dark-mode-block"
                                    width={306}
                                    alt="Illustration"
                                />
                            </div>
                            <div
                                className="rellax ps-md-4 ps-xl-5"
                                data-rellax-percentage="0.5"
                                data-rellax-speed="0.4"
                                data-disable-parallax-down="lg"
                            >
                                <h3 className="h4">
                                Find what interests you and choose your test
                                </h3>
                                <p className="mb-0">
                                Unreal Engine is big and beautiful, start with what you think you know and take any of our tests on that. 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="step pt-0 pt-md-4 pb-5">
                        <div className="step-number">
                            <div className="step-number-inner">2</div>
                        </div>
                        <div className="step-body d-flex align-items-center ps-xl-5">
                            <div
                                className="rellax d-none d-lg-block flex-shrink-0 mx-4 mx-xl-5"
                                data-rellax-percentage="0.5"
                                data-rellax-speed="-0.5"
                                data-disable-parallax-down="lg"
                            >
                                <img
                                    src={StepThree}
                                    className="d-dark-mode-none"
                                    width={306}
                                    alt="Illustration"
                                />
                                <img
                                    src={StepFour}
                                    className="d-none d-dark-mode-block"
                                    width={306}
                                    alt="Illustration"
                                />
                            </div>
                            <div
                                className="rellax ps-md-4 ps-xl-5"
                                data-rellax-percentage="0.5"
                                data-rellax-speed="0.5"
                                data-disable-parallax-down="lg"
                            >
                                <h3 className="h4">Learn by doing</h3>
                                <p className="mb-0">
                                Confused where to start? Our tests are ordered in a way that helps you determine what stage of knowledge and practise you are, so you don’t get confused which part of the learning ladder you are on.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="step pt-0 pt-md-4 pb-5">
                        <div className="step-number">
                            <div className="step-number-inner">3</div>
                        </div>
                        <div className="step-body d-flex align-items-center ps-xl-5">
                            <div
                                className="rellax d-none d-lg-block flex-shrink-0 mx-4 mx-xl-5"
                                data-rellax-percentage="0.5"
                                data-rellax-speed="-0.3"
                                data-disable-parallax-down="lg"
                            >
                                <img
                                    src={StepFive}
                                    className="d-dark-mode-none"
                                    width={306}
                                    alt="Illustration"
                                />
                                <img
                                    src={StepSix}
                                    className="d-none d-dark-mode-block"
                                    width={306}
                                    alt="Illustration"
                                />
                            </div>
                            <div
                                className="rellax ps-md-4 ps-xl-5"
                                data-rellax-percentage="0.5"
                                data-rellax-speed="0.4"
                                data-disable-parallax-down="lg"
                            >
                                <h3 className="h4">Get instant results</h3>
                                <p className="mb-0">
                                Our tests allow you to determine what your qualification level is, and you get a certificate to show for it. Didn’t pass the test? No problem, take the relevant learning module and get better, instantly re-apply for the test once you are done!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="step pt-0 pt-md-4 pb-5">
                        <div className="step-number">
                            <div className="step-number-inner">4</div>
                        </div>
                        <div className="step-body d-flex align-items-center ps-xl-5">
                            <div
                                className="rellax d-none d-lg-block flex-shrink-0 mx-4 mx-xl-5"
                                data-rellax-percentage="0.5"
                                data-rellax-speed="-0.5"
                                data-disable-parallax-down="lg"
                            >
                                <img
                                    src={StepSeven}
                                    className="d-dark-mode-none"
                                    width={306}
                                    alt="Illustration"
                                />
                                <img
                                    src={StepEight}
                                    className="d-none d-dark-mode-block"
                                    width={306}
                                    alt="Illustration"
                                />
                            </div>
                            <div
                                className="rellax ps-md-4 ps-xl-5"
                                data-rellax-percentage="0.5"
                                data-rellax-speed="0.6"
                                data-disable-parallax-down="lg"
                            >
                                <h3 className="h4">
                                Put your learning into practice, find your dream job!

                                </h3>
                                <p className="mb-0">
                                Our team at Visceral helps you be hired through remote work. Our world class platform is your desktop in the cloud, where you can collaborate with peers or fly solo! We take care of all the billing, customer and project management. Cha Ching!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
