import "./review.scss";

import BodyTemplate from "../employer/commomComponent/BodyTemplate";
import { Button } from "react-bootstrap";
import CustomCard from "../../Components/common/card/CustomCard";
import HeaderTemplate from "../employer/commomComponent/HeaderTemplate";
import React from "react";

function ReviewLandingPage(props) {
  const showAlert = {
    isActive: true,
    message: "Your Profile is under review by the Visceral team",
  };

  return <CustomCard showAlert={showAlert} override="empReview" />;
}

export default ReviewLandingPage;
