import {
  RESET_EMPLOYER_APPROVAL,
  SET_EMPLOYER_APPROVAL,
} from "../../Actions/types";

import initialState from "../../InitialState/initialState";

export default function approvalReducer(
  state = initialState?.employer?.approval,
  { type, payload }
) {
  switch (type) {
    case SET_EMPLOYER_APPROVAL:
      return { ...state, ...payload };
    case RESET_EMPLOYER_APPROVAL:
      return initialState?.employer?.approval;
    default:
      return state;
  }
}
