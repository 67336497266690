import React, { useContext, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header/Header';
import UserContext from '../../context/user/UserContext';
import CandidateLandingFive from './CandidateLandingFive';
import CandidateLandingFour from './CandidateLandingFour';
import CandidateLandingOne from './CandidateLandingOne';
import CandidateLandingSeven from './CandidateLandingSeven';
import CandidateLandingSix from './CandidateLandingSix';
import CandidateLandingThree from './CandidateLandingThree';
import CandidateLandingTwo from './CandidateLandingTwo';

const CandidateHome = () => {

  let navigate = useNavigate()
  const context = useContext(UserContext)
  const [jsLoad, setJsLoad]=useState(false)
  const { isLoggedIn } = context;
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/test");
    }
  }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

useEffect(()=>{
  setJsLoad(true)
},[])
  return (
    <>
      <Header />
    {jsLoad &&  <Helmet>
        <script>
          initializeJSRoot()
        </script>
      </Helmet>}
      {/* Section One */}
      <CandidateLandingOne />

      {/* Section Two */}
      {/* <CandidateLandingTwo /> */}

      {/* Section Three */}
      <CandidateLandingThree />

      {/* Section Four */}
      <CandidateLandingFour />

      {/* Section Five */}
      {/* <CandidateLandingFive /> */}

      {/* Section Six */}
      {/* <CandidateLandingSix /> */}

      {/* Section Seven */}
      <CandidateLandingSeven />
      <Footer />
    </>
  )
}
export default CandidateHome