import React, { useState } from "react";

import Spinner from "react-bootstrap/Spinner";

const IFrame = ({
  url = "https://kishlaya.rstk.in:8443",
  onLoad = () => {},
}) => {
  // const [state, setState] = useState(() => {
  //   return url;
  // });
  const [loader, setLoader] = useState(true);

  return (
    <div className="embed-responsive embed-responsive-16by9 w-100 h-100 d-flex justify-content-center align-items-center">
      <iframe
        onLoad={() => {
          onLoad(false);
          setLoader(false);
        }}
        style={{ height: "100%" }}
        title="Embeds Page"
        className="embed-responsive-item"
        src={url}
        allowfullscreen
      ></iframe>

      {loader && (
        <Spinner
          animation="border"
          variant="primary"
          className="position-absolute end-45"
        />
      )}
    </div>
  );
};

export default IFrame;
