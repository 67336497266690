import { RESET_AUTH, SET_AUTH } from "../Actions/types";

import initialState from "../InitialState/initialState";

export default function signupReducer(
  state = initialState?.auth,
  { type, payload }
) {
  switch (type) {
    case SET_AUTH:
      return { ...state, ...payload };
    case RESET_AUTH:
      return initialState?.auth;
    default:
      return state;
  }
}
