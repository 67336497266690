import * as Yup from "yup";

import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import AccountImage from "../../Assets/img/account/signin-bg.jpg";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import LinkedInPage from "./LinkedInPage";
import { Loader } from "../../Components/Loader";
import OTPModal from "./OTPModal";
import UserContext from "../../context/user/UserContext";
import { postApiWithoutToken } from "../../utils/ApiMethods";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { validateEmail } from "../../utils/helperFunctions";

export default function SignUp() {
  const [userInfo, setUserInfo] = useState({
    email: "",
    firstName: "",
    lastName: "",
    terms: false,
  });
  const [error, setError] = useState({
    email: { errorMsg: "", isError: false },
    firstName: { errorMsg: "", isError: false },
    lastName: { errorMsg: "", isError: false },
    terms: { errorMsg: "", isError: false },
  });
  const { token } = useSelector((state) => state?.auth);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const context = useContext(UserContext);
  const { isLoggedIn } = context;

  let navigate = useNavigate();

  // --------------------------------------------------   checking if user  is logged in     ----------------------------------------------------

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  const getValidation = ({ value, name, checked }) => {
    const currentError = { errorMsg: "", isError: false };
    switch (name) {
      case "firstName":
        if (value?.length > 15) {
          currentError.errorMsg = "Must be 15 characters or less";
          currentError.isError = true;
        } else if (value?.length === 0) {
          currentError.errorMsg = "Required";
          currentError.isError = true;
        } else {
          currentError.errorMsg = "";
          currentError.isError = false;
        }
        break;
      case "lastName":
        if (value?.length > 15) {
          currentError.errorMsg = "Must be 15 characters or less";
          currentError.isError = true;
        } else if (value?.length === 0) {
          currentError.errorMsg = "Required";
          currentError.isError = true;
        } else {
          currentError.errorMsg = "";
          currentError.isError = false;
        }
        break;
      case "email":
        let flag = validateEmail(value);
        if (value?.length === 0) {
          currentError.errorMsg = "Required";
          currentError.isError = true;
        } else if (!flag) {
          currentError.errorMsg = "Invalid Email address";
          currentError.isError = !flag;
        } else {
          currentError.errorMsg = "";
          currentError.isError = false;
        }
        break;
      case "terms":
        if (checked) {
          currentError.errorMsg = "";
          currentError.isError = false;
        } else {
          currentError.errorMsg = "Please agree to the Terms & Conditions";
          currentError.isError = true;
        }
        break;

      default:
    }
    return currentError;
  };
  const onChange = (event) => {
    const { name, value } = event?.currentTarget;
    setUserInfo({ ...userInfo, [name]: value });
    setError({ ...error, [name]: getValidation(event?.currentTarget) });
  };

  const handleSignUp = async () => {
    let result = false;
    try {
      setLoading(true);
      const response = await postApiWithoutToken(`users/userSignup`, {
        firstName: userInfo.firstName.replace(/\s/g, ""),
        lastName: userInfo.lastName.replace(/\s/g, ""),
        email: userInfo.email.replace(/\s/g, ""),
        role: "student",
      });

      const data = response.data;
      if (data.status) {
        setIsOpen(true);
        result = true;
      } else if (data?.message === "User already exists") {
        navigate(`/sign-in?email=${userInfo.email}`);
        toast.warning(data?.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error("Something went wrong");
    }
    setLoading(false);
    return result;
  };
  const onSubmit = (e) => {
    e.preventDefault();
    handleSignUp();
  };
  const handleLinkedInLogin = () => {
    // let w=500
    // let h=700
    // var left = (window.screen.width/2)-(w/2);
    // var top = (window.screen.height/2)-(h/2);
    // window.open(`${process.env.REACT_APP_BaseUrl}auth/linkedin`,'','width='+w+',height='+h+',left='+left+',top='+top);

    window.location.href = `${process.env.REACT_APP_BaseUrl}auth/linkedin`;
  };

  const isDisabled =
    Object.keys(error).some((d) => error[d]?.isError) ||
    Object.keys(userInfo).some(
      (d) => userInfo[d] === "" || userInfo[d] === false
    );
  return (
    <>
      <Header />
      <main className="page-wrapper">
        <section className="position-relative h-100 pt-5 pb-5">
          <div className="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100 pt-5">
            <div
              className="w-100 align-self-end pt-1 pt-md-4 pb-4"
              style={{ maxWidth: 526 }}
            >
              <h1 className="text-center text-xl-start">Create Account</h1>
              <p className="text-center text-xl-start pb-3 mb-3">
                Already have an account?{" "}
                <Link to="/sign-in">Sign in here.</Link>
              </p>
              <form
                className="needs-validation"
                noValidate=""
                onSubmit={onSubmit}
              >
                <div className="row">
                  <div className="col-sm-6">
                    <div className="position-relative mb-4">
                      <label htmlFor="name" className="form-label fs-base">
                        First name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="firstName"
                        className={`form-control form-control-lg ${
                          error?.firstName?.isError ? "border-danger" : ""
                        }`}
                        required=""
                        onChange={onChange}
                        value={userInfo?.firstName}
                      />

                      <div className="invalid-feedback position-absolute start-0 top-100 d-block">
                        {error?.firstName?.isError &&
                          error?.firstName?.errorMsg}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="position-relative mb-4">
                      <label htmlFor="name" className="form-label fs-base">
                        Last name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="lastName"
                        className={`form-control form-control-lg ${
                          error?.lastName?.isError ? "border-danger" : ""
                        }`}
                        required=""
                        onChange={onChange}
                        value={userInfo?.lastName}
                      />
                      <div className="invalid-feedback position-absolute start-0 top-100 d-block">
                        {error?.lastName?.isError && error?.lastName?.errorMsg}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="position-relative mb-4">
                      <label htmlFor="email" className="form-label fs-base">
                        Email
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        className={`form-control form-control-lg ${
                          error?.email?.isError ? "border-danger" : ""
                        }`}
                        required={true}
                        onChange={onChange}
                        value={userInfo?.email}
                      />
                      <div className="invalid-feedback position-absolute start-0 top-100 d-block">
                        {/* Please enter a valid email address! */}
                        {error?.email?.isError && error?.email?.errorMsg}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <div className="form-check">
                    <input
                      name="terms"
                      type="checkbox"
                      id="terms"
                      className={`form-check-input ${
                        error?.terms?.isError && "border-danger"
                      }`}
                      value={userInfo?.terms}
                      onChange={onChange}
                    />
                    <label htmlFor="terms" className="form-check-label fs-base">
                      <Link to="/terms">
                        I agree to <a href="">Terms &amp; Conditions</a>
                      </Link>
                    </label>
                  </div>
                </div>
                <div
                  className={`mb-2 invalid-feedback start-0 top-100 d-block ${
                    error?.terms?.isError
                      ? "visibility-visible"
                      : "visibility-hidden"
                  }`}
                  style={{ height: "1rem" }}
                >
                  {error?.terms?.isError && error?.terms?.errorMsg}
                </div>
                <div>
                  <div className="d-grid">
                    {loading ? (
                      <button
                        disabled={true}
                        className="btn btn-primary shadow-primary btn-lg w-100"
                      >
                        <Loader style={{ width: "1rem", height: "1rem" }} />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary shadow-primary btn-lg w-100"
                        disabled={isDisabled}
                      >
                        Sign up
                      </button>
                    )}
                  </div>
                </div>
              </form>
              <hr className="my-4" />
              <h6 className="text-center mb-4">
                Or sign up with your social network
              </h6>
              <div className="row">
                <div className="col mb-3">
                  <div className=" w-100 text-primary">
                    <LinkedInPage />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="position-absolute top-0 end-0 w-50 h-100 bg-position-center bg-repeat-0 bg-size-cover d-none d-xl-block"
            style={{ backgroundImage: `url(${AccountImage})` }}
          />
        </section>
      </main>
      <Footer />
      <OTPModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        email={userInfo?.email}
      />
    </>
  );
}
