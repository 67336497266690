import { Badge, Button } from "react-bootstrap";
import {
  RESET_MODAL,
  RESET_UNREAL,
  SET_UNREAL,
} from "../../Redux/Actions/types";
import React, { useEffect } from "react";
import { postApi, postApiForUnrealEngine } from "../../utils/ApiMethods";
import { useDispatch, useSelector } from "react-redux";

import IFrame from "../../Components/common/iframe/SystemIFrame";
import Modal from "../../Components/common/modal/Modal";
import { toast } from "react-toastify";
import { useState } from "react";

function UnrealModal({ id }) {
  const currentModal = "unrealmodal";
  const {
    modal,
    user: { candidateId },
  } = useSelector((state) => state);
  const show = modal[currentModal];
  const close = async () => {
    try {
      const response = await postApi("attemptTest/closeTest", {
        testId: id,
        userId: candidateId,
      });
      const data = response.data;

      if (data.status == 1) {
        onHandleClose();
        // getTestDetails();
      } else if (data.status == 0) {
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const dispatch = useDispatch();
  const onHandleClose = () => {
    dispatch({ type: RESET_MODAL });
    dispatch({
      type: RESET_UNREAL,
    });
  };
  const onClose = () => {
    close();
    onHandleClose();
  };
  const { createModal } = Modal;

  const Content = () => {
    const {
      user: { candidateId, firstName, lastName },
      unreal: { loaded, url },
      tests: {
        testDetails: { duration },
      },
    } = useSelector((state) => state);
    const invokeLemda = async () => {
      try {
        const response = await postApiForUnrealEngine("instance/invokeLambda", {
          firstName: firstName,
          lastName: lastName,
          testId: id,
          userName: firstName,
          candidateId,
          testDuration: duration,
        });
        const { data } = response.data;

        if (data?.StatusCode == 200) {
          let res = JSON.parse(data?.Payload);
          if (res?.errorType) {
            toast.error("Error while loading the Server");
          } else {
            dispatch({
              type: SET_UNREAL,
              payload: {
                url: res?.url,
                password: res?.password,
                loaded: true,
              },
            });
          }
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    };
    useEffect(() => {
      if (show && url === "" && loaded === false) {
         invokeLemda();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <IFrame url={url} />
      </>
    );
  };
  const SubmitComponent = () => {
    const { id, title } = useSelector((state) => state?.tests?.testDetails);
    const { user, unreal } = useSelector((state) => state);
    const [userCopied, setUserCopied] = useState(false);

    const [passwordCopied, setPasswordCopied] = useState(false);
    const onApplyForTest = async () => {
      try {
        const response = await postApi("attemptTest/createAttemptTest", {
          testDetailId: id,
          title: title,
        });
        const data = response.data;

        if (data.status == 1) {
          onHandleClose();
          // getTestDetails();
        } else if (data.status == 0) {
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    };
    const handleConfirmationBox = (action) => {
      if (action === "delete") {
        console.log(document.querySelector("#testCloseContainer"))
        document.querySelector("#testCloseContainer").style.display = "block"
        document.querySelector("#testCloseContainer").classList.remove('hide');
      } else if (action === "cancel"){
        document.querySelector("#testCloseContainer").style.display = "none"
        document.querySelector("#testCloseContainer").classList.add('hide');
      }
    }

    const handleCopy = (field) => {
      let value;
      if (field === "username") {
        value = `${user?.firstName}_${user?.lastName}`;
        setUserCopied(true);
      } else {
        value = `${unreal?.password}`;
        setPasswordCopied(true);
      }

      navigator.clipboard.writeText(value);

      // setCopied({ ...copied, [field]: true });
    };

    return (
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="d-flex">
          <label className="mx-1">
            <Badge bg="primary">Username :</Badge>
            <span
              id="username"
              onMouseOut={() => setUserCopied(false)}
              style={
                userCopied
                  ? {
                      color: "grey",
                      transition: "all 1s ease-in-out 1s",
                    }
                  : {
                      color: "green",
                      fontWeight: "bold",
                      transition: "all 1s ease-in-out 1s",
                    }
              }
              className="d-inline-block"
              onClick={() => handleCopy("username")}
              data-placement="right"
              data-toggle="tooltip"
              title={userCopied ? "Copied.." : `Click to copy`}
            >
              {` ${user?.firstName}_${user?.lastName}`}
            </span>
            <button type="button" class="btn btn-link" id="copyButton" onClick={() => handleCopy("username")}>
              <i className="bi bi-clipboard d-inline-block"></i>
            </button>
          </label>
          <label className="mx-1">
            <Badge bg="primary">Password :</Badge>
            <span
              id="password"
              onMouseOut={() => setPasswordCopied(false)}
              style={
                passwordCopied
                  ? {
                      color: "grey",
                      transition: "all 1s ease-in-out 1s",
                    }
                  : {
                      color: "green",
                      fontWeight: "bold",
                      transition: "all 1s ease-in-out 1s",
                    }
              }
              className="d-inline-block"
              onClick={() => handleCopy("password")}
              data-placement="right"
              data-toggle="tooltip"
              title={passwordCopied ? "Copied.." : `Click to copy`}
            >
              {` ${unreal?.password}`}
            </span>
            <button type="button" className="btn btn-link" id="copyButton" onClick={() => handleCopy("password")}>
              <i className="bi bi-clipboard d-inline-block"></i>
            </button>
          </label>
        </div>
        <div className="modal" id="testCloseContainer" tabIndex="-1" role="dialog" aria-labelledby="testCloseContainerModalLabel"
             aria-hidden="true">
          <div className="modal-dialog" id="testDocContainer" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="testCloseContainerModalLabel">Test Submission Confirmation</h5>
              </div>
              <div className="modal-body text-wrap" style={{ "margin-left": "0" }}>
                <p >Do you really want to complete this test (this Action can't be reverted)?</p>
              </div>
              <div className="modal-footer justify-content-between">
                <button type="button" className="btn btn-primary" onClick={onApplyForTest}>Complete Test</button>
                <button type="button" className="btn-danger btn btn-primary" data-dismiss="modal" onClick={() => handleConfirmationBox('cancel')}>Back To Test</button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", width: "15%" }}>
          <Button
            className="mx-1"
            style={{ padding: " 1% 8%" }}
            onClick={() => handleConfirmationBox('delete')}
          >
            Complete Test
          </Button>
          <Button
            className="btn-danger"
            style={{ padding: " 1% 8%" }}
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </div>
    );
  };
  return createModal({
    Content: <Content />,
    SubmitComponent: <SubmitComponent />,
    // title: "Unreal Modal",
    modalConfig: { size: "tl" },

    show: show ? true : false,
    overwriteClassName: "unreal-modal",
    closeButton: false,
  });
}

export default UnrealModal;
