import approvalReducer from "./employer/approvalReducer";
import auth from "./authReducer";
import { combineReducers } from "redux";
import contact from "./employer/contactReducer";
import employerDetailsReducer from "./employer/employerDetailsReducer";
import hiring from "./employer/hiringReducer";
import modal from "./setModal";
import suitablecandidate from "./employer/suitablecandidateReducer";
import tellusmore from "./employer/tellusmoreReducer";
import testReducer from "./testReducer";
import unrealReducer from "./unrealReducer";
import userReducer from "./userReducer";

// import dashboardReducer from "./dashboardReducer";
const employer = combineReducers({
  contact,
  tellusmore,
  hiring,
  suitablecandidate,
  approval: approvalReducer,
  employerDetails: employerDetailsReducer,
});
const tests = combineReducers({
  testDetails: testReducer,
});
// const dashboard = combineReducers({
//   dashboardReducer,
// });

const appReducer = combineReducers({
  user: userReducer,
  auth,
  modal,
  tests,
  employer,
  // dashboard,
  unreal: unrealReducer,
});
const rootReducer = (state, action) => appReducer(state, action);
export default rootReducer;
