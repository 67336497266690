import { RESET_SIGNUP_CREDS, SET_SIGNUP_CREDS } from "../Actions/types";

import initialState from "../InitialState/initialState";

export default function userReducer(
  state = initialState?.user,
  { type, payload }
) {
  switch (type) {
    case SET_SIGNUP_CREDS:
      return { ...state, ...payload };
    case RESET_SIGNUP_CREDS:
      return initialState?.user;
    default:
      return state;
  }
}
