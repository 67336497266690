import "./EmployerCard.scss";

import React, { useState } from "react";

import { Link } from "react-router-dom";
import { encodeURI2 } from "../../../utils/helperFunctions";
import { postApi } from "../../../utils/ApiMethods";
import { useSelector } from "react-redux";

function EmployerCard({ item, index }) {
  const { id } = useSelector((state) => state?.employer?.employerDetails);
  const [disabled, setDisabled] = useState(
    item?.shortlistedCandidates?.[0]?.isShortlisted
  );
  const handelShortList = async (value) => {
    try {
      const response = await postApi(`shortlisted/selectCandidate`, {
        employerId: id,
        userId: value.id,
      });
      const { status } = response;
      if (status === 200) {
        setDisabled(true);
      } else {
      }
    } catch (err) {}
  };

  return (
    <>
      <div
        className=" employercard card border-0 shadow-sm overflow-hidden mb-4"
        key={index}
        hidden={item.id === 1}
      >
        <div className="row">
          <div className="col-2">
            {item &&
            item.accountDetails &&
            item.accountDetails[0].userProfile ? (
              <img
                alt=""
                style={{
                  height: "61px",
                  width: "61px",
                  maxWidth: "61px",
                  borderRadius: "8px",
                  margin: "15px",
                }}
                src={`${
                  item.accountDetails[0].userProfile
                    ? item.accountDetails[0].userProfile.startsWith("http")
                      ? encodeURI(item.accountDetails[0].userProfile)
                      : encodeURI2(item.accountDetails[0].userProfile)
                    : "/placeholder.jpg"
                }`}
              ></img>
            ) : (
              <i
                className="candidateProfilePic "
                style={{ margin: "15px" }}
              ></i>
            )}
          </div>
          <div className="col-10">
            <div className="employer-body card-body">
              <div className="fs-sm text-muted mb-1">
                {item.state}
                <button
                  type="button"
                  className="btn btn-outline-primary button-shortlist"
                  style={{ float: "right" }}
                  onClick={() => handelShortList(item)}
                  disabled={disabled}
                >
                  Shortlist
                </button>
              </div>
              <div>
                <Link
                  to={`/employer/dashboard/profile/${item.id}`}
                  class="stretched-link"
                ></Link>
                <h2 className="h4 pb-1 mb-2">
                  <span className="text-capitalize">{"ID : " + item.id}</span>
                </h2>
                <div className="d-flex align-items-center card-description  my-2">
                  <i className="vector "></i>
                  <p className="description">{item.speciality}</p>
                </div>
                <div className="d-flex align-items-center card-description  my-2">
                  <i className="note "></i>
                  <p className="description">{`${item?.experience} Years`}</p>
                </div>
                <div className="d-flex align-items-center card-description  my-2">
                  <i className="star "></i>
                  <p className="description">{item?.speciality}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployerCard;
