export const SET_SIGNUP_CREDS = "SET_SIGNUP_CREDS";
export const RESET_SIGNUP_CREDS = "RESET_SIGNUP_CREDS";
export const SET_AUTH = "SET_AUTH";
export const RESET_AUTH = "RESET_AUTH";
export const SET_MODAL = "SET_MODAL";
export const RESET_MODAL = "RESET_MODAL";
export const SET_TEST_DETAILS = "SET_TEST_DETAILS";
export const RESET_TEST_DETAILS = "RESET_TEST_DETAILS";
export const SET_EMPLOYER_CONTACT = "SET_EMPLOYER_CONTACT";
export const RESET_EMPLOYER_CONTACT = "RESET_EMPLOYER_CONTACT";
export const SET_EMPLOYER_TELL_US_MORE = "SET_EMPLOYER_TELL_US_MORE";
export const RESET_EMPLOYER_TELL_US_MORE = "RESET_EMPLOYER_TELL_US_MORE";
export const SET_EMPLOYER_HIRING = "SET_EMPLOYER_HIRING";
export const RESET_EMPLOYER_HIRING = "RESET_EMPLOYER_HIRING";
export const SET_EMPLOYER_SUITABLE_CANDIDATE =
  "SET_EMPLOYER_SUITABLE_CANDIDATE";
export const RESET_EMPLOYER_SUITABLE_CANDIDATE =
  "RESET_EMPLOYER_SUITABLE_CANDIDATE";
export const SET_EMPLOYER_APPROVAL = "SET_EMPLOYER_APPROVAL";

export const RESET_EMPLOYER_APPROVAL = "RESET_EMPLOYER_APPROVAL";

// export const SET_EMPLOYER_SUITABLE_CALENDLY = "SET_EMPLOYER_SUITABLE_CALENDLY";
// export const RESET_EMPLOYER_SUITABLE_CALENDLY =
//   "RESET_EMPLOYER_SUITABLE_CALENDLY";
export const SET_UNREAL = "SET_UNREAL";
export const RESET_UNREAL = "RESET_UNREAL";
export const SET_EMPLOYER_DETAILS = "SET_EMPLOYER_DETAILS";

export const RESET_EMPLOYER_DETAILS = "RESET_EMPLOYER_DETAILS";
