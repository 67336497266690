import { CLIENT_ID, REDIRECT_URL, SCOPE, USER } from "../../constant";
import { SET_AUTH, SET_SIGNUP_CREDS } from "../../Redux/Actions/types";

import React from "react";
import { postApiWithoutToken } from "../../utils/ApiMethods";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { useNavigate } from "react-router-dom";

function LinkedInPage(code) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getDetailsFromLinkedin = async (code) => {
    let response;
    try {
      response = await postApiWithoutToken("users/getAccessTokenLinkedIn", {
        code: code,
        redirect_uri: `${window?.location.origin}/${REDIRECT_URL}`,
      });
      if (response?.data?.status === 1) {
        const { data } = response?.data;
        localStorage.setItem("token", data?.token);
        dispatch({
          type: SET_AUTH,
          payload: { token: data?.token, type: USER },
        });
        dispatch({
          type: SET_SIGNUP_CREDS,
          payload: {
            firstName: data?.["accountDetails.firstName"],
            lastName: data?.["accountDetails.lastName"],
            isProfileComplete: data?.isProfileComplete,
            isProfile: data?.isProfile,
            isConfirm: data?.isConfirm,
            candidateId: data?.id,
            email: data?.email,
          },
        });

        navigate("/dashboard");
      }
    } catch (error) {
      toast.error("Something Went wrong");
    }
  };
  const { linkedInLogin } = useLinkedIn({
    clientId: CLIENT_ID,
    scope: SCOPE,
    redirectUri: `${window?.location.origin}/${REDIRECT_URL}`, //`${window.location.origin}/test`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      try {
        getDetailsFromLinkedin(code);
      } catch (error) {
        toast.error("Something Went wrong");
      }
    },
    onError: (error) => {},
  });

  return (
    <div
      className="btn btn-icon btn-secondary btn-primary btn-lg w-100 text-primary"
      onClick={linkedInLogin}
    >
      <i className="bx bxl-linkedin fs-xl me-2 pb-1 " />
      LinkedIn
    </div>
  );
}
export default LinkedInPage;
