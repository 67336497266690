import "./customcard.scss";

import { Card } from "react-bootstrap";
import React from "react";
import { useEffect } from "react";

function CustomCard({
  showAlert = {},
  CardBody = () => {},
  Header = () => {},
  override = "",
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {showAlert?.isActive && (
        <div className="alert-custom-employer">
          <p>{showAlert?.message}</p>
        </div>
      )}
      <Card className="employerCard">
        <Card.Header>{Header}</Card.Header>
        <Card.Body className={override}>{CardBody}</Card.Body>
      </Card>
    </>
  );
}

export default CustomCard;
