const initialState = {
  user: {
    firstName: "",
    lastName: "",
    email: "",
    resume: "",
    userPortfolio: "",
    experience: "0",
    speciality: "",
    skills: [],
    isPriorexp: false,
    daysforWorking: 0,
    isProfileComplete: false,
    availability: { title: "", description: "", index: "" },
    currentEarning: "",
    expectedEarning: "",
    candidateId: "",
  },
  auth: {},
  tests: {
    testDetails: {},
  },
  dashboard: {},
  task: {},
  jobs: {},
  messages: {},
  modal: {},
  employer: {
    contact: {
      email: "",
      fullName: "",
      contact: "",
    },
    tellusmore: {
      noOfPeople: [
        { title: "1-10", isActive: false },
        { title: "11-50", isActive: false },
        { title: "51-100", isActive: false },
        { title: "100+", isActive: false },
      ],
      aboutProject: "",
      activeIndex: -1,
      start: "",
    },
    hiring: {
      company: "",
      email: "",
      companyIndex: -1,
      seedIndex: -1,
      publicListedIndex: -1,
      companysize: [
        { title: "1-10", isActive: false },
        { title: "11-50", isActive: false },
        { title: "51-250", isActive: false },
        { title: "10k+", isActive: false },
      ],
      hiringNeed: [
        { title: "Part time", isActive: false },
        { title: "Full time", isActive: false },
        { title: "Remote", isActive: false },
      ],
    },
    suitablecandidate: {
      candidate: [
        {
          name: "Samira Mehta",
          speciality: "Tech Artist",
          technology: "Mid-level Unreal Engine Generalist",
          experience: "1-3 Years",
          isActive: false,
        },
        {
          name: "Samira Mehta",
          speciality: "Tech Artist",
          technology: "Mid-level Unreal Engine Generalist",
          experience: "1-2 Years",
          isActive: false,
        },
        {
          name: "Samira Mehta",
          speciality: "Tech Artist",
          technology: "Mid-level Unreal Engine Generalist",
          experience: "1-2 Years",
          isActive: false,
        },
      ],
    },
    approval: { isApproved: false },
    employerDetails: {
      id: "",
    },
  },
  unreal: {
    url: "",
    password: "",
    loaded: false,
  },
};
export default initialState;
