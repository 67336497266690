import React from 'react';
import CoverImage from '../../Assets/img/blog/single/cover-image.jpg';
import BannerOne from '../../Assets/img/blog/flame.svg';
import CompanyOne from '../../Assets/img/company/intel.png';
import CompanyTwo from '../../Assets/img/company/wipro.png';
import CompanyThree from '../../Assets/img/company/ibm.png';

export default function CandidateLandingOne() {
    return (
        <>
            <section
                className="mb-5 dark-mode position-relative jarallax pb-xl-3"
                data-jarallax=""
                data-speed="0.4"

            >
                {/* Parallax img */}
                <div
                    className="jarallax-img bg-dark opacity-70"
                    style={{ backgroundImage: `url(${CoverImage})` }}
                />
                {/* Overlay bg */}
                <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-70 zindex-1" />
                {/* Overlay content */}
                <div className="container position-relative pt-5 pb-0 zindex-5">
                    <div className="row mb-xxl-5 py-md-4 py-lg-5">
                        <div className="col-lg-6 col-md-7 pb-3 pb-md-0 mb-4 mb-md-5">
                            <div className="mb-3 fs-lg text-light">
                                {/* <img
                        src={BannerOne}
                        width={24}
                        alt="Flame icon"
                        className="mt-n1 me-1"
                    /> */}
                                <i className="bx bx-user me-2" />
                                For Developers
                            </div>
                            <h1 className="display-5 pb-md-3">
                                Get your dream job as a 3D developer based on our vetting process
                            </h1>
                            <div className="d-flex flex-wrap mb-md-5 mb-4 pb-md-2 text-white">
                                {/* <div className="border-end border-light h-100 mb-2 pe-3 me-3">
                        <span className="badge bg-faded-light fs-base">Grab the opportunity</span>
                    </div>
                    <div className="border-end border-light mb-2 pe-3 me-3 opacity-70">
                    Top Companies
                    </div>  */}
                                Join a network of the world's best and vetted 3D developers & get full-time, long-term remote 3D Developer jobs with better compensation and career growth.
                            </div>
                            <a href="/sign-up" className="btn btn-lg btn-primary">
                                Get Verified
                                <i className="bx bx-right-arrow-alt ms-2 me-n1 lead" />
                            </a>
                        </div>
                        {/* Articles slider */}
                        <div className="col-lg-4 offset-lg-2 col-md-5">
                            <div
                                className="swiper overflow-hidden w-100 ms-n2 ms-md-0 pe-3 pe-sm-4"
                                style={{ maxHeight: 405 }}
                                data-swiper-options='{
                    "direction": "vertical",
                    "slidesPerView": "auto",
                    "freeMode": true,
                    "scrollbar": {
                        "el": ".swiper-scrollbar"
                    },
                    "mousewheel": true
                    }'
                            >
                                <div className="swiper-wrapper pe-md-2">
                                    <div className="swiper-slide h-auto px-2">
                                        <div className="row row-cols-md-1 row-cols-sm-2 row-cols-1 g-md-4 g-3">
                                            {/* Article */}
                                            <div className="col">
                                                <article className="card h-100 border-0 shadow-sm card-hover-primary">
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                            <span
                                                                
                                                                className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle"
                                                            >
                                                                <img src={CompanyOne} className="img-fluid" />
                                                            </span>
                                                            <span className="fs-sm text-muted">June 2, 2022</span>
                                                        </div>
                                                        <span className="badge fs-sm text-nav bg-white text-decoration-none position-relative zindex-2 mb-2">New Delhi</span>
                                                        <h3 className="h5 mb-0">
                                                            <span className="stretched-link">
                                                                Hired Ram Krishan as Mesh Developer, Unreal Engine
                                                            </span>
                                                        </h3>
                                                    </div>
                                                    {/* <div className="card-footer d-flex align-items-center py-4 text-muted border-top-0">
                                    <div className="d-flex align-items-center me-3">
                                        <i className="bx bx-like fs-lg me-1" />
                                        <span className="fs-sm">8</span>
                                    </div>
                                    <div className="d-flex align-items-center me-3">
                                        <i className="bx bx-comment fs-lg me-1" />
                                        <span className="fs-sm">4</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <i className="bx bx-share-alt fs-lg me-1" />
                                        <span className="fs-sm">2</span>
                                    </div>
                                    </div> */}
                                                </article>
                                            </div>
                                            <div className="col">
                                                <article className="card h-100 border-0 shadow-sm card-hover-primary">
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                            <span
                                                               
                                                                className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle"
                                                            >
                                                                {/* <i className="bx bx-bookmark" /> */}
                                                                <img src={CompanyTwo} className="img-fluid" />
                                                            </span>
                                                            <span className="fs-sm text-muted">May 15, 2022</span>
                                                        </div>
                                                        <span className="badge fs-sm text-nav bg-white text-decoration-none position-relative zindex-2 mb-2">Pune</span>
                                                        <h3 className="h5 mb-0">
                                                            <span className="stretched-link">
                                                                Hired Krishna as 3D Optimization Developer, Unreal Engine
                                                            </span>
                                                        </h3>
                                                    </div>
                                                    {/* <div className="card-footer d-flex align-items-center py-4 text-muted border-top-0">
                                    <div className="d-flex align-items-center me-3">
                                        <i className="bx bx-like fs-lg me-1" />
                                        <span className="fs-sm">8</span>
                                    </div>
                                    <div className="d-flex align-items-center me-3">
                                        <i className="bx bx-comment fs-lg me-1" />
                                        <span className="fs-sm">4</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <i className="bx bx-share-alt fs-lg me-1" />
                                        <span className="fs-sm">2</span>
                                    </div>
                                    </div> */}
                                                </article>
                                            </div>
                                            <div className="col">
                                                <article className="card h-100 border-0 shadow-sm card-hover-primary">
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                            <a
                                                                href="#"
                                                                className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle"
                                                            >
                                                                {/* <i className="bx bx-bookmark" /> */}
                                                                <img src={CompanyThree} className="img-fluid" />
                                                            </a>
                                                            <span className="fs-sm text-muted">April 24, 2022</span>
                                                        </div>
                                                        <span className="badge fs-sm text-nav bg-white text-decoration-none position-relative zindex-2 mb-2">Chennai</span>
                                                        <h3 className="h5 mb-0">
                                                            <span className="stretched-link">
                                                                Hired Rahul as Shader Developer, Unreal Engine
                                                            </span>
                                                        </h3>
                                                    </div>
                                                    {/* <div className="card-footer d-flex align-items-center py-4 text-muted border-top-0">
                                    <div className="d-flex align-items-center me-3">
                                        <i className="bx bx-like fs-lg me-1" />
                                        <span className="fs-sm">8</span>
                                    </div>
                                    <div className="d-flex align-items-center me-3">
                                        <i className="bx bx-comment fs-lg me-1" />
                                        <span className="fs-sm">4</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <i className="bx bx-share-alt fs-lg me-1" />
                                        <span className="fs-sm">2</span>
                                    </div>
                                    </div> */}
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-scrollbar" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
