import { RESET_TEST_DETAILS, SET_TEST_DETAILS } from "../Actions/types";

import initialState from "../InitialState/initialState";

export default function testReducer(
  state = initialState?.tests?.testDetails,
  { type, payload }
) {
  switch (type) {
    case SET_TEST_DETAILS:
      return { ...state, ...payload };
    case RESET_TEST_DETAILS:
      return initialState?.tests?.testDetails;
    default:
      return state;
  }
}
