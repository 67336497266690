import { Link, useNavigate } from "react-router-dom";
import {
  RESET_AUTH,
  RESET_MODAL,
  RESET_SIGNUP_CREDS,
  SET_MODAL,
} from "../../Redux/Actions/types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ApprovedPendingModal from "./ApprovedPendingModal";
import Helmet from "react-helmet";
import { Loader } from "../../Components/Loader";
import Select from "react-select";
import { encodeURI2 } from "../../utils/helperFunctions";
import { getApi } from "../../utils/ApiMethods";
import { testOptions } from "../../utils/jsonData";
import { toast } from "react-toastify";

export default function TestList() {
  const { isProfile, isConfirm } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [testList, setTestList] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  window.scrollTo(0, 0);
  useEffect(() => {
    getAllTestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isProfile === "pending") {
      navigate("/profilereview");
    } else if (isProfile === "approval" && isConfirm === false) {
      dispatch({ type: SET_MODAL, payload: { verificationModal: true } });
    } else {
      dispatch({ type: RESET_MODAL });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfile, isConfirm]);

  const getAllTestList = async () => {
    try {
      setLoading(true);
      const response = await getApi(`testDetail/getTestDetails`);
      const data = response.data;
      if (data.status) {
        setTestList(data.data);
      } else {
        toast.error("Sorry Something went wrong");
      }
    } catch (err) {
      toast.error(err.response.data.message);
      if (err.response.status == 401) {
        localStorage.removeItem("token");
        dispatch({ type: RESET_AUTH });
        dispatch({ type: RESET_SIGNUP_CREDS });
        localStorage.removeItem("ltoken");
        navigate("/sign-up");
      }
      setLoading(false);
    }
    setLoading(false);
  };

  // const calculateImageType = (index) => {
  //   let modulo = ((index % 3) + 3) % 3;
  //   return modulo;
  // };
  return (
    <>
      {/* <Header /> */}
      <Helmet>
        <script>initializeJSRoot()</script>
      </Helmet>
      <section className="container">
        <div className="row ">
          <div className="col-md-8 offset-lg-1 pb-5 mb-lg-2 pt-md-2 mt-n3 mt-md-0">
            <div className="ps-md-3 ps-lg-0 mt-md-2 pt-md-4 pb-md-2">
              <div className="d-sm-flex align-items-center justify-content-between pt-xl-1 mb-3 pb-3">
                <h1 className="h2 mb-sm-0">Tests</h1>
                {/* <select className="form-select" style={{ maxWidth: "15rem" }}>
                  <option value="Category">Category</option>
                  <option value="Word Build">Optimization</option>
                  <option value="Word Build">Word Build</option>
                  <option value="Shaders">Shaders</option>
                  <option value="Shaders">Movies</option>
                </select> */}

                <Select
                  placeholder={
                    <div>
                      Tests &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                  }
                  name="experience"
                  options={testOptions}
                  onChange={() => navigate("/test")}
                />
              </div>
              {/* Item */}

              {loading ? (
                <Loader />
              ) : testList?.length > 0 ? (
                testList?.map((item, index) => {
                  return (
                    <div
                      className="card border-0 shadow-sm overflow-hidden mb-4"
                      key={index}
                    >
                      <div className="row g-0">
                        {/* {
                            item.bannerImage
                            ?
                                <Link
                            to={`/test-detail/${item.id}`}
                            className="col-sm-4 bg-repeat-0 bg-position-center bg-size-cover"

                            style={{ 
                                
                                backgroundImage: 'url('+encodeURI(process.env.REACT_APP_BaseUrl +item.bannerImage)+')',
                                minHeight: "13rem"
                            }}
                            />:
                            <Link
                            to={`/test-detail/${item.id}`}
                            className="col-sm-4 bg-repeat-0 bg-position-center bg-size-cover"
                            
                            style={{ 
                                
                                backgroundImage: `url(/placeholder.jpg)`,
                                minHeight: "13rem"
                            }}
                            />
                        } */}
                        <Link
                          to={`test-detail/${item.id}`}
                          className="col-sm-4 bg-repeat-0 bg-position-center bg-size-cover"
                          style={{
                            backgroundImage: `${
                              item.bannerImage
                                ? "url(" + encodeURI2(item.bannerImage) + ")"
                                : "url(/placeholder.jpg)"
                            }`,
                            minHeight: "13rem",
                          }}
                        />

                        {/* <Link
                            to={`/test-detail/${item.id}`}
                            className="col-sm-4 bg-repeat-0 bg-position-center bg-size-cover"
                            style={{
                                backgroundImage: `url(${calculateImageType(index)==0?CollectionImageOne:calculateImageType(index)==1?CollectionImageTwo:CollectionImageThree})`,
                                minHeight: "13rem"
                            }}
                            /> */}

                        <div className="col-sm-8">
                          <div className="card-body">
                            <div className="fs-sm text-muted mb-1">
                              {new Date(item.createdAt)
                                .toDateString()
                                .slice(4, 15)}{" "}
                              {
                                index == 0
                                // && <span className="badge bg-success text-white fs-sm text-decoration-none ms-2 d-inline-block ">Completed</span>
                              }
                            </div>
                            <h2 className="h4 pb-1 mb-2">
                              <Link to={`test-detail/${item.id}`}>
                                {item.title}
                              </Link>
                            </h2>
                            <p className="mb-4 mb-lg-5">
                              {/* Graphic Design / Motion Design */}
                              {item.summary}
                            </p>
                            <div className="d-flex">
                              <Link
                                to={`test-detail/${item.id}`}
                                className="btn btn-outline-primary px-3 px-lg-4 me-3"
                              >
                                <i className="bx bx-arrow-to-right fs-xl me-xl-2" />
                                <span className="d-inline">View more</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h1>No Tests Available</h1>
              )}
              {/* <div className="card border-0 shadow-sm overflow-hidden mb-4">
                        <div className="row g-0">
                            <a
                            href="#"
                            className="col-sm-4 bg-repeat-0 bg-position-center bg-size-cover"
                            style={{
                                backgroundImage: `url(${CollectionImageOne})`,
                                minHeight: "13rem"
                            }}
                            />
                            <div className="col-sm-8">
                            <div className="card-body">
                                <div className="fs-sm text-muted mb-1">Nov 30, 2021 <span className="badge bg-success text-white fs-sm text-decoration-none ms-2 d-inline-block">Completed</span></div>
                                <h2 className="h4 pb-1 mb-2">
                                <a href="#">Optimization</a>
                                </h2>
                                <p className="mb-4 mb-lg-5">
                                Graphic Design / Motion Design
                                </p>
                                <div className="d-flex">
                                <Link to="/test-detail" className="btn btn-outline-primary px-3 px-lg-4 me-3"> 
                                    <i className="bx bx-arrow-to-right fs-xl me-xl-2" /> 
                                    <span className="d-none d-xl-inline">View more</span> 
                                </Link> 
                                </div>
                            </div>
                            </div>
                        </div>
                        </div> */}
              {/* Item */}
              {/* <div className="card border-0 shadow-sm overflow-hidden mb-4">
                        <div className="row g-0">
                            <a
                            href="#"
                            className="col-sm-4 bg-repeat-0 bg-position-center bg-size-cover"
                            style={{
                                backgroundImage: `url(${CollectionImageTwo})`,
                                minHeight: "13rem"
                            }}
                            />
                            <div className="col-sm-8">
                            <div className="card-body">
                                <div className="fs-sm text-muted mb-1">Oct 18, 2021 <span className="badge bg-primary text-white fs-sm text-decoration-none ms-2 d-inline-block">New</span></div>
                                <h2 className="h4 pb-1 mb-2">
                                <a href="#">
                                Word Build
                                </a>
                                </h2>
                                <p className="mb-4 mb-lg-5">
                                Graphic Design / Art / Identity / Motion Design
                                </p>
                                <div className="d-flex">
                                <Link to="/test-detail" className="btn btn-outline-primary px-3 px-lg-4 me-3"> 
                                    <i className="bx bx-arrow-to-right fs-xl me-xl-2" /> 
                                    <span className="d-none d-xl-inline">View more</span> 
                                </Link> 
                                </div>
                            </div>
                            </div>
                        </div>
                        </div> */}
              {/* Item */}
              {/* <div className="card border-0 shadow-sm overflow-hidden mb-4">
                            <div className="row g-0">
                                <a
                                href="#"
                                className="col-sm-4 bg-repeat-0 bg-position-center bg-size-cover"
                                style={{
                                    backgroundImage: `url(${CollectionImageThree})`,
                                    minHeight: "13rem"
                                }}
                                />
                                <div className="col-sm-8">
                                <div className="card-body">
                                    <div className="fs-sm text-muted mb-1">Aug 05, 2021 <span className="badge bg-danger text-white fs-sm text-decoration-none ms-2 d-inline-block">Try Again</span></div>
                                    <h2 className="h4 pb-1 mb-2">
                                    <a href="#">Shaders</a>
                                    </h2>
                                    <p className="mb-4 mb-lg-5">
                                    Mobile App Design / Graphic Design / Art
                                    </p>
                                    <div className="d-flex">
                                    <Link to="/test-detail" className="btn btn-outline-primary px-3 px-lg-4 me-3"> 
                                        <i className="bx bx-arrow-to-right fs-xl me-xl-2" /> 
                                        <span className="d-none d-xl-inline">View more</span> 
                                    </Link> 
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div> */}

              {/* Item */}
              {/* <div className="card border-0 shadow-sm overflow-hidden mb-4">
                            <div className="row g-0">
                                <a
                                href="#"
                                className="col-sm-4 bg-repeat-0 bg-position-center bg-size-cover"
                                style={{
                                    backgroundImage: `url(${CollectionImageOne})`,
                                    minHeight: "13rem"
                                }}
                                />
                                <div className="col-sm-8">
                                <div className="card-body">
                                    <div className="fs-sm text-muted mb-1">Apr 15, 2021 </div>
                                    <h2 className="h4 pb-1 mb-2">
                                    <a href="#">Movies</a>
                                    </h2>
                                    <p className="mb-4 mb-lg-5">
                                    3D App Design / Graphic Design / Art
                                    </p>
                                    <div className="d-flex">
                                    <Link to="/test-detail" className="btn btn-outline-primary px-3 px-lg-4 me-3"> 
                                        <i className="bx bx-arrow-to-right fs-xl me-xl-2" /> 
                                        <span className="d-none d-xl-inline">View more</span> 
                                    </Link> 
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div> */}
            </div>
          </div>
        </div>
      </section>
      <ApprovedPendingModal />
    </>
  );
}
