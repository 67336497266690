import "./availability.scss";

import AvailabilityList from "./AvailabilityList";
import React from "react";

function Availability(props) {
  return (
    <div className="availability">
      <div className="title">
        <div className="dotSymbol"></div>
        <label>Set Your Availability *</label>
      </div>
      <AvailabilityList {...props} />
    </div>
  );
}

export default Availability;
