import {
  RESET_MODAL,
  SET_AUTH,
  SET_EMPLOYER_DETAILS,
} from "../../../Redux/Actions/types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { EMPLOYER } from "../../../constant";
import { Loader } from "../../../Components/Loader";
import Modal from "../../../Components/common/modal/Modal";
import OtpInput from "react-otp-input";
import { postApiWithoutToken } from "../../../utils/ApiMethods";
import { toast } from "react-toastify";

function OTPModalEmployer(props) {
  const currentModal = "employer_model";
  const modal = useSelector((state) => state.modal);
  const show = modal[currentModal];
  const dispatch = useDispatch();
  const onHandleClose = () => {
    dispatch({ type: RESET_MODAL });
  };

  const { createModal, ModalSize } = Modal;
  const Content = (props) => {
    const { email } = useSelector((state) => state?.employer?.contact);
    const { setIsOpen } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [isWrongOtp, setIsWrongOtp] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [otp, setOTP] = useState("");
    const navigate = useNavigate();
    const resendOtp = async () => {
      try {
        const response = await postApiWithoutToken(`users/employerLogin`, {
          email,
        });

        const { data, status } = response;

        if (status === 200) {
          if (data.status === 1) {
            toast?.success("OTP Resend");
          }
        }
      } catch (error) {
        toast.error(error);
      }
    };
    function closeModal() {
      setIsOpen(false);
      setErrorMsg("");
      setOTP("");
      setIsWrongOtp("");
      onHandleClose();
    }
    const onVerifyOtp = async () => {
      try {
        setLoading(true);
        // let propertyValues = Object.values(values);
        // let code = propertyValues.join('')
        //
        if (otp.length < 6) {
          // toast.error("Otp is not of 6 digits")
          setIsWrongOtp(true);
          setErrorMsg("Please Enter 6 digits otp");
        } else {
          const response = await postApiWithoutToken(`users/otpVerification`, {
            email: email,
            otp: otp,
          });
          const { data, status } = response;

          if (status === 200) {
            if (data?.status === "1") {
              localStorage.setItem("token", data?.data?.token);
              dispatch({
                type: SET_AUTH,
                payload: { token: data?.data?.token, type: EMPLOYER },
              });
              dispatch({
                type: SET_EMPLOYER_DETAILS,
                payload: { id: data?.data?.id },
              });
              if (data.data.isApproved) {
                // navigate("/candidate/");
                navigate("/employer/dashboard/candidate");
              } else {
                navigate("/employer/dashboard/calls");
              }
            } else {
              setIsWrongOtp(true);
              setErrorMsg(data?.message);
            }
          } else {
            toast.error("Something Went Wrong");
          }
        }
      } catch (error) {
        setLoading(false);
        toast.error("Something Went Wrong");
      }
      setLoading(false);
    };

    const handleOtpInput = (otp) => {
      setIsWrongOtp(false);
      setErrorMsg("");
      setOTP(otp);
    };
    return (
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body p-4 p-sm-5">
            <div className="text-end">
              <button
                className="btn-close"
                style={{ display: "none" }}
                id="dissmissModal"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="wrapper">
              <div className="col-sm-12 text-center">
                <form className="rounded bg-white">
                  <h3 className="text-uppercase text-dark fw-bolder fs-3 mb-2">
                    Email Verification
                  </h3>
                  <div className="fw-normal text-muted mb-2">
                    Enter the verification code we sent to
                  </div>
                  <div className="mb-4">
                    <a className="text-decoration-none">{email}</a>
                  </div>
                  <div className="otp_input text-start mb-2">
                    <label htmlFor="digit">
                      Type your 6 digit security code
                    </label>

                    <div className="text-center mt-2 ">
                      <OtpInput
                        inputStyle={{
                          maxHeight: 60,
                          maxWidth: 60,
                          width: "2.8rem",
                          outline: 0,
                        }}
                        className={`me-2 text-center fs-3  w-100 `}
                        errorStyle={{ border: "solid 1px red" }}
                        hasErrored={isWrongOtp}
                        isInputNum
                        value={otp}
                        onChange={(otp) => handleOtpInput(otp)}
                        numInputs={6}
                        separator={<span> </span>}
                      />
                    </div>
                    {isWrongOtp && (
                      <div className="text-danger">{errorMsg}</div>
                    )}
                  </div>

                  <div
                    className="btn btn-primary submit_btn my-4"
                    onClick={onVerifyOtp}
                  >
                    {loading ? <Loader /> : "Submit"}
                  </div>

                  <div className="fw-normal text-muted mb-2">
                    Didn’t get the code ?{" "}
                    <a
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={resendOtp}
                    >
                      Resend
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return createModal({
    Content: <Content />,
    modalConfig: { size: ModalSize?.xs },
    onHandleClose,
    show: show ? true : false,
  });
}

export default OTPModalEmployer;
