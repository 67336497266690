import "./custommodal.scss";

import { Button, Modal } from "react-bootstrap";

import React from "react";
import { useState } from "react";

function CustomModal({
  title,
  Content,
  SubmitComponent,
  modalConfig,
  onHandleClose,
  show,
  overwriteClassName,
  closeButton = true,
}) {
  return (
    <Modal
      className={overwriteClassName}
      show={show}
      onHide={onHandleClose}
      size={modalConfig?.size ?? "lg"}
      style={{ width: "1200px !important" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {title && (
        <Modal.Header closeButton={closeButton}>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body className="d-flex justify-content-center align-items-center position-relative">
        {Content}
      </Modal.Body>
      {SubmitComponent && <Modal.Footer>{SubmitComponent}</Modal.Footer>}
    </Modal>
  );
}

export default CustomModal;
