import { SERVER_ERROR_CODE } from "../constant";

export const encodeURI2 = (url) => {
  if (Boolean(url.toString().startsWith("https"))) {
    return encodeURI(url);
  } else {
    return encodeURI(process.env.REACT_APP_BucketUrl + url);
  }
};
export const validateEmail = (email) => {
  const expressionTest = /\S+@\S+\.\S+/;
  return expressionTest.test(email);
};

export const getValidation = ({ value, name, checked }) => {
  const currentError = { errorMsg: "", isError: false };
  switch (name) {
    case "fullName":
      if (value?.length > 115) {
        currentError.errorMsg = "Must be 115 characters or less";
        currentError.isError = true;
      } else if (value?.length === 0) {
        currentError.errorMsg = "Required";
        currentError.isError = true;
      } else {
        currentError.errorMsg = "";
        currentError.isError = false;
      }
      break;
    case "company":
      if (value?.length > 115) {
        currentError.errorMsg = "Must be 115 characters or less";
        currentError.isError = true;
      } else if (value?.length === 0) {
        currentError.errorMsg = "Required";
        currentError.isError = true;
      } else {
        currentError.errorMsg = "";
        currentError.isError = false;
      }
      break;
    case "contact":
      if (value?.length > 10) {
        currentError.errorMsg = "Must be 10 or less";
        currentError.isError = true;
      } else if (value?.length === 0) {
        currentError.errorMsg = "Required";
        currentError.isError = true;
      } else {
        currentError.errorMsg = "";
        currentError.isError = false;
      }
      break;
    case "email":
      let flag = validateEmail(value);
      if (value?.length === 0) {
        currentError.errorMsg = "Required";
        currentError.isError = true;
      } else if (!flag) {
        currentError.errorMsg = "Invalid Email address";
        currentError.isError = !flag;
      } else {
        currentError.errorMsg = "";
        currentError.isError = false;
      }
      break;

    default:
  }
  return currentError;
};

export const handelError = ({ code, message, logout = () => {} }) => {
  const { ERR_BAD_REQUEST } = SERVER_ERROR_CODE;
  switch (code) {
    case ERR_BAD_REQUEST:
      logout();
      break;
    default:
      logout();
  }
};
