import {
  RESET_EMPLOYER_APPROVAL,
  RESET_MODAL,
} from "../../../Redux/Actions/types";
import React, { useState } from "react";

import CustomCard from "../../../Components/common/card/CustomCard";
import IFrame from "../../../Components/common/iframe/IFrame";
import bodyTemp from "../commomComponent/BodyTemplate";
import headerTemp from "../commomComponent/HeaderTemplate";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Calendly(props) {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const content = (
    <IFrame
      url={"https://calendly.com/jay-productwarfare/30min"}
      onLoad={(value) => setLoading(value)}
    />
  );
  const body = bodyTemp({
    content: content,
    title: "Call With Visceral- Hire the Best",
    isClose: !loading,
    dispatch: dispatch,
    navigate: navigate,
  });
  const header = headerTemp({
    naviagteTo: "",
    title: "Call With Visceral team",
    dispatch: dispatch,
  });

  return (
    <CustomCard CardBody={body} Header={header} override="calender-body" />
  );
}

export default Calendly;
