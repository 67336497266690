import React from 'react';
import IdeaImage from '../../Assets/img/landing/digital-agency/icons/idea.svg'; 
import AwardImage from '../../Assets/img/landing/digital-agency/icons/award.svg'; 
import TeamImage from '../../Assets/img/landing/digital-agency/icons/team.svg'; 

export default function LandingSectionTwo() {
  return (
    <>
        <section className="container mb-5 pt-lg-5" id="benefits">
            <div
                className="swiper pt-3"
                data-swiper-options='{
                "slidesPerView": 1,
                "pagination": {
                "el": ".swiper-pagination",
                "clickable": true
                },
                "breakpoints": {
                "500": {
                    "slidesPerView": 2
                },
                "991": {
                    "slidesPerView": 3
                }
                }
            }'
            >
                <div className="swiper-wrapper">
                {/* Item */}
                <div className="swiper-slide border-end-lg px-2">
                    <div className="text-center">
                    <img
                        src={IdeaImage}
                        width={48}
                        alt="Bulb icon"
                        className="d-block mb-4 mx-auto"
                    />
                    <h4 className="mb-2 pb-1">Training</h4>
                    <p className="mx-auto" style={{ maxWidth: 336 }}>
                    Get trained in every dimension of Unreal Engine.
                    </p>
                    </div>
                </div>
                {/* Item */}
                <div className="swiper-slide border-end-lg px-2">
                    <div className="text-center">
                    <img
                        src={AwardImage}
                        width={48}
                        alt="Award icon"
                        className="d-block mb-4 mx-auto"
                    />
                    <h4 className="mb-2 pb-1">Testing</h4>
                    <p className="mx-auto" style={{ maxWidth: 336 }}>
                    Test your skills and get certified across skills on Unreal Engine.
                    </p>
                    </div>
                </div>
                {/* Item */}
                <div className="swiper-slide px-2">
                    <div className="text-center">
                    <img
                        src={TeamImage}
                        width={48}
                        alt="Team icon"
                        className="d-block mb-4 mx-auto"
                    />
                    <h4 className="mb-2 pb-1">Hire</h4>
                    <p className="mx-auto" style={{ maxWidth: 336 }}>
                    Hire individuals or teams for Unreal Engine and Fortnite Creative.
                    </p>
                    </div>
                </div>
                </div>
                {/* Pagination (bullets) */}
                <div className="swiper-pagination position-relative pt-2 pt-sm-3 mt-4" />
            </div>
        </section>

    </>
  )
}
