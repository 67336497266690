import React, { useEffect, useState } from "react";
import { getApi, postApi } from "../../../utils/ApiMethods";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Loader } from "../../../Components/Loader";
import ShortlistCard from "./ShortlistCard";
import { useSelector } from "react-redux";

function ShortListedCandidateList() {
  const [approvedUserList, setApprovedUserList] = useState({});
  const [isLoading, setLoading] = useState(true);
  const { id } = useSelector((state) => state?.employer?.employerDetails);
  let navigate = useNavigate();

  function debounce(func, timeout = 900) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
  const fetchAllApprovedCandidates = async () => {
    try {
      setLoading(true);

      const response = await postApi(
        `shortlisted/getAllUsersDetailsShortlisted`,
        { employerId: id }
      );

      const data = response.data;
      setApprovedUserList(data.data);

      setLoading(false);
    } catch (err) {
      // toast.error("Something went wrong");
      setLoading(false);
    }
  };
  const fetchUsers = debounce(() => fetchAllApprovedCandidates());
  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <section className="container">
        <div className="row pb-5 mb-lg-2 pt-5 mt-n3 mt-md-0">
          <div className="col-sm-12 col-6">
            <h1 className="h2 mb-4">Your Shortlists</h1>
          </div>
          <div className="col-sm-12 col-6">
            <div>
              {isLoading ? (
                <Loader />
              ) : (
                approvedUserList.map((item, index) => {
                  return (
                    <ShortlistCard
                      item={item}
                      index={index}
                      fetchUsers={fetchUsers}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ShortListedCandidateList;
