import { RESET_AUTH, SET_SIGNUP_CREDS } from "../../Redux/Actions/types";
import { getApi, putApi } from "../../utils/ApiMethods";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "react-bootstrap";
import Modal from "../../Components/common/modal/Modal";
import React from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function ApprovedPendingModal(props) {
  const currentModal = "verificationModal";
  const modal = useSelector((state) => state.modal);
  const show = modal[currentModal];
  const dispatch = useDispatch();
  const onHandleClose = () => {
    // dispatch({ type: RESET_MODAL });
  };
  const { createModal, ModalSize } = Modal;
  const Content = () => {
    const { isConfirm, isProfile } = useSelector((state) => state.user);
    const navigate = useNavigate();
    let h1 = "";
    let lbl = "";
    let icon = "";
    let isConfirmBtn = false;
    if (isProfile === "pending") {
      h1 = "Your Profile is Under Review";
      lbl =
        "We will notify you about our verification on your registered email id";
      icon = "reviews";
    } else if (isProfile === "approval" && isConfirm === false) {
      h1 = "Your Profile is Now Verified";
      lbl =
        "Take your first step to view all the jobs you are eligible and being considered for...";
      icon = "isverified";
      isConfirmBtn = true;
    }
    const getUserAccountDetails = async () => {
      const response = await getApi(`accountDetails/userDetails`);
      if (response?.data?.status === 1) {
        const { data } = response?.data;
        dispatch({
          type: SET_SIGNUP_CREDS,
          payload: {
            firstName: data?.["accountDetails.firstName"],
            lastName: data?.["accountDetails.lastName"],
            isProfileComplete: data?.isProfileComplete,
            isProfile: data?.isProfile,
            isConfirm: true,
            candidateId: data?.id,
          },
        });
      }
    };
    const handleUpdateAccount = async (values) => {
      let result = false;
      try {
        const response = await putApi(`accountDetails/updateAccountDetails`, {
          isConfirm: true,
        });

        const { status } = response;
        if (status === 200) {
          result = true;
          getUserAccountDetails();
        } else {
        }
      } catch (error) {
        // setLoading(false);
        dispatch({ type: RESET_AUTH });
        localStorage.removeItem("token");
        navigate("/sign-in");

        toast.error("Something went wrong");
      } finally {
      }
      // setLoading(false);
      return result;
    };

    return (
      <div className="d-flex flex-column align-items-center">
        <i className={icon} />
        <h1
          style={{
            fontWeight: "900",
            fontSize: "32px",
            lineHeight: "150%",
            textAlign: "center",
            color: "#131022",
            margin: "5% 0",
          }}
        >
          {h1}
        </h1>
        <label
          style={{
            textAlign: "center",
            width: "63%",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            display: "flex",
            alignItems: "center",
            textAign: "center",
            color: "#585C7B",
            marginBottom: "4%",
          }}
        >
          {lbl}
        </label>
        {isConfirmBtn && (
          <Button style={{ width: "362px" }} onClick={handleUpdateAccount}>
            Take a Test
          </Button>
        )}
      </div>
    );
  };

  return createModal({
    Content: <Content />,
    modalConfig: { size: ModalSize?.md },
    onHandleClose,
    show: show ? true : false,
  });
}

export default ApprovedPendingModal;
