import "./experience.scss";

import { Dropdown } from "react-bootstrap";
import React from "react";

function Experience({ name, handleChange, experience }) {
  name = "How Many years of Experience do you have? *";
  return (
    <div className="experience">
      <label>{name}</label>
      <div>
        <Dropdown className="drop">
          <Dropdown.Toggle>{experience}</Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              value="1"
              onClick={(value) => {
                handleChange({
                  name: "experience",
                  value: value?.currentTarget.attributes?.value?.value,
                });
                // setState(value.currentTarget.attributes?.value?.value);
              }}
            >
              1
            </Dropdown.Item>
            <Dropdown.Item
              value="2"
              onClick={(value) => {
                handleChange({
                  name: "experience",
                  value: value?.currentTarget.attributes?.value?.value,
                });
              }}
            >
              2
            </Dropdown.Item>
            <Dropdown.Item
              // href="#/action-1"
              value="3"
              onClick={(value) => {
                handleChange({
                  name: "experience",
                  value: value?.currentTarget.attributes?.value?.value,
                });
                // setState(value.currentTarget.attributes?.value?.value);
              }}
            >
              3
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

export default Experience;
