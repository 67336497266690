import React, { useState } from "react";


import Spinner from "react-bootstrap/Spinner";
import Header from "../../Header/Header";
import {postApiForUnrealEngine} from "../../../utils/ApiMethods";
import {toast} from "react-toastify";
import {SET_UNREAL} from "../../../Redux/Actions/types";
import {getApi}  from "../../../utils/ApiMethods";
import {useDispatch} from "react-redux";
import {BsPrefixProps, BsPrefixRefForwardingComponent} from "react-bootstrap/helpers";
import {Variant} from "react-bootstrap/types";
//const readline = require('readline');
import { useEffect, useRef } from 'react';



const IFrame = ({
                    url = "https://kishlaya.rstk.in:8443",
                    onLoad = () => {},
                }) => {
    const [loader, setLoader] = useState(true);
    const [logger, setLogger] = useState(true);
    const invokeLogs = async () => {
        try {
            const response = await getApi("instance/getInstanceLog");
            if (response.status == 200) {
                setLogger(response.data)
            } else {
                toast.error("Error while loading the logs");
            }
        } catch (error) {
            console.log(error)
        }
    };
    invokeLogs();
    let LoggerLog = []
    function createSystemLogs(logger) {
        if ((typeof logger) != "boolean") {
            function generatelog(loggerArray) {
                for (let i = 0; i < loggerArray.length; i++) {
                    loggerArray[i] = "<tr>" + loggerArray[i].charAt(0).toUpperCase() + loggerArray[i].slice(1) + "</tr>";
                }
                return loggerArray;
            }
            let loggerArray = logger.split("\n");
            return LoggerLog = loggerArray
        }
    }
    createSystemLogs(logger, LoggerLog);
    const messagesEndRef = useRef(null)
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }
    useEffect(() => {
        scrollToBottom()
    }, [LoggerLog]);
    return (
        <div className="embed-responsive embed-responsive-16by9 w-100 h-100 d-flex justify-content-center align-items-center">
            <div id="logPagecontainer" style={{display: 'block', float: 'center',position:'fixed', width: '100%', height: '100%' }}>
                <Header/>
                <h3>Please wait for sometime, system is starting</h3>
                <div id="logPage" style={{display: 'block', float: 'center', paddingTop: "1%", paddingLeft: "1%", backgroundColor : "rgb(255 255 255)", color : "black",  height : "70vh"
                    , overflow: "hidden"}}>
                    {LoggerLog.map(LoggerLog => <div>{LoggerLog}</div>)}
                    <div>Information 12:41 Unreal system booting up This can take upto 5 minutes, please wait...</div>
                    <div ref={messagesEndRef} />
                </div>
            </div>
            <iframe
                onLoad={() => {
                    onLoad(false);
                    setLoader(false);
                    if (document.getElementById("screenIframe").src.endsWith("visceral.io:8443/")) {
                        document.getElementById("logPagecontainer").style.display = 'none';
                        document.getElementById("screenIframe").style.display = 'block';
                    }
                    console.log(document.getElementById("screenIframe").src)
                }}
                style={{ height: "100%" , display: 'none'}}
                title="Embeds Page"
                id='screenIframe'
                className="embed-responsive-item"
                src={url}
                allowFullScreen
            ></iframe>

        </div>
    );
};

export default IFrame;
