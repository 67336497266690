import React from "react";

function WhatyouGet(props) {
  const data = [
    {
      iconName: "video_wyg",
      title: "Placement with the best",
      description:
        "Our clients include the world’s top gaming, media and entertainment, architecture, automotive and general simulation firms. You will get the chance to work with multiple companies and work with the best in the business, from the comfort of your home, where ever that may be.",
    },
    {
      iconName: "user_wyg",
      title: "Get paid according to global standards",
      description:
        "Our tests are built to find world class talent, regardless of where you are. This means, you get paid according to global standards for your skills.",
    },
    {
      iconName: "key_wyg",
      title: "Freedom!",
      description:
        "Visceralites work and collaborate remotely. We give you access to world class hardware and communication software in the cloud. It doesn’t matter if you work from a mountain or a beach. You are connected to your global peers while getting paid to dollar!",
    },
  ];
  return (
    <div className="wyg">
      <h1> What you Get</h1>
      <div className="wyg-body">
        {data.map(({ iconName, title, description }) => (
          <div>
            <i className={iconName}></i>
            <title>{title}</title>
            <label>{description}</label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhatyouGet;
