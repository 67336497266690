import React from 'react';
import IconOne from '../../Assets/img/landing/online-courses/icons/01.svg';
import IconTwo from '../../Assets/img/landing/online-courses/icons/02.svg';
import IconThree from '../../Assets/img/landing/online-courses/icons/03.svg'; 

export default function CandidateLandingFour() {
  return (
    <>
    <section className="container pt-1 pt-xl-3 pb-5 ">
        <h2 className="h1 text-center pb-3 pb-md-0 mb-md-5 ">What You Get</h2>
        <div
            className="swiper swiper-container swiper-nav-onhover mt-n3 mx-n2"
            data-swiper-options='{
            "slidesPerView": 1,
            "spaceBetween": 8,
            "pagination": {
            "el": ".swiper-pagination",
            "clickable": true
            },
            "breakpoints": {
            "600": {
                "slidesPerView": 2
            },
            "1000": {
                "slidesPerView": 3
            }
            }
        }'
        >
            <div className="swiper-wrapper ">
            {/* Item */}
            <div className="swiper-slide h-auto py-3">
                <div className="card card-hover h-100 mx-2">
                <div className="card-body">
                    <div className="d-table position-relative p-3 mb-4">
                    <img
                        src={IconOne}
                        className="position-relative zindex-2"
                        width={32}
                        alt="Icon"
                    />
                    <span className="bg-primary position-absolute top-0 start-0 w-100 h-100 rounded-circle opacity-8" />
                    </div>
                    <h3 className="h5 pb-1 mb-2">Placements with the best</h3>
                    <p className="mb-0">
                    Our clients include the world’s top gaming, media and entertainment, architecture, automotive and general simulation firms. You will get the chance to work with multiple companies and work with the best in the business, from the comfort of your home, where ever that may be.
                    </p>
                </div>
                </div>
            </div>
            {/* Item */}
            <div className="swiper-slide h-auto py-3">
                <div className="card card-hover h-100 mx-2">
                <div className="card-body">
                    <div className="d-table position-relative p-3 mb-4">
                    <img
                        src={IconTwo}
                        className="position-relative zindex-2"
                        width={32}
                        alt="Icon"
                    />
                    <span className="bg-primary position-absolute top-0 start-0 w-100 h-100 rounded-circle opacity-8" />
                    </div>
                    <h3 className="h5 pb-1 mb-2">Get paid according to global standards</h3>
                    <p className="mb-0">
                    Our tests are built to find world class talent, regardless of where you are. This means, you get paid according to global standards for your skills.
                    </p>
                </div>
                </div>
            </div>
            {/* Item */}
            <div className="swiper-slide h-auto py-3">
                <div className="card card-hover h-100 mx-2">
                <div className="card-body">
                    <div className="d-table position-relative p-3 mb-4">
                    <img
                        src={IconThree}
                        className="position-relative zindex-2"
                        width={32}
                        alt="Icon"
                    />
                    <span className="bg-primary position-absolute top-0 start-0 w-100 h-100 rounded-circle opacity-8" />
                    </div>
                    <h3 className="h5 pb-1 mb-2">Freedom!</h3>
                    <p className="mb-0">
                    Visceralites work and collaborate remotely. We give you access to world class hardware and communication software in the cloud. It doesn’t matter if you work from a mountain or a beach. You are connected to your global peers while getting paid too dollar!
                    </p>
                </div>
                </div>
            </div>
            </div>
            {/* Pagination (bullets) */}

            <div className="swiper-pagination position-relative pt-2 pt-sm-3 mt-4" />
        </div>
    </section>

    </>
  )
}
