import "./mainmenuwrapper.scss";

import React from "react";
import SidebarEmployer from "../AccountSidebar/SidebarEmployer";
import SidebarNew from "../AccountSidebar/SidebarNew";

function MainMenuWrapper(props) {
  return (
    <div className="menu w-100">
      <div className="menulist p-0">
        {props?.employer ? (
          <SidebarEmployer currentTab={props?.currentTab} />
        ) : (
          <SidebarNew currentTab={props?.currentTab} />
        )}
      </div>
      <div className="maincontent">{props.children}</div>
    </div>
  );
}

export default MainMenuWrapper;
