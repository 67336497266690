import "./experienceRange.scss";

import Range from "../../../Components/common/range/Range";
import React from "react";

function ExperienceRange({ handleChange, daysforWorking }) {
  return (
    <div className="experience-range">
      <label>{"How soon you can start working ? *"}</label>
      <Range
        value={daysforWorking}
        onChange={(value) => handleChange({ name: "daysforWorking", value })}
        width="25%"
      />
    </div>
  );
}

export default ExperienceRange;
