import React, { useEffect, useState } from "react";
import {
  experienceOption,
  skillsCandidateOptions,
  specialityOptions,
  timeZonesOption,
} from "../../../utils/jsonData";
import { useNavigate, useSearchParams } from "react-router-dom";

import EmployerCard from "./EmployerCard";
import { Loader } from "../../../Components/Loader";
import Select from "react-select";
import { postApi } from "../../../utils/ApiMethods";
import { useSelector } from "react-redux";

function EmployerCandidateList() {
  const [approvedUserList, setApprovedUserList] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(false);
  const [searchParamsQuery, setSearchParamsQuery] = useState({
    skills: "",
    speciality: "",
    experienceFrom: "",
    experienceTo: "",
    timeZone: "",
  });
  const [searchParams] = useSearchParams();
  const { id } = useSelector((state) => state?.employer?.employerDetails);
  let navigate = useNavigate();

  useEffect(() => {
    setSearchParamsQuery({
      ...searchParamsQuery,
      skills:
        searchParams.get("skills") === null ? "" : searchParams.get("skills"),
      speciality: searchParams.get("speciality"),
      experienceFrom: searchParams.get("experienceFrom"),
      experienceTo: searchParams.get("experienceTo"),
      timeZone: searchParams.get("timeZone"),
    });
    setIsMounted(true);
  }, []);
  function debounce(func, timeout = 900) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
  const fetchAllApprovedCandidates = async () => {
    try {
      setLoading(true);

      const response = await postApi(
        `accountDetails/getAllUsersDetailsApproved?skills=${searchParamsQuery.skills}&speciality=${searchParamsQuery.speciality}&experienceTo=${searchParamsQuery.experienceTo}&experienceFrom=${searchParamsQuery.experienceFrom}&timeZone=` +
          encodeURIComponent(searchParamsQuery.timeZone),
        { employerId: id }
      );

      const data = response.data;
      setApprovedUserList(data.data);

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const fetchUsers = debounce(() => fetchAllApprovedCandidates());
  useEffect(() => {
    if (isMounted) {
      navigate(
        `/employer/dashboard/candidate?skills=${searchParamsQuery.skills}&speciality=${searchParamsQuery.speciality}&experienceTo=${searchParamsQuery.experienceTo}&experienceFrom=${searchParamsQuery.experienceFrom}&timeZone=` +
          encodeURIComponent(searchParamsQuery.timeZone)
      );
      fetchUsers();
    }
  }, [searchParamsQuery]);

  const handleChange = (e, name) => {
    if (name === "skills") {
      setSearchParamsQuery({
        ...searchParamsQuery,
        skills: e.map((el) => el.value),
      });
    } else if (name == "experience") {
      if (e !== "10+ yrs") {
        setSearchParamsQuery({
          ...searchParamsQuery,
          experienceFrom: e?.split(" - ")[0] || "",
          experienceTo: e?.split(" - ")[1]?.split(" ")[0] || "",
        });
      } else {
        setSearchParamsQuery({
          ...searchParamsQuery,
          experienceFrom: "10",
          experienceTo: "50",
        });
      }
    } else {
      setSearchParamsQuery({
        ...searchParamsQuery,
        [name]: e || "",
      });
    }
  };
  return (
    <>
      <section className="container">
        <div className="row pb-5 mb-lg-2 pt-5 mt-n3 mt-md-0">
          <div className="col-sm-12 col-6">
            <h1 className="h2 mb-4">Candidate's List</h1>
          </div>
          <div className="col-lg-4 col-sm-5">
            <div className="pt-xl-1 mb-3 pb-3">
              <h1 className="fs-5">Filter by</h1>

              <Select
                className="w-100 my-2"
                placeholder={<div>Skills</div>}
                name="skills"
                value={
                  searchParamsQuery &&
                  searchParamsQuery.skills &&
                  searchParamsQuery.skills.length
                    ? typeof searchParamsQuery.skills != "string"
                      ? searchParamsQuery.skills.map((el) => {
                          return { label: el, value: el };
                        })
                      : searchParamsQuery.skills.split(",").map((el) => {
                          return { label: el, value: el };
                        })
                    : []
                }
                options={skillsCandidateOptions}
                isClearable={true}
                onChange={(value) => handleChange(value, "skills")}
                isMulti
              />

              <Select
                className="w-100 my-2"
                placeholder={<div>Experience</div>}
                name="experience"
                options={experienceOption}
                isClearable={true}
                value={
                  searchParamsQuery &&
                  searchParamsQuery.experienceFrom &&
                  searchParamsQuery.experienceFrom.length
                    ? {
                        label:
                          searchParamsQuery.experienceFrom +
                          (searchParamsQuery.experienceTo == 50
                            ? "+ yrs"
                            : " - " + searchParamsQuery.experienceTo + " yrs"),
                        value:
                          searchParamsQuery.experienceFrom +
                          " - " +
                          searchParamsQuery.experienceTo +
                          " yrs",
                      }
                    : []
                }
                onChange={(value) => handleChange(value?.value, "experience")}
              />

              <Select
                placeholder={<div>Speciality</div>}
                name="speciality"
                options={specialityOptions}
                isClearable={true}
                value={
                  searchParamsQuery.speciality && {
                    label: searchParamsQuery.speciality,
                    value: searchParamsQuery.speciality,
                  }
                }
                onChange={(value) => handleChange(value?.value, "speciality")}
              />
              <Select
                className="w-100 my-2"
                placeholder={<div>Time Zone</div>}
                name="timeZone"
                options={timeZonesOption}
                isClearable={true}
                value={
                  searchParamsQuery.timeZone && {
                    label: searchParamsQuery.timeZone,
                    value: searchParamsQuery.timeZone,
                  }
                }
                onChange={(value) => handleChange(value?.value, "timeZone")}
              />
            </div>
          </div>
          <div className="col-lg-8 col-sm-7">
            <div className="ps-md-4 mt-md-1 pb-md-2">
              {isLoading ? (
                <Loader />
              ) : (
                approvedUserList.map((item, index) => {
                  return (
                    <EmployerCard
                      item={item}
                      index={index}
                      key={item + index}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EmployerCandidateList;
