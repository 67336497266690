import * as Yup from "yup";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import AccountImage from "../../Assets/img/account/signin-bg.jpg";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import LinkedInPage from "./LinkedInPage";
import { Loader } from "../../Components/Loader";
import OTPModal from "./OTPModal";
import { postApiWithoutToken } from "../../utils/ApiMethods";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useSelector } from "react-redux";

// import OTP from './OTP'

export default function SignIn() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state?.auth);
  let [searchParams, setSearchParams] = useSearchParams();
  const emailFromParam = searchParams?.get("email");
  let navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/test");
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid Email address").required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setEmail(formik.values.email);
      const signIn = await handleSignUp();
      if (signIn) {
        resetForm({ values: "" });
      }
    },
  });
  console.log("function=", formik.handleChange);

  const handleSignUp = async () => {
    let result = false;

    try {
      setLoading(true);

      const response = await postApiWithoutToken(`users/UserLogin`, {
        email: formik?.values?.email,
      });

      const data = response.data;

      if (data.status) {
        result = true;
        setIsOpen(true);
      } else {
        toast.error(data.message);
        navigate("/sign-up");
        setLoading(true);
        toast.info("Redirecting to Sign up Page")
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error("Something went wrong");
        }
    setLoading(false);
    return result;
  };

  const handleLinkedInLogin = () => {
    // let w=500
    // let h=700
    // var left = (window.screen.width/2)-(w/2);
    // var top = (window.screen.height/2)-(h/2);
    // window.open(`${process.env.REACT_APP_BaseUrl}auth/linkedin`,'','width='+w+',height='+h+',left='+left+',top='+top);
    navigate(`/auth/linkedin`);
  };
  useEffect(() => {
    if (emailFromParam) {
      formik.setFieldValue("email", emailFromParam);
    }
  }, [emailFromParam]);

  return (
    <>
      <Header />
      <main className="page-wrapper">
        <section className="position-relative h-100 pt-5 pb-5">
          <div className="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100 pt-5">
            <div
              className="w-100 align-self-end pt-1 pt-md-4 pb-4"
              style={{ maxWidth: 526 }}
            >
              <h1 className="text-center text-xl-start">Welcome Back</h1>
              <p className="text-center text-xl-start pb-3 mb-3">
                Don’t have an account yet?{" "}
                <Link to="/sign-up">Register here.</Link>
              </p>
              <form
                className="needs-validation mb-2"
                noValidate=""
                onSubmit={formik.handleSubmit}
              >
                <div className="position-relative mb-4">
                  <label htmlFor="email" className="form-label fs-base">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className={`form-control form-control-lg ${
                      formik.errors.email ? "border-danger" : ""
                    }`}
                    required=""
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  <div className="invalid-feedback position-absolute start-0 top-100 d-block ">
                    {/* Please enter a valid email address! */}
                    {formik.errors.email}
                  </div>
                </div>
                {/* <div className="mb-4">
                  <label htmlFor="password" className="form-label fs-base">
                  Password
                  </label>
                  <div className="password-toggle">
                  <input
                      type="password"
                      id="password"
                      className="form-control form-control-lg"
                      required=""
                  />
                  <label
                      className="password-toggle-btn"
                      aria-label="Show/hide password"
                  >
                      <input className="password-toggle-check" type="checkbox" autocomplete="off" />
                      <span className="password-toggle-indicator" />
                  </label>
                  <div className="invalid-feedback position-absolute start-0 top-100">
                      Please enter your password!
                  </div>
                  </div>
              </div>
              <div className="mb-4">
                  <div className="form-check">
                  <input type="checkbox" id="remember" className="form-check-input" autocomplete="off"/>
                  <label htmlFor="remember" className="form-check-label fs-base">
                      Remember me
                  </label>
                  </div>
              </div> */}

                {/* <button
                  type="Submit"
                  className="btn btn-primary shadow-primary btn-lg w-100 mb-4"  
                //   data-bs-toggle="modal" data-bs-target="#modalId"
                  
              >
                  Sign in
              </button> */}
                <div>
                  <div className="d-grid">
                    {loading ? (
                      <button
                        disabled={true}
                        className="btn btn-primary shadow-primary btn-lg w-100"
                      >
                        <Loader style={{ width: "1rem", height: "1rem" }} />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary shadow-primary btn-lg w-100"
                      >
                        Sign in
                      </button>
                    )}
                  </div>
                </div>
              </form>
              <hr className="my-4" />
              <h6 className="text-center mb-4">
                Or sign in with your social network
              </h6>
              <div className="row">
                <div className="col mb-3">
                  <div
                    className=" w-100 text-primary"
                    // onClick={handleLinkedInLogin}
                  >
                    <LinkedInPage />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="position-absolute top-0 end-0 w-50 h-100 bg-position-center bg-repeat-0 bg-size-cover d-none d-xl-block"
            style={{ backgroundImage: `url(${AccountImage})` }}
          />
        </section>
      </main>

      <Footer />
      {/* <OTP email={email}/> */}
      <OTPModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} email={email} />
    </>
  );
}
