import "./skilledlist.scss";

import Chip from "../../../Components/common/chip/Chip";
import React from "react";

function SkilledList({
  name = "What are you most skilled in? *",
  list1 = [],
  skills = [],
  handleChange,
}) {
  list1 = [
    { title: "Games" },
    { title: "Architecture" },
    { title: "Film & television" },
    { title: "Broadcast & live events" },
    { title: "Animation" },
    { title: "Automotive & transportation" },
    { title: "Simulation" },
    { title: "Virtual production" },
    { title: "Extended reality (XR)" },
  ];
  const onChange = ({ name }) => {
    let t = new Set(skills);
    if (t.has(name)) {
      t.delete(name);
    } else {
      t.add(name);
    }
    handleChange({ name: "skills", value: Array.from(t) });
  };

  return (
    <div className="skilledList">
      <label>{name}</label>
      <div>
        {list1.map(({ title }, index) => {
          return (
            <Chip
              isActive={skills?.includes(title)}
              title={title}
              index={index}
              handleChange={() => onChange({ index, name: title })}
            />
          );
        })}
      </div>
    </div>
  );
}

export default SkilledList;
