import CustomCard from "../../../Components/common/card/Card";
import React from "react";

function AvailabilityList({ handleChange, availability }) {
  const list = [
    {
      extendedClass: "workbag_svg_green",
      title: "Available for work",
      description:
        "I am actively looking  for New Remote Jobs  and Available  For Interview In the next 30 days",
    },
    {
      extendedClass: "workbag_svg_orange",
      title: "Already working. Looking for a switch",
      description:
        "I am Not actively looking  for New Remote Jobs But I am Available to hear new offers the next 30 days",
    },
    // {
    //   extendedClass: "workbag_svg_red",
    //   title: "Unavailable for the Jobs",
    //   description:
    //     "I am actively looking  for New Remote Jobs  and Available  For Interview In the next 30 days",
    // },
  ];

  return (
    <div className="availabilitylist d-flex flex-wrap flex-start mt-2">
      {list?.map(({ title, description, extendedClass }) => {
        return (
          <CustomCard
            isActive={
              title?.toLowerCase() === availability?.title?.toLowerCase()
            }
            onClick={() => {
              handleChange({
                name: "availability",
                value: { title, description },
              });
            }}
            extendedClass={extendedClass}
            title={title}
            description={description}
          />
        );
      })}
    </div>
  );
}

export default AvailabilityList;
