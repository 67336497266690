import "./freeLanceExp.scss";

import { Form } from "react-bootstrap";
import React from "react";

function FreeLanceExp({ handleChange, type = "radio", isPriorexp }) {
  return (
    <div className="freeLanceExp">
      <label>Do You Have Prior Freelance Experience *</label>
      <div key={`inline-${type}`} className="mb-3 radioCheck">
        <Form.Check
          checked={isPriorexp}
          onClick={() => {
            handleChange({ name: "isPriorexp", value: true });
          }}
          inline
          label="Yes"
          name="group1"
          type={type}
          id={`inline-${type}-1`}
        />
        <Form.Check
          onClick={() => {
            handleChange({ name: "isPriorexp", value: false });
          }}
          checked={!isPriorexp}
          inline
          label="No"
          name="group1"
          type={type}
          id={`inline-${type}-2`}
        />
      </div>
    </div>
  );
}

export default FreeLanceExp;
