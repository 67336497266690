import "react-date-picker/dist/DatePicker.css";
import "./tellusmore.scss";

import { useDispatch, useSelector } from "react-redux";

import { Button } from "react-bootstrap";
import Chip from "../../../Components/common/chip/Chip";
import CustomCard from "../../../Components/common/card/CustomCard";
import DatePicker from "react-date-picker";
import React from "react";
import { SET_EMPLOYER_TELL_US_MORE } from "../../../Redux/Actions/types";
import bodyTemp from "../commomComponent/BodyTemplate";
import headerTemp from "../commomComponent/HeaderTemplate";
import { useNavigate } from "react-router-dom";

function TellUsMore(props) {
  const dispatch = useDispatch();
  const { aboutProject, activeIndex, start, noOfPeople } = useSelector(
    (state) => state?.employer?.tellusmore
  );
  const handleChange = (e, index) => {
    dispatch({
      type: SET_EMPLOYER_TELL_US_MORE,
      payload: { activeIndex: index },
    });
  };

  if (activeIndex > -1) {
    noOfPeople[activeIndex].isActive = true;
  }
  let isDisabled =
    !aboutProject || !start || !noOfPeople?.some((d) => d?.isActive);
  const handleContactClick = async (values) => {
    navigate("/employer/hiring");
  };
  const navigate = useNavigate();
  const onChange = (e) => {
    dispatch({
      type: SET_EMPLOYER_TELL_US_MORE,
      payload: { [e?.currentTarget?.name]: e?.currentTarget?.value },
    });
  };
  const content = (
    <form className="needs-validation" noValidate="">
      <div className="row">
        <div className="col-12">
          <div className="position-relative mb-4">
            <label htmlFor="name" className="form-label fs-base">
              What do you want to build
            </label>
            <input
              type="text"
              id="aboutProject"
              name="aboutProject"
              className={`form-control form-control-lg $`}
              required=""
              onChange={onChange}
              value={aboutProject}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="position-relative mb-4 d-flex flex-column">
            <label htmlFor="name" className="form-label fs-base">
              How many people your are looking for?
            </label>
            <div className="d-flex">
              {noOfPeople?.map(({ title, isActive }, internalIdex) => {
                return (
                  <Chip
                    title={title}
                    isActive={isActive}
                    handleChange={(e) => handleChange(e, internalIdex)}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="position-relative mb-4 d-flex flex-column">
            <label htmlFor="name" className="form-label fs-base">
              When do you want your team to start?(in weeks)
            </label>
            <DatePicker
              dayPlaceholder=""
              monthPlaceholder=""
              yearPlaceholder=""
              calendarClassName="mmCalneder"
              onChange={(value) => {
                const e = { currentTarget: { value, name: "start" } };
                onChange(e);
              }}
              name="start"
              value={start}
              className="mycalender"
              format="dd-MM-yyyy"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <Button
            className="w-75"
            onClick={handleContactClick}
            disabled={isDisabled}
          >
            Continue
          </Button>
        </div>
      </div>
    </form>
  );
  const body = bodyTemp({
    content: content,
    title:
      "The more project details you can provide,the better we can support you",
  });
  const header = headerTemp({
    naviagteTo: "/employer/contact",
    title: "Tell us more about your Project",
  });

  return <CustomCard CardBody={body} Header={header} />;
}

export default TellUsMore;
