import { Navigate, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Helmet from "react-helmet";
import LandingSectionEight from "./LandingSectionEight";
import LandingSectionFive from "./LandingSectionFive";
import LandingSectionFour from "./LandingSectionFour";
import LandingSectionNine from "./LandingSectionNine";
import LandingSectionOne from "./LandingSectionOne";
import LandingSectionSeven from "./LandingSectionSeven";
import LandingSectionSix from "./LandingSectionSix";
import LandingSectionThree from "./LandingSectionThree";
import LandingSectionTwo from "./LandingSectionTwo";
import UserContext from "../../context/user/UserContext";

const HomePage = () => {
  const context = useContext(UserContext);
  const { isLoggedIn } = context;
  let navigate = useNavigate();
  const [jsLoad, setJsLoad] = useState(false);
  // -----------------------------------------------------   checking if user  is logged in     ----------------------------------------------------
  // useEffect(() => {
  //   if (isLoggedIn) {
  //     navigate("/test");
  //   }
  // }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setJsLoad(true);
  }, []);

  return (
    <>
      <main className="page-wrapper">
        {jsLoad && (
          <Helmet>
            <script>initializeJSRoot()</script>
          </Helmet>
        )}
        {/* Hero Section One */}
        <LandingSectionOne />

        {/* Benefits Section Two */}
        <LandingSectionTwo />

        {/* Featured Section Three */}
        {/* <LandingSectionThree /> */}

        {/* Service Section Four */}
        {/* <LandingSectionFour /> */}

        {/* Brands (Carousel) */}
        {/* <LandingSectionFive /> */}

        {/* Testimonials slider */}
        {/* <LandingSectionSix /> */}

        {/* Our Creative Atmosphere */}
        {/* <LandingSectionSeven /> */}

        {/* Awards */}
        {/* <LandingSectionEight /> */}

        {/* Blog + Contact cta */}
        {/* <LandingSectionNine /> */}
      </main>
      <Footer />
    </>
  );
};

export default HomePage;
