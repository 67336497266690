import { SET_MODAL, SET_TEST_DETAILS } from "../../Redux/Actions/types";
import { useDispatch, useSelector } from "react-redux";

import { Helmet } from "react-helmet";
import { Loader } from "../../Components/Loader";
import React from "react";
import SingleImage from "../../Assets/img/portfolio/courses/single.jpg";
import { encodeURI2 } from "../../utils/helperFunctions";
import { getApi } from "../../utils/ApiMethods";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function TestDescription({ id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [btnText, setBtnText] = useState("Attempt Test");
  const testDetails = useSelector((state) => state?.tests?.testDetails);

  const getTestDetails = async () => {
    try {
      setLoading(true);
      const response = await getApi(`testDetail/getTestDetailsById/${id}`);
      const data = response.data;
      if (data.status) {
        dispatch({ type: SET_TEST_DETAILS, payload: data?.data?.data });
        if (data.data.isApplied) {
          setBtnText("Applied");
        }
      } else {
        toast.error("Something went wrong");
      }
      setLoading(false);
    } catch (err) {
      toast.error(err.response.data.message);
      if (err.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("ltoken");

        navigate("/sign-up");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getTestDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const apply = () => {
    dispatch({
      type: SET_MODAL,
      payload: { unrealmodal: true },
    });
  };
  return (
    <>
      <Helmet>
        <script>initializeJSRoot()</script>
      </Helmet>
      <section className="container">
        <div className="row">
          {/* Account collections */}
          <div className="col-md-8 offset-lg-1 pb-5 mb-lg-2 pt-md-2 mt-n3 mt-md-0">
            {loading ? (
              <Loader />
            ) : (
              <div className="ps-md-3 ps-lg-0 mt-md-2 pt-md-4 pb-md-2">
                <div
                  className="jarallax dark-mode bg-dark pt-2 pt-lg-3 pb-lg-5"
                  data-jarallax=""
                  data-speed="0.4"
                >
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-70" />
                  {/* {
                          testDetails.bannerImage?
                          <div
                          className="jarallax-img"
                          style={{ backgroundImage: 'url('+encodeURI(process.env.REACT_APP_BaseUrl+testDetails.bannerImage)+')'}}
                      />:
                      <div
                          className="jarallax-img"
                          style={{ backgroundImage: `url(${SingleImage})` }}
                      />
                  } */}
                  <div
                    className="jarallax-img"
                    style={{
                      backgroundImage: `${
                        testDetails.bannerImage
                          ? "url(" + encodeURI2(testDetails.bannerImage) + ")"
                          : `url(${SingleImage})`
                      }`,
                    }}
                  />

                  {/* {console.log("image url",process.env.REACT_APP_BaseUrl+testDetails.bannerImage)} */}
                  <div className="container position-relative zindex-5">
                    {/* Badges */}
                    <div className="d-flex pt-3 pb-4 py-sm-4 pt-lg-5">
                      {/* <span className="badge bg-success fs-sm me-2">
                  Completed
                </span> */}
                      <a
                        href="#"
                        className="badge bg-white text-nav fs-sm text-decoration-none"
                      >
                        Grab the opportunity
                      </a>
                    </div>
                    {/* Title */}
                    <h1>{testDetails.title}</h1>
                    <p className="fs-lg text-light opacity-70">
                      {testDetails.summary}
                    </p>
                    {/* Stats */}
                    <div className="d-sm-flex py-3 py-md-4 py-xl-5">
                      <div className="we-75 d-flex border-sm-end pe-sm-3 me-sm-3 mb-2 mb-sm-0">
                        <span className="text-light fw-bold">
                          No. of developers who have already attempted the test
                          - 1K
                        </span>
                      </div>
                      {testDetails.duration !== 0 && (
                        <div className="d-flex align-items-center">
                          <i className="bx bx-time fs-xl text-light opacity-70 me-1" />
                          <span className="text-light fw-bold">
                            {testDetails.duration} mins
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="container pt-5 mt-2 mt-lg-4 mt-xl-5">
                  <div className="row">
                    {(testDetails.isDuration ||
                      testDetails.isSupport ||
                      testDetails.isInstructions ||
                      testDetails.isCertificateOnCompleteion ||
                      testDetails.isConvenienceAtYourEnd) && (
                      <aside className="col-lg-12 mb-5">
                        <div style={{ marginTop: "-96px" }} />
                        <div className="position-sticky top-0 pt-5">
                          <div className="mt-md-3">
                            {/* <div className="card shadow-sm p-sm-3">
                              <div className="card-body">
                                <h4 className="mb-4">This test includes:</h4>
                                <ul className="list-unstyled pb-3">
                                  {testDetails.isDuration && (
                                    <li className="d-flex align-items-center mb-2">
                                      <i className="bx bx-slideshow fs-xl text-muted me-2 pe-1" />
                                      Time duration
                                    </li>
                                  )}
                                  {testDetails.isInstructions && (
                                    <li className="d-flex align-items-center mb-2">
                                      <i className="bx bx-file fs-xl text-muted me-2 pe-1" />
                                      Set of instructions
                                    </li>
                                  )}
                                  {testDetails.isConvenienceAtYourEnd && (
                                    <li className="d-flex align-items-center mb-2">
                                      <i className="bx bx-infinite fs-xl text-muted me-2 pe-1" />
                                      Convenience at your end
                                    </li>
                                  )}
                                  {testDetails.isCertificateOnCompleteion && (
                                    <li className="d-flex align-items-center mb-2">
                                      <i className="bx bx-slideshow fs-xl text-muted me-2 pe-1" />
                                      Certification of Completion
                                    </li>
                                  )}
                                  {testDetails.isSupport && (
                                    <li className="d-flex align-items-center mb-2">
                                      <i className="bx bx-headphone fs-xl text-muted me-2 pe-1" />
                                      Support
                                    </li>
                                  )}
                                </ul>
                                <div
                                  onClick={btnText !== "Applied" ? apply : null}
                                  className="btn btn-primary btn-lg shadow-primary"
                                >
                                 
                                  {btnText}
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </aside>
                    )}
                    {/* Content */}
                    <div className="col-lg-12 mb-5">
                      {testDetails.description ? (
                        <div>
                          <h2 className="h1 pb-md-2 pb-lg-3">
                            Test description
                          </h2>
                          <div className="pb-4 mb-3">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: testDetails.description,
                              }}
                            />
                          </div>
                        </div>
                      ) : null}

                      <h3 className="mb-4">What you'll learn</h3>
                      <ul className="list-unstyled mb-5">
                        <li className="d-flex align-items-center mb-2">
                          <i className="bx bx-check-circle text-primary fs-xl me-2" />
                          Get hands-on experience how to complete a test within
                          a specified period of time.
                        </li>
                        <li className="d-flex align-items-center mb-2">
                          <i className="bx bx-check-circle text-primary fs-xl me-2" />
                          To know the latest technology.
                        </li>
                        <li className="d-flex align-items-center mb-2">
                          <i className="bx bx-check-circle text-primary fs-xl me-2" />
                          Get your knowledge tested.
                        </li>
                        <li className="d-flex align-items-center mb-2">
                          <i className="bx bx-check-circle text-primary fs-xl me-2" />
                          To know the complexity of programming.
                        </li>
                        <li className="d-flex align-items-center mb-2">
                          <i className="bx bx-check-circle text-primary fs-xl me-2" />
                          To know more about the competitors in the market.
                        </li>
                      </ul>

                      <h2 className="h1 pt-md-2 pt-lg-4 pt-xl-5 pb-md-3 pb-lg-4 mb-md-4">
                        Test details
                      </h2>

                      <div className="step step-sm d-flex flex-column align-items-start">
                        {testDetails?.testDetails?.map((item, index) => {
                          return (
                            <div className="step" key={index}>
                              <div className="step-number">
                                <div className="step-number-inner">
                                  {index + 1}
                                </div>
                              </div>
                              <div className="step-body">
                                <h5 className="mb-2">{item.title}</h5>
                                <p className="mb-0">{item.description}</p>
                              </div>
                            </div>
                          );
                        })}
                        {/*<div className="step">
                              <div className="step-number">
                                  <div className="step-number-inner">1</div>
                              </div>
                              <div className="step-body">
                                  <h5 className="mb-2">This test consists of four parts such as Optimization , Word Build , Shaders and Movies.</h5>
                                  <p className="mb-0">
                                  Nulla faucibus mauris pellentesque blandit faucibus non. Sit ut et
                                  at suspendisse gravida hendrerit tempus placerat.
                                  </p>
                              </div>
                              </div>
                               <div className="step">
                              <div className="step-number">
                                  <div className="step-number-inner">2</div>
                              </div>
                              <div className="step-body">
                                  <h5 className="mb-2">Choose your test which suits you the best as per your profession and experience.</h5>
                                  <p className="mb-0">
                                  Lobortis diam elit id nibh ultrices sed penatibus donec. Nibh
                                  iaculis eu sit cras ultricies. Nam eu eget etiam egestas donec
                                  scelerisque ut ac enim. Vitae ac nisl, enim nec accumsan vitae
                                  est.
                                  </p>
                              </div>
                              </div>
                              <div className="step">
                              <div className="step-number">
                                  <div className="step-number-inner">3</div>
                              </div>
                              <div className="step-body">
                                  <h5 className="mb-2">Once you've chosen the test you can select the one and start your test meanwhile we will be sending you an instructions for test over your email address.</h5>
                                  <p className="mb-0">
                                  Duis euismod enim, facilisis risus tellus pharetra lectus diam
                                  neque. Nec ultrices mi faucibus est. Magna ullamcorper potenti
                                  elementum ultricies auctor.
                                  </p>
                              </div>
                              </div>
                              <div className="step">
                              <div className="step-number">
                                  <div className="step-number-inner">4</div>
                              </div>
                              <div className="step-body">
                                  <h4 className="mb-2">Attempt the test</h4>
                                  <p className="mb-0">
                                  Morbi porttitor risus imperdiet a, nisl mattis. Amet, faucibus
                                  eget in platea vitae, velit, erat eget velit. At lacus ut proin
                                  erat.
                                  </p>
                              </div>
                              </div>
                              <div className="step">
                              <div className="step-number">
                                  <div className="step-number-inner">5</div>
                              </div>
                              <div className="step-body">
                                  <h5 className="mb-2">Awaiting for the results ? Even we are also we will get back to you at the earliest</h5>
                                  <p className="mb-0">
                                  Risus morbi euismod in congue scelerisque fusce pellentesque diam
                                  consequat. Nisi mauris nibh sed est morbi amet arcu urna.
                                  Malesuada feugiat quisque consectetur elementum diam vitae.
                                  Dictumst facilisis odio eu quis maecenas risus odio fames bibendum
                                  ullamcorper.
                                  </p>
                              </div>
                              </div>  */}
                      </div>
                    </div>

                    {/* Button */}
                    <div className="col-lg-12 text-center">
                      {btnText !== "Applied" ? (
                        <div
                          className="btn btn-primary btn-lg shadow-primary"
                          onClick={() => apply(testDetails.title)}
                        >
                          {/* Attempt Test */}
                          Attempt Test
                        </div>
                      ) : (
                        <button className="btn btn-primary btn-lg shadow-primary">
                          {/* Attempt Test */}
                          Applied
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default TestDescription;
