import { useDispatch, useSelector } from "react-redux";

import Modal from "../../Components/common/modal/Modal";
import { RESET_MODAL } from "../../Redux/Actions/types";
import React from "react";

function MyModal(props) {
  const currentModal = "mymodal";
  const modal = useSelector((state) => state.modal);
  const show = modal[currentModal];
  const dispatch = useDispatch();
  const onHandleClose = () => {
    dispatch({ type: RESET_MODAL });
  };
  const title = "abcd";
  const { createModal, ModalSize } = Modal;

  return createModal({
    Content: <div>hey</div>,
    title,
    modalConfig: { size: ModalSize?.lg },
    SubmitComponent: <></>,
    onHandleClose,
    show: show ? true : false,
  });
}

export default MyModal;
