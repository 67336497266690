import "./card.scss";

import Card from "react-bootstrap/Card";
import src1 from "./prof.jpeg";

function CardComponent({
  src = src1,
  name,
  speciality,
  experience,
  isActive = false,
  technology,
  handleChange,
}) {
  return (
    <Card
      className={`candidate-card ${isActive && "active"}`}
      onClick={handleChange}
    >
      <Card.Img variant="top" src={src} />
      <Card.Body>
        <Card.Title>{name}</Card.Title>
        <Card.Text>
          <p className="card-text-p">
            <i className="bi bi-star"></i>
            <p>{speciality}</p>
          </p>
          <p className="card-text-p">
            <i className="bi bi-journal-text"></i>
            <p>{experience}</p>
          </p>
          <p className="card-text-p">
            <i className="bi bi-star"></i>
            <p>{technology}</p>
          </p>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
export default CardComponent;
