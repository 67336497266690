import {
  SET_AUTH,
  SET_EMPLOYER_SUITABLE_CANDIDATE,
} from "../../../Redux/Actions/types";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "react-bootstrap";
import CardComponent from "../commomComponent/Card";
import CustomCard from "../../../Components/common/card/CustomCard";
import { EMPLOYER } from "../../../constant";
import React from "react";
import bodyTemp from "../commomComponent/BodyTemplate";
import headerTemp from "../commomComponent/HeaderTemplate";
import { postApi } from "../../../utils/ApiMethods";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function SuitableCandidate(props) {
  const dispatch = useDispatch();
  const {
    contact: { email, fullName, contact },
    tellusmore: { aboutProject, activeIndex, start, noOfPeople },
    hiring: {
      company,
      email: workEmail,
      companyIndex,
      seedIndex,
      publicListedIndex,
      hiringNeed,
      companysize,
    },
    suitablecandidate: { candidate },
  } = useSelector((state) => state?.employer);

  const handleChange = (e, index) => {
    dispatch({
      type: SET_EMPLOYER_SUITABLE_CANDIDATE,
      payload: {
        candidate: candidate?.map((d, internalIndex) => {
          if (internalIndex === index) {
            d.isActive = !d?.isActive;
          }
          return d;
        }),
      },
    });
  };

  let isDisabled = !candidate?.some((d) => d?.isActive);
  const handleContactClick = async (values) => {
    const createCorporateDetails = async () => {
      try {
        const requestLoad = {
          name: fullName,
          email: email,
          phone: contact,
          company,
          isActive: true,
          project: aboutProject,
          noOfPeople: noOfPeople[activeIndex],
          workEmail,
          hiringNeed: hiringNeed,
          companySize: companysize[companyIndex],
          calandyFlag: false,
          startDate: start,
          isFunded: seedIndex === 0 ? true : false,
          isPublic: publicListedIndex === 0 ? true : false,
          candidatePersona: candidate,
        };
        const response = await postApi("corporate/createEmployer", requestLoad);
        const { data } = response;
        if (data.status == 1) {
          toast.success(data?.message);
          localStorage.setItem("token", data?.data?.token);
          dispatch({
            type: SET_AUTH,
            payload: { token: data?.token, type: EMPLOYER },
          });
          navigate(`/employer/calendly`);
        } else {
          toast.error(data?.message);
        }
      } catch (err) {
        toast.error("Something went Wrong");
      } finally {
        navigate(`/employer/calendly`);
      }
    };
    createCorporateDetails();
  };
  const navigate = useNavigate();
  const content = (
    <form className="needs-validation" noValidate="">
      <div className="row">
        <div className="col-12 d-flex flex-wrap justify-content-around">
          {candidate?.map(
            (
              { name, speciality, experience, isActive, technology },
              internalIdex
            ) => {
              return (
                <CardComponent
                  handleChange={(e) => handleChange(e, internalIdex)}
                  name={name}
                  speciality={speciality}
                  experience={experience}
                  isActive={isActive}
                  technology={technology}
                />
              );
            }
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <Button
            className="w-50 my-3"
            onClick={handleContactClick}
            disabled={isDisabled}
          >
            Submit and Continue
          </Button>
        </div>
      </div>
    </form>
  );
  const body = bodyTemp({
    content: content,
  });
  const header = headerTemp({
    naviagteTo: "/employer/hiring",
    title: "Select Suitable Candidate Personas",
    subtitle:
      "This will help our representative match suitable candidates for your requirement prior to your call.",
  });

  return (
    <CustomCard
      CardBody={body}
      Header={header}
      override="suitable-candidate-body"
    />
  );
}

export default SuitableCandidate;
