import CustomModal from "./CustomModal";
import React from "react";

const Modal = {
  createModal: ({
    Content,
    modalConfig,
    title,
    SubmitComponent,
    onHandleClose = () => {},
    show,
    overwriteClassName,
    closeButton,
  }) => {
    return (
      <CustomModal
        show={show}
        onHandleClose={onHandleClose}
        title={title}
        Content={Content}
        modalConfig={modalConfig}
        SubmitComponent={SubmitComponent}
        overwriteClassName={overwriteClassName}
        closeButton={closeButton}
      />
    );
  },
  ModalSize: {
    lg: "lg",
    md: "md",
    sm: "sm",
    xl: "xl",
    xs: "xs",
  },
};
export default Modal;
