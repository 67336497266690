import React, { useEffect, useState } from "react";
import { getApi } from "../../utils/ApiMethods";
import UserContext from "./UserContext";


const UserState = (props) =>{


    const [isLoggedIn,setIsLoggedIn] = useState(false)
    const [userData,setUserData] = useState({})

    //Get Logged In User Data
    const getUserData = async() =>{
        try {
            const response = await getApi(`users/userDtatFetch`)
            const data = response.data
            // 
            setUserData(data.data)
            
        } catch (error) {
            
            // setUserData({status:false})
     
            }
    }

    const getAuth = () => {
        const token =  localStorage.getItem("token")
        if(token) {
            setIsLoggedIn(true)
        }
    }

    useEffect(() =>{
        getAuth()
    },[])

    return (
        <UserContext.Provider value={{getUserData,userData,isLoggedIn,setIsLoggedIn}}>
            {props.children}
        </UserContext.Provider>
    )
}   

export default UserState;