import React from "react";

function PageContent({ title, description }) {
  return (
    <div className="dashboard-page-content">
      <label className="title">{title}</label>
      <label className="description">{description}</label>
    </div>
  );
}

export default PageContent;
