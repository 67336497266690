import "./CandidateProfile.scss";

import React, { useEffect, useState } from "react";
import { encodeURI2, handelError } from "../../../utils/helperFunctions";
import { useNavigate, useParams } from "react-router-dom";

import { postApi } from "../../../utils/ApiMethods";
import useLogout from "../../../customHooks/useLogout";
import { useSelector } from "react-redux";

function CandidateProfile({ item, index }) {
  const { id: employerId } = useSelector(
    (state) => state?.employer?.employerDetails
  );
  const [userAccountDetails, setUserAccountDetails] = useState({});
  const [candidateId, setCandidateId] = useState("");
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const { ID } = useParams();
  const [logout] = useLogout();
  const handelShortList = async (value) => {
    try {
      const response = await postApi(`shortlisted/selectCandidate`, {
        employerId,
        userId: candidateId,
      });
      const { status } = response;
      if (status === 200) {
        setDisabled(true);
      } else {
      }
    } catch (err) {}
  };
  const getUserAccountDetails = async () => {
    try {
      const response = await postApi(`accountDetails/getCandidateDetails`, {
        userId: candidateId,
      });

      const data = response.data;
      if (data?.status == 1) {
        setUserAccountDetails(data?.data);
        setDisabled(
          data.data?.shortlistedCandidates?.filter(
            (candidate) =>
              candidate?.employerId == employerId &&
              candidate?.userId == candidateId
          )[0]?.isShortlisted
        );
      } else {
      }
    } catch (error) {
      handelError({ ...error, logout });
    }
  };

  useEffect(() => {
    if (candidateId !== "") {
      getUserAccountDetails();
    }
  }, [candidateId]);

  useEffect(() => {
    setCandidateId(ID);
  }, [ID]);

  return (
    <>
      <section className="container main-section-candidate">
        <div className="row pb-5 mb-lg-2 pt-5 mt-n3 mt-md-0">
          <div className="col-sm-6 d-flex cta" onClick={() => navigate(-1)}>
            <i className="backButton"></i>
            <h1 className="h2 mb-4">Candidate's List</h1>
          </div>
          <div className="col-sm-6">
            <button
              type="button"
              class="btn shortlist-button"
              disabled={disabled}
              onClick={handelShortList}
            >
              {`${disabled ? "Shortlisted" : "Shortlist"}`}
            </button>
          </div>
        </div>
        {userAccountDetails &&
          userAccountDetails?.accountDetails &&
          userAccountDetails?.accountDetails.length > 0 && (
            <div className="row">
              <div className="col-3" style={{ zIndex: 1 }}>
                {userAccountDetails?.accountDetails[0].userProfile ? (
                  <img
                    alt=""
                    style={{
                      height: "145px",
                      width: "145px",
                      maxWidth: "145px",
                      borderRadius: "8px",
                    }}
                    src={`${
                      userAccountDetails?.accountDetails[0].userProfile
                        ? userAccountDetails?.accountDetails[0].userProfile.startsWith(
                            "http"
                          )
                          ? encodeURI(
                              userAccountDetails?.accountDetails[0].userProfile
                            )
                          : encodeURI2(
                              userAccountDetails?.accountDetails[0].userProfile
                            )
                        : "/placeholder.jpg"
                    }`}
                  ></img>
                ) : (
                  <i className="rectangleProfilePic "></i>
                )}
                <h2 className="h4 pb-1 mb-2 my-1">
                  <a href="" className="text-capitalize">
                    {"ID : " + userAccountDetails?.id}
                  </a>
                </h2>
                <div className="align-items-center card-description  my-3">
                  <p className="description mb-0">Location</p>
                  <p className="description-value mb-0">
                    {userAccountDetails?.accountDetails[0].city}
                  </p>
                </div>
                <div className="align-items-center card-description  my-3">
                  <p className="description mb-0">Email</p>
                  <p className="description-value mb-0">
                    {userAccountDetails?.email}
                  </p>
                </div>
                <div className="align-items-center card-description  my-3">
                  <p className="description mb-0">Phone</p>
                  <p className="description-value mb-0">
                    {userAccountDetails?.accountDetails[0].phone}
                  </p>
                </div>
                <div className="align-items-center card-description  my-3">
                  <p className="description mb-0">Tools</p>
                  <p className="tools tools-border border border-info">
                    {"XYZ"}
                  </p>
                  <p className="tools tools-border border border-info">
                    {"abc"}
                  </p>
                  <p className="tools tools-border border border-info">
                    {"qwe"}
                  </p>
                </div>
              </div>
              <div className="col-9">
                <div className="align-items-center heading-cotent my-4">
                  <p className="description mb-0">Portfolio Link</p>
                  <a
                    href={userAccountDetails?.accountDetails[0].userProfile}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {userAccountDetails?.accountDetails[0].userPortfolio}
                  </a>
                </div>
                <div className="row align-items-center heading-cotent my-4">
                  <div className="col-4" style={{ paddingLeft: "0px" }}>
                    <div className="d-flex align-items-center description mb-0">
                      <i className="linkedIn"></i>
                      <p className="description mb-0">LinkedIn URL</p>
                    </div>
                    <a href="abc">{userAccountDetails?.linkedinProfile}</a>
                  </div>
                  <div className="col-4">
                    <div className="d-flex align-items-center description mb-0">
                      <i className="epicIcon"></i>
                      <p className="description mb-0">Epic Online Service</p>
                    </div>
                    <a href="abc">{"Epic online service/in/Kavya/"}</a>
                  </div>
                  <div className="col-4">
                    <div className="d-flex align-items-center description mb-0">
                      <i className="artstation"></i>
                      <p className="description mb-0">Artstation Profile</p>
                    </div>
                    <a href="abc">{"Artstation.com/in/Kavya/"}</a>
                  </div>
                </div>
                <div className="row align-items-center heading-cotent my-4">
                  <div className="col-6" style={{ paddingLeft: "0px" }}>
                    <p className="description mb-2">Skilled In</p>
                    {userAccountDetails?.accountDetails[0].skills.map(
                      (item, index) => {
                        return (
                          <p className="tools tools-border border border-info heading-data mr-3">
                            {item}
                          </p>
                        );
                      }
                    )}
                  </div>
                  <div className="col-6">
                    <p className="description mb-2">Speciality</p>
                    {/* {userAccountDetails?.speciality
                .map(
                  (item, index) => {
                    return ( */}
                    <p className="tools tools-border border border-info heading-data mr-3">
                      {userAccountDetails?.speciality}
                    </p>
                    {/* );
                  }
                )} */}
                  </div>
                </div>
                <div className="card border-0 shadow-sm overflow-hidden mb-4 row align-items-center heading-cotent my-4">
                  <div className="row align-items-center heading-cotent my-3 mx-3 desc">
                    <div className="col-3" style={{ paddingLeft: "0px" }}>
                      <p className="description mb-0">
                        Expected Annual Earning*
                      </p>
                      <p className="heading-data">
                        {`${userAccountDetails?.accountDetails[0].expectedEarning}`}
                        <span className="span-color">per year</span>
                      </p>
                    </div>
                    <div className="col-3">
                      <p className="description mb-0">
                        Available to start working in
                      </p>
                      <p className="heading-data">
                        {userAccountDetails?.accountDetails[0].daysForWorking}
                        <span className="span-color">days</span>
                      </p>
                    </div>
                    <div className="col-3">
                      <p className="description mb-0">Years of experience</p>
                      <p className="heading-data">
                        {userAccountDetails?.experience}
                      </p>
                    </div>
                    <div className="col-3">
                      <p className="description mb-0">
                        Prior Freelance Experience
                      </p>
                      <p className="heading-data">
                        {userAccountDetails?.accountDetails[0].isPriorexp
                          ? "Yes"
                          : "No"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card border-0 shadow-sm overflow-hidden mb-4 row align-items-center heading-cotent my-4">
                  <div className="row align-items-center heading-cotent my-3 mx-3">
                    <div
                      className="d-flex align-items-center description"
                      style={{ paddingLeft: "0px" }}
                    >
                      <i className="workIcon"></i>
                      <p className="description mb-0">
                        {
                          userAccountDetails?.accountDetails[0].availability
                            ?.title
                        }
                      </p>
                    </div>
                    <p
                      className="heading-data py-2"
                      style={{ paddingLeft: "0px" }}
                    >
                      <span
                        className="span-color"
                        style={{ marginLeft: "0px" }}
                      >
                        {
                          userAccountDetails?.accountDetails[0].availability
                            .description
                        }
                      </span>
                    </p>
                    <p
                      className="description mb-3"
                      style={{ paddingLeft: "0px" }}
                    >
                      Interested in full-time work (8hrs/day, 40hrs/week)
                    </p>
                  </div>
                </div>
                <div className="align-items-center heading-cotent my-4">
                  <p className="description mb-0">Work History</p>
                  <div className="card border-0 shadow-sm overflow-hidden mb-4 row align-items-center heading-cotent my-3">
                    <div className="row align-items-center heading-cotent my-3 mx-3">
                      <div className="col-5">
                        <p className="work-history-heading mb-0">Job Title</p>
                        <p className="work-history-data">
                          {"Freelance Consultant"}
                        </p>
                        <p className="work-history-heading mb-0">Position</p>
                        <p className="work-history-data">
                          {"Senior Consultant"}
                        </p>
                        <p className="work-history-heading mb-0">
                          Work Details
                        </p>
                        <p className="work-history-data">
                          {
                            "Senior UE5 Artist for last 2 years. Managed all the projects"
                          }
                        </p>
                      </div>
                      <div className="col-1">
                        <div class="vr vertical-line"></div>
                      </div>
                      <div className="col-6">
                        <div className="row heading-cotent my-3 mx-3">
                          <div className="col-4">
                            <p className="work-history-heading mb-0">
                              Employer name
                            </p>
                            <p className="work-history-data">{"Microsoft"}</p>
                          </div>
                          <div className="col-4">
                            <p className="work-history-heading mb-0">
                              Start Date
                            </p>
                            <p className="work-history-data">{"Jan 2016"}</p>
                          </div>
                          <div className="col-4">
                            <p className="work-history-heading mb-0">
                              End Date
                            </p>
                            <p className="work-history-data">{"Jan 2020"}</p>
                          </div>
                        </div>
                        <div className="row align-items-center heading-cotent my-3 mx-3">
                          <p className="work-history-heading mb-0">Location</p>
                          <p className="work-history-data">{"Mumbai"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </section>
    </>
  );
}

export default CandidateProfile;
