import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import React from "react";

function MainBoard(props) {
  return (
    <div className="mainboard">
      <Header />
      {props.children}
      {/* <Footer /> */}
    </div>
  );
}

export default MainBoard;
