import "./profile.scss";

import { RESET_AUTH, SET_SIGNUP_CREDS } from "../../Redux/Actions/types";
import { postApi, putApi } from "../../utils/ApiMethods";
import { useDispatch, useSelector } from "react-redux";

import Availability from "./availability/Availability";
import { Button } from "react-bootstrap";
import Experience from "./experience/Experience";
import ExperienceRange from "./range/ExperienceRange";
import FreeLanceExp from "./experience/freelanceExp/FreeLanceExp";
import Monetry from "./monetryexpeactation/Monetry";
import React from "react";
import SkilledList from "./skilledList/SkilledList";
import Speciality from "./skilledList/Speciality";
import Steps from "../../Components/common/circularSteps/Steps";
import leftTiltedVector from "../../Assets/img/tiltedArrow/leftTiltedVector.png";
import rightTiltedVector from "../../Assets/img/tiltedArrow/rightTiltedArrow.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function CompleteProfile(props) {
  const {
    firstName,
    resume,
    userPortfolio,
    experience,
    isPriorexp,
    daysforWorking,
    skills,
    speciality,
    availability,
    currentEarning,
    expectedEarning,
    isProfileComplete,
  } = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleUpdateAccount = async (values) => {
    let result = false;
    try {
      setLoading(true);
      const response = await putApi(`accountDetails/updateAccountDetails`, {
        resume,
        userPortfolio,
        experience,
        isPriorexp,
        daysforWorking,
        skills,
        speciality,
        availability,
        currentEarning,
        expectedEarning,
        isProfileComplete: true,
      });

      const { data, status } = response;
      if (status === 200) {
        toast.success(data.message);
        result = true;
        navigate("/dashboard");
      } else {
        toast.success(data.message);
      }
    } catch (error) {
      setLoading(false);
      dispatch({ type: RESET_AUTH });
      localStorage.removeItem("token");
      navigate("/sign-in");

      toast.error("Something went wrong");
    }
    setLoading(false);
    return result;
  };

  const handleFilechange = async (e) => {
    try {
      let file = e.target.files[0];
      if (!file) {
        return;
      }
      const type = file.type.split("/")[1];
      const imageType = ["jpeg", "jpg", "png", "pdf", "docx"];
      const validImageType = imageType.includes(type);
      if (!validImageType) {
        return;
      } else {
        if (file.size > 1000001) {
          return;
        } else {
          let fr = new FileReader();
          fr.readAsDataURL(file);
          fr.onload = function () {};
        }
      }

      const formdata = new FormData();
      formdata.append("file", file);
      const response = await postApi(
        "testDetail/createTestDetailsUploadImage",
        formdata
      );
      const data = response.data;
      dispatch({
        type: SET_SIGNUP_CREDS,
        payload: {
          resume: data.Image,
        },
      });
    } catch (err) {
      toast.error("Something went Wrong");
    }
  };
  const handleChange = ({ name, value }) => {
    dispatch({
      type: SET_SIGNUP_CREDS,
      payload: {
        [name]: value,
      },
    });
  };
  const isDisabled =
    resume === "" ||
    userPortfolio === "" ||
    experience === "0" ||
    skills?.length === 0 ||
    daysforWorking === 0 ||
    availability?.title === "" ||
    currentEarning === "" ||
    expectedEarning === "";
  // useEffect(() => {
  //   if (isProfileComplete) {
  //     navigate("/dashboard");
  //   }
  // }, [isProfileComplete]);
  return (
    <>
      <div className="container ">
        <div className="completeprofile">
          <div className="mainheadline">
            <h1>hey {firstName}, we would like to know you better..</h1>
            <label>
              Provide us the few basic details,Let us help you better
            </label>
          </div>
          <div className="profilesection-1 d-flex">
            <Steps count="1" />
            <div className="profiletitle">
              <div>
                <label className="label-1">Complete Your Profile</label>
              </div>
              <div>
                <label className="label-2">
                  Provide us a little more information to match you with the
                  eligible jobs
                </label>
              </div>
            </div>
          </div>
          <div className="upload ">
            <div>
              <label className="label-1-upload">Upload your Resume *</label>
              <label className="label-2-upload">
                It gives us a better understanding of your expectations
              </label>
            </div>

            <img
              className="leftArrow arrow"
              src={leftTiltedVector}
              alt="Girl in a jacket"
            />
            <div className="collectionImage">
              <div className="innerCollection">
                <img
                  className="rightArrow-1 arrow"
                  src={rightTiltedVector}
                  alt="Girl in a jacket"
                />
                <img
                  className="rightArrow-2 arrow"
                  src={rightTiltedVector}
                  alt="Girl in a jacket"
                />
                <img
                  className="rightArrow-3 arrow"
                  src={rightTiltedVector}
                  alt="Girl in a jacket"
                />
              </div>
            </div>

            <div
              className="uploadcontainer"
              // style={{
              //   backgroundImage: `url(${profileImg})`,
              //   backgroundSize: "100% 100%",
              //   backgroundRepeat: "no-repeat",
              // }}
            >
              <div>
                {resume ? (
                  <>
                    <i
                      className="bi bi-file-pdf"
                      style={{
                        color: "#F40F02",
                        display: "inline-block",
                        fontSize: "6rem",
                      }}
                    ></i>
                    <i
                      className="bi bi-x close-icon close"
                      onClick={() => {
                        dispatch({
                          type: SET_SIGNUP_CREDS,
                          payload: {
                            resume: "",
                          },
                        });
                      }}
                    ></i>
                  </>
                ) : (
                  <i className="uploadIcon_svg" for="profileImage"></i>
                )}
              </div>
              <input
                id="resume"
                type="file"
                accept="png, jpeg, jpg,png,pdf"
                name="resume"
                className="upload-file-input"
                onChange={handleFilechange}
                // inputProps={{ accept: "image/*" }}
              />
            </div>
          </div>
          <div className="portfolia-link">
            <form>
              <label htmlFor="fn" className="form-label fs-base">
                Link to your Portfolio *
              </label>
              <input
                value={userPortfolio}
                type="text"
                id="fn"
                className="form-control form-control-lg"
                placeholder="URL"
                name="portfolio"
                onChange={(event) =>
                  handleChange({
                    name: "userPortfolio",
                    value: event?.currentTarget?.value,
                  })
                }
              />
            </form>
          </div>
          <div className="skills">
            <div className="title">
              <div className="dotSymbol"></div>
              <label>Tell about your skills</label>
            </div>
            <SkilledList handleChange={handleChange} skills={skills} />
            {/* <Speciality handleChange={handleChange} speciality={speciality} /> */}
            <Experience handleChange={handleChange} experience={experience} />
            <FreeLanceExp handleChange={handleChange} isPriorexp={isPriorexp} />
          </div>
          <Availability
            handleChange={handleChange}
            availability={availability}
          />
          <ExperienceRange
            handleChange={handleChange}
            daysforWorking={daysforWorking}
          />
          <Monetry
            currentEarning={currentEarning}
            expectedEarning={expectedEarning}
            handleChange={handleChange}
          />
        </div>
        <div className="submitprofile">
          <Button className="skip" onClick={() => navigate("/test")}>
            Skip for now
          </Button>
          <Button
            className="shadow"
            disabled={isDisabled}
            onClick={handleUpdateAccount}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
}

export default CompleteProfile;
