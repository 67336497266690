import "./menuitem.scss";

import { Nav } from "react-bootstrap";
import React from "react";

function MenuItem({
  name,
  eventKey,
  isDisabled = false,
  isActive = false,
  iconClass,
  value,
}) {
  return (
    <Nav.Item className="menuitem">
      <div className={`activediv ${isActive ? "isactive" : ""} `}></div>
      <Nav.Link
        value={value}
        eventKey={eventKey}
        disabled={isDisabled}
        className={isActive ? "isactive" : "inactive"}
        name={name}
      >
        <i className={`bi ${iconClass} iconSize`}></i>
        <label>{name}</label>
      </Nav.Link>
    </Nav.Item>
  );
}

export default MenuItem;
