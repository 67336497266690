import { BaseUrl } from "./BaseUrl";
import axios from "axios";

const instance = (props = {}) => {
  const { baseURL = BaseUrl, timeout = 10000 } = props;
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  return axios.create({
    baseURL,
    timeout,
    headers,
  });
};
export default instance;
