import {
  RESET_EMPLOYER_HIRING,
  SET_EMPLOYER_HIRING,
} from "../../Actions/types";

import initialState from "../../InitialState/initialState";

export default function hiringReducer(
  state = initialState?.employer?.hiring,
  { type, payload }
) {
  switch (type) {
    case SET_EMPLOYER_HIRING:
      return { ...state, ...payload };
    case RESET_EMPLOYER_HIRING:
      return initialState?.employer?.hiring;
    default:
      return state;
  }
}
