import { useFormik } from "formik";
import React, { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { Loader } from "../../Components/Loader";
import { postApi } from "../../utils/ApiMethods";
import OTP from "../Account/OTP";
import { contactFormValidation } from "./validation";

export default function ContactForm() {
  const routerLocation = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("id");
  const name = searchParams.get("name").split("__")[0];
  const experience = searchParams.get("experience");
  const skill = searchParams.get("skills");

  const [loading, setLoading] = useState(false);
  const handleContactClick = async (values) => {
    try {
      const {
        email: corporateEmail,
        name: corporateName,
        phone: corporatePhone,
        company,
      } = values;

      const response1 = await postApi("corporate/createCorporateDetails", {
        studentId: userId,
        name: corporateName,
        email: corporateEmail,
        phone: corporatePhone,
        company,
      });
      const data1 = response1.data;
      if (data1.status == 1) {
        
      } else {
        toast.error("May be an issue arise. Please Try later");
      }
      
      const response = await postApi("users/corporateContactCandidate", {
        id: userId,
        name,
        experience,
        skill,
        email: values.email,
      });
      const data = response.data;
      if (data.status == 1) {
        navigate(`/thankyou${routerLocation.search}`);
        setLoading(false);
      } else {
        toast.error("May be an issue arise. Please Try later");
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error("Something went Wrong");
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      phone: null,
      company: "",
    },
    validationSchema: contactFormValidation,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      handleContactClick(values);
    },
  });
  return (
    <>
      <Header />
      <section className="position-relative py-5">
        <div className="container py-5">
          <div className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 align-self-end p-4 shadow-lg">
            <h1 className="text-center text-xl-start mb-5">Contact us</h1>
            <form
              className="needs-validation"
              noValidate=""
              onSubmit={formik.handleSubmit}
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="position-relative mb-4">
                    <label htmlFor="name" className="form-label fs-base">
                      Full name
                    </label>
                    <input
                      type="text"
                      id="fullname"
                      disabled={loading}
                      className={`form-control form-control-lg ${
                        formik.touched.name && formik.errors.name
                          ? "border-danger"
                          : ""
                      }`}
                      name="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="text-danger  start-0 top-100">
                        {formik.touched.name && formik.errors.name}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="position-relative mb-4">
                    <label htmlFor="email" className="form-label fs-base">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      disabled={loading}
                      className={`form-control form-control-lg ${
                        formik.touched.email && formik.errors.email
                          ? "border-danger"
                          : ""
                      }`}
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="text-danger  start-0 top-100">
                        {formik.touched.email && formik.errors.email}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="position-relative mb-4">
                    <label htmlFor="tel" className="form-label fs-base">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      disabled={loading}
                      id="number"
                      className={`form-control form-control-lg ${
                        formik.touched.phone && formik.errors.phone
                          ? "border-danger"
                          : ""
                      }`}
                      name="phone"
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <div className="text-danger  start-0 top-100">
                        {formik.touched.phone && formik.errors.phone}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="position-relative mb-5">
                    <label htmlFor="text" className="form-label fs-base">
                      Company
                    </label>
                    <input
                      type="text"
                      id="company"
                      disabled={loading}
                      className={`form-control form-control-lg ${
                        formik.touched.company && formik.errors.company
                          ? "border-danger"
                          : ""
                      }`}
                      name="company"
                      onChange={formik.handleChange}
                      value={formik.values.company}
                    />
                    {formik.touched.company && formik.errors.company && (
                      <div className="text-danger  start-0 top-100">
                        {formik.touched.company && formik.errors.company}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {loading ? (
                <button
                  disabled={true}
                  className="btn btn-primary shadow-primary btn-lg w-100"
                >
                  <Loader style={{ width: "1rem", height: "1rem" }} />
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary shadow-primary btn-lg w-100"
                >
                  Submit
                </button>
              )}
              <p className="text-center">
                Please fill out the form below so that we can contact you at the
                earliest.
              </p>
            </form>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
