import "./sidebar.scss";

import MenuItem from "./menuItem/MenuItem";
import { Nav } from "react-bootstrap";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";

export default function SidebarEmployer({ currentTab }) {
  const navigate = useNavigate();
  const { isApproved } = useSelector((state) => state?.employer?.approval);
  const [isActive, setActive] = useState({ [currentTab]: true });
  useEffect(() => {
    if (currentTab) {
      if (!isApproved && currentTab === "/candidate") {
        navigate("/employer/dashboard/calls");
      } else {
        setActive({ [currentTab]: true });
      }
      setActive({ [currentTab]: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const listMenuItems = [
    {
      name: "Candidate",
      value: "/candidate",
      isDisabled: !isApproved,
      iconClass:
        isActive["candidate"] & isApproved
          ? "dashboard_active"
          : "dashboard_black",
    },
    {
      name: "Shortlists",
      value: "/shortlists",
      isDisabled: false,
      iconClass: "bi-pencil-square",
    },

    {
      name: "Scheduled Calls",
      value: "/calls",
      isDisabled: false,
      iconClass: "bi-gear",
    },
  ];
  return (
    <Nav
      className="flex-column sidebar"
      onSelect={(selectedKey, a, v) => {
        setActive({ [a?.currentTarget?.attributes?.value?.value]: true });
        navigate(`/employer/dashboard${selectedKey}`);
      }}
    >
      {listMenuItems?.map(({ name, value, isDisabled, iconClass }, index) => (
        <MenuItem
          key={index + name}
          isDisabled={isDisabled}
          name={name}
          value={value}
          eventKey={value}
          isActive={isActive[value]}
          iconClass={iconClass}
        />
      ))}
    </Nav>
  );
}
