import { RESET_UNREAL, SET_UNREAL } from "../Actions/types";

import initialState from "../InitialState/initialState";

export default function unrealReducer(
  state = initialState?.unreal,
  { type, payload }
) {
  switch (type) {
    case SET_UNREAL:
      return { ...state, ...payload };
    case RESET_UNREAL:
      return initialState?.unreal;
    default:
      return state;
  }
}
