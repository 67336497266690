import {
  RESET_AUTH,
  RESET_MODAL,
  RESET_SIGNUP_CREDS,
  RESET_TEST_DETAILS,
  RESET_UNREAL,
} from "../Redux/Actions/types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { EMPLOYER } from "../constant";
import React from "react";

function useLogout(props) {
  const navigate = useNavigate();
  const { type } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const logout = () => {
    const isEmployer = type?.toLowerCase() === EMPLOYER;
    localStorage.removeItem("token");
    localStorage.removeItem("ltoken");
    dispatch({
      type: RESET_SIGNUP_CREDS,
    });
    dispatch({
      type: RESET_AUTH,
    });
    dispatch({ type: RESET_MODAL });
    dispatch({ type: RESET_TEST_DETAILS });
    dispatch({ type: RESET_UNREAL });
    navigate(isEmployer ? "/home" : "/sign-up");
  };
  return [logout];
}

export default useLogout;
