import { yupToFormErrors } from "formik";
import * as Yup from "yup";
export const validationSchema = Yup.object().shape({
  firstName: Yup.string("Invalid value")
    .nullable()
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  lastName: Yup.string("Invalid value")
    .nullable()
    .max(50, "Must be 50 characters or less")
    .required("Required"),

  // email: Yup.string()
  // .email("Invalid Email address")
  // .required("Required"),

  // profilePic: Yup.mixed().required("Please select video file"),
  skills: Yup.array()
    .nullable()
    .min(1, "Required")
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    )
    .required("Required"),
  phone: Yup.number("Must be a valid phone no.")
    .typeError("Must be a valid phone no.")
    .nullable()
    .min(1000000000, "Must be a valid phone no.")
    .max(9999999999, "Must be a valid phone no."),
  bio: Yup.string()
    .nullable()
    .max(255, "Letters should not exceed 255 characters"),

  // ).typeError('please Enter Valid skills'),

  // skills:Yup.array()
  // .min(1, "Required")
  // .of(
  //   Yup.string()

  // ).typeError('please Enter Valid skills'),

  github: Yup.string()
    .nullable()
    .matches(/^https:\/\//,"Please enter the valid url")
    .required("Required"),
  stackoverflow: Yup.string()
    .nullable()
    .matches(/^https:\/\//,"Please enter the valid url")
    .required("Required"),
  hackerrank: Yup.string()
    .nullable()
    .matches(/^https:\/\//,"Please enter the valid url")
    .required("Required"),
  leetcode: Yup.string()
    .nullable()
    .matches(/^https:\/\//,"Please enter the valid url")
    .required("Required"),
  country: Yup.object().nullable().required("Required"),
  state: Yup.object().required("Required").nullable(),
  city: Yup.object().required("Required").nullable(),
  zipcode: Yup.number()
    .typeError("Zipcode must be a number")
    .min(100000, "Must be a valid 6 digit code")
    .max(999999, "Must be a valid 6 digit code")
    .required("Required"),
  address: Yup.string()
    .nullable()
    .max(100, "Must be 100 characters or less")
    .required("Required"),
  address2: Yup.string().nullable().max(100, "Must be 100 characters or less"),
  // speciality: Yup.object().shape({
  //   label: Yup.string().required(),
  //   value: Yup.string().required()
  // }),
  // timeZone: Yup.object().shape({
  //   label: Yup.string().required(),
  //   value: Yup.string().required()
  // }),
  // experience: Yup.object().shape({
  //   label: Yup.string().required(),
  //   value: Yup.string().required()
  // })
  speciality: Yup.string().required("Required"),
  timeZone: Yup.string().required("Required"),
  experience: Yup.number().required("Required").integer(),
});
