import "./commoncomponetCSS.scss";

import { RESET_MODAL, SET_MODAL } from "../../../Redux/Actions/types";
import { useDispatch, useSelector } from "react-redux";

import ConfirmModal from "./ConfirmModal";
import OTPModalEmployer from "../OTPModal/OTPModalEmployer";
import React from "react";
import { async } from "q";
import { postApi } from "../../../utils/ApiMethods";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function HeaderTemplate({ naviagteTo = "/home", title, subtitle }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { isApproved } = useSelector((state) => state?.employer?.approval);
  // useEffect(() => {
  //   if (isApproved) {
  //     dispatch({ type: RESET_MODAL });
  //   } else {
  //     dispatch({ type: SET_MODAL, payload: { employer_review: true } });
  //   }
  // }, [isApproved]);

  return (
    <>
      <div className="headerTemplate d-flex ">
        <i
          className="bi left-arrow mx-2"
          onClick={() => navigate(naviagteTo)}
        ></i>
        <label>{title}</label>

        <OTPModalEmployer />
      </div>
      {subtitle && <p className="subtitle">{subtitle}</p>}
    </>
  );
}

export default HeaderTemplate;
