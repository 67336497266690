import "./range.scss";

import React from "react";
import { useEffect } from "react";
import { useRef } from "react";

function Range({ min = 0, max = 100, onChange, value, width = "100%" }) {
  const inp = useRef(null);
  const out = useRef(null);
  useEffect(() => {
    out.current.innerHTML = value + " days";
    inp.current.style.background = `linear-gradient(
        to right,
        #6366f1 0%,
        #6366f1 ${value}%,
        #E4E4E4 10%,
        #E4E4E4 100%
      )`;
    out.current.style.left = `calc(${value}% + (${8 - value * 0.15}px))`;
  }, [value]);
  const handleChange = () => {
    const val = inp?.current?.value;
    const newVal = Number(((val - min) * 100) / (max - min));

    onChange(newVal);
  };
  return (
    <div className="range-wrapper" style={{ width }}>
      <div style={{ position: "absolute", top: "28%", left: "0%" }}>0</div>
      <div class="range-wrap" onChange={handleChange}>
        <input
          type="range"
          class="range"
          ref={inp}
          id="myinput"
          min={min}
          max={max}
          value={value}
        />
        <output class="bubble" ref={out}></output>
      </div>
      <div style={{ position: "absolute", top: "28%", right: "0%" }}>100</div>
    </div>
  );
}

export default Range;
