import React, {useState, useEffect, useContext} from 'react';
import UserContext from "../../context/user/UserContext";
import { useNavigate } from "react-router-dom";
import { postApiWithoutToken } from "../../utils/ApiMethods";
import { toast } from "react-toastify";
import { Loader } from "../../Components/Loader";
import { useFormik } from "formik";
import OTPModal from "../Account/OTPModal";
import * as Yup from "yup";




import ImageOne from '../../Assets/img/landing/online-courses/signup-img.jpg'; 

export default function CandidateLandingSeven() {
    const context = useContext(UserContext);
    const { isLoggedIn } = context;
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);

    const [email, setEmail] = useState("");
    let navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
          navigate("/test");
        }
      }, [isLoggedIn]); 
    const formik = useFormik({
        initialValues: {
          email: "",
        },
        validationSchema: Yup.object({
          email: Yup.string().email("Invalid Email address").required("Required"),
        }),
        onSubmit: async (values, { resetForm }) => {
          
          setEmail(formik.values.email);
          const signIn = await handleSignUp();
          if (signIn) {
            resetForm({ values: "" });
          }
        },
      });
    const handleLinkedinLogin = () => {
        window.location.href = `${process.env.REACT_APP_BaseUrl}auth/linkedin`;
    }
    const handleSignUp = async () => {
        let result = false;
        
        try {
          setLoading(true);
          const response = await postApiWithoutToken(`users/UserLogin`, {
            email: formik.values.email,
          });
          const data = response.data;
          
          if (data.status) {
            result = true;
            setIsOpen(true);
          } else {
            toast.error(data.message);
          }
        } catch (error) {
          console.error(error);
          setLoading(false);
          toast.error("Something went wrong");
        }
        setLoading(false);
        return result;
      };
  return (
    <>
    <section
        className="bg-secondary pb-lg-5"
        style={{ marginTop: "-240px", paddingTop: 300 }}
        >
        <div className="container pt-2 pt-md-3 pt-lg-5 pb-5">
            <h2 className="h1 text-center pb-2 mb-4 mb-lg-5">
            Ready to Kick-Start Your Career?
            <br />
            Start Completing the Tests
            </h2>
            <div className="row pb-3">
            <div className="col-xl-7 col-md-6">
                <div
                className="d-flex flex-column w-100 h-100 rounded-3 bg-position-center bg-repeat-0 bg-size-cover"
                style={{
                    backgroundImage:
                    `url(${ImageOne})`
                }}
                />
            </div>
            <div className="col-xl-5 col-md-6">
                <div className="card border-0 p-lg-4">
                <div className="card-body">
                    <h3 className="pb-3 pb-lg-4">
                    Sign in to take tests and get certified 
                    </h3>
                    <form
                    className="needs-validation mb-4 pb-2 pb-lg-0 mb-lg-5"
                    noValidate=""
                    onSubmit={formik.handleSubmit}
                    >
                    <div className="position-relative mb-4">
                        <label htmlFor="email" className="form-label fs-base">
                        Email address
                        </label>
                        <input
                        type="email"
                        id="email"
                        className={`form-control form-control-lg ${
                          formik.errors.email ? "border-danger" : ""
                        }`}
                        required=""
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        />
                        <div className="invalid-tooltip position-absolute top-100 start-0">
                        Please provide a valid email address.
                        </div>
                    </div>
                    {/* <div className="mb-4 pt-1 pb-2">
                        <label htmlFor="password" className="form-label fs-base">
                        Password
                        </label>
                        <div className="password-toggle">
                        <input
                            type="password"
                            id="password"
                            className="form-control form-control-lg"
                            required=""
                        />
                        <label
                            className="password-toggle-btn"
                            aria-label="Show/hide password"
                        >
                            <input className="password-toggle-check" type="checkbox" />
                            <span className="password-toggle-indicator" />
                        </label>
                        <div className="invalid-tooltip position-absolute top-100 start-0">
                            Please provide a valid email address.
                        </div>
                        </div>
                    </div> */}
                   <div className="d-grid">
                    {loading ? (
                      <button
                        disabled={true}
                        className="btn btn-primary shadow-primary btn-lg w-100"
                      >
                        <Loader style={{ width: "1rem", height: "1rem" }} />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary shadow-primary btn-lg w-100"
                      >
                        Sign in
                      </button>
                    )}
                  </div>
                    </form>
                    <h6 className="mb-4">Or sign up using:</h6>
                    <div className="d-flex"> 
                    <span  onClick={handleLinkedinLogin}
                        className="btn btn-icon btn-secondary btn-linkedin ms-1 me-2"
                    >
                        <i className="bx bxl-linkedin" />
                    </span> 
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        <OTPModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} email={email} />

    </section>

    </>
  )
}
