import "./chips.scss";

import React from "react";

function Chip({ isActive = false, title, handleChange }) {
  return (
    <span className={`chip ${isActive && "active"}`} onClick={handleChange}>
      <span>{title}</span>
    </span>
  );
}

export default Chip;
