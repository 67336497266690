export const loadState = () => {
  try {
    const state = localStorage.getItem("state");
    if (state === null) {
      return undefined;
    }
    return JSON.parse(atob(state));
  } catch (error) {
    return undefined;
  }
};

export const saveState = (currentState) => {
  try {
    localStorage.setItem("state", btoa(JSON.stringify(currentState)));
  } catch (error) {
    return undefined;
  }
};
