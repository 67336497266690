import React, { useEffect, useState } from "react";
import {
  experienceOption,
  skillsCandidateOptions,
  skillsOptions,
  specialityOptions,
  timeZonesOption,
} from "../../utils/jsonData";
import { getApi, getApiWithData, postApi } from "../../utils/ApiMethods";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import CandidateCard from "./CandidateCard";
import ContactModal from "./ContactModal";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { Loader } from "../../Components/Loader";
import Select from "react-select";
import UserFive from "../../Assets/img/team/05.jpg";
import UserFour from "../../Assets/img/team/04.jpg";
import UserOne from "../../Assets/img/team/01.jpg";
import UserThree from "../../Assets/img/team/03.jpg";
import UserTwo from "../../Assets/img/team/02.jpg";
import { contactFormValidation } from "./validation";
import { encodeURI2 } from "../../utils/helperFunctions";
import { toast } from "react-toastify";
import { useFormik } from "formik";

const CandidateList = () => {
  const [approvedUserList, setApprovedUserList] = useState({});
  const [selectedUserData, setSelectedUserData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [loading, setLoading2] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const [searchParamsQuery, setSearchParamsQuery] = useState({
    skills: "",
    speciality: "",
    experienceFrom: "",
    experienceTo: "",
    timeZone: "",
  });
  //
  const userId = searchParams.get("id");

  let navigate = useNavigate();
  // const [skills,setSkills] = useState('')
  // const [speciality,setSpeciality] = useState('')
  // const [experienceTo,setExperienceTo] = useState('')
  // const [experienceFrom,setExperienceFrom] = useState('')
  // const [timeZone,setTimeZone] = useState('')

  useEffect(() => {
    setSearchParamsQuery({
      ...searchParamsQuery,
      ["skills"]: searchParams.get("skills"),
      ["speciality"]: searchParams.get("speciality"),
      ["experienceFrom"]: searchParams.get("experienceFrom"),
      ["experienceTo"]: searchParams.get("experienceTo"),
      ["timeZone"]: searchParams.get("timeZone"),
    });
    setIsMounted(true);
  }, []);

  function debounce(func, timeout = 900) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const fetchAllApprovedCandidates = async () => {
    try {
      setLoading(true);

      const response = await getApi(
        `accountDetails/getAllUsersDetailsApproved?skills=${searchParamsQuery.skills}&speciality=${searchParamsQuery.speciality}&experienceTo=${searchParamsQuery.experienceTo}&experienceFrom=${searchParamsQuery.experienceFrom}&timeZone=` +
          encodeURIComponent(searchParamsQuery.timeZone)
      );

      const data = response.data;
      setApprovedUserList(data.data);

      setLoading(false);
    } catch (err) {
      // toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const routerLocation = useLocation();

  const fetchUsers = debounce(() => fetchAllApprovedCandidates());
  const handleContactClick = async () => {
    try {
      const response = await postApi("users/corporateContactCandidate", {
        id: userId,
      });
      const data = response.data;
      if (data.status == 1) {
        navigate(`/thankyou${routerLocation.search}`);
        setLoading(false);
      } else {
        toast.error("May be an issue arise. Please Try later");
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      // toast.error("Something went Wrong");
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      phone: null,
      company: "",
    },
    validationSchema: contactFormValidation,
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);

        const response = await postApi("corporate/createCorporateDetails", {
          ...values,
          studentId: userId,
        });
        const data = response.data;
        if (data.status == 1) {
          handleContactClick();

          toast.success("Contact Details Sent Successfully");
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
          // navigate(`/thankyou${routerLocation.search}`)
        } else {
          toast.error("May be an issue arise. Please Try later");
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error("Something went Wrong");
      }
    },
  });

  useEffect(() => {
    if (isMounted) {
      navigate(
        `/candidate?skills=${searchParamsQuery.skills}&speciality=${searchParamsQuery.speciality}&experienceTo=${searchParamsQuery.experienceTo}&experienceFrom=${searchParamsQuery.experienceFrom}&timeZone=` +
          encodeURIComponent(searchParamsQuery.timeZone)
      );
      fetchUsers();
    }
  }, [searchParamsQuery]);

  const handleChange = (e, name) => {
    if (name === "skills") {
      setSearchParamsQuery({
        ...searchParamsQuery,
        skills: e.map((el) => el.value),
      });
    } else if (name == "experience") {
      if (e !== "10+ yrs") {
        setSearchParamsQuery({
          ...searchParamsQuery,
          experienceFrom: e?.split(" - ")[0] || "",
          experienceTo: e?.split(" - ")[1]?.split(" ")[0] || "",
        });
      } else {
        setSearchParamsQuery({
          ...searchParamsQuery,
          experienceFrom: "10",
          experienceTo: "50",
        });
      }
    } else {
      setSearchParamsQuery({
        ...searchParamsQuery,
        [name]: e || "",
      });
    }
  };
  return (
    <>
      <section className="container">
        <div className="row pb-5 mb-lg-2 pt-5 mt-n3 mt-md-0">
          <div className="col-sm-12 col-6">
            <h1 className="h2 mb-4">Candidate's List</h1>
          </div>
          <div className="col-lg-4 col-sm-5">
            <div className="pt-xl-1 mb-3 pb-3">
              <h1 className="fs-5">Filter by</h1>

              <Select
                className="w-100 my-2"
                placeholder={<div>Skills</div>}
                name="skills"
                value={
                  searchParamsQuery &&
                  searchParamsQuery.skills &&
                  searchParamsQuery.skills.length
                    ? typeof searchParamsQuery.skills != "string"
                      ? searchParamsQuery.skills.map((el) => {
                          return { label: el, value: el };
                        })
                      : searchParamsQuery.skills.split(",").map((el) => {
                          return { label: el, value: el };
                        })
                    : []
                }
                options={skillsCandidateOptions}
                isClearable={true}
                onChange={(value) => handleChange(value, "skills")}
                isMulti
              />

              <Select
                className="w-100 my-2"
                placeholder={<div>Experience</div>}
                name="experience"
                options={experienceOption}
                isClearable={true}
                value={
                  searchParamsQuery.experienceFrom && {
                    label:
                      searchParamsQuery.experienceFrom +
                      (searchParamsQuery.experienceTo == 50
                        ? "+ yrs"
                        : " - " + searchParamsQuery.experienceTo + " yrs"),
                    value:
                      searchParamsQuery.experienceFrom +
                      " - " +
                      searchParamsQuery.experienceTo +
                      " yrs",
                  }
                }
                onChange={(value) => handleChange(value?.value, "experience")}
              />

              <Select
                placeholder={<div>Speciality</div>}
                name="speciality"
                options={specialityOptions}
                isClearable={true}
                value={
                  searchParamsQuery.speciality && {
                    label: searchParamsQuery.speciality,
                    value: searchParamsQuery.speciality,
                  }
                }
                onChange={(value) => handleChange(value?.value, "speciality")}
              />
              <Select
                className="w-100 my-2"
                placeholder={<div>Time Zone</div>}
                name="timeZone"
                options={timeZonesOption}
                isClearable={true}
                value={
                  searchParamsQuery.timeZone && {
                    label: searchParamsQuery.timeZone,
                    value: searchParamsQuery.timeZone,
                  }
                }
                onChange={(value) => handleChange(value?.value, "timeZone")}
              />
            </div>
          </div>
          <div className="col-lg-8 col-sm-7">
            <div className="ps-md-4 mt-md-1 pb-md-2">
              {isLoading ? (
                <Loader />
              ) : (
                approvedUserList.map((item, index) => {
                  return (
                    <CandidateCard
                      item={item}
                      index={index}
                      setSelectedUserData={setSelectedUserData}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default CandidateList;
