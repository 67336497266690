import "./ShortlistCard.scss";

import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";

import { encodeURI2 } from "../../../utils/helperFunctions";
import { postApi } from "../../../utils/ApiMethods";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function ShortlistCard({ item, index, fetchUsers }) {
  const [isDisabled, setisDisabled] = useState(false);
  const navigate = useNavigate();
  const { id } = useSelector((state) => state?.employer?.employerDetails);

  const removeShortListed = async (userId) => {
    try {
      setisDisabled(true);
      const response = await postApi(
        "/shortlisted/deleteShortlistedCandidate",
        { employerId: id, userId }
      );

      const data = response.data;

      if (data?.status == 1) {
        toast.success(data?.message);
        fetchUsers();
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      toast.error("Something went wrong");
    } finally {
    }
  };

  return (
    <>
      <div class="container card border-0 shadow-sm overflow-hidden mb-4">
        <div class="row">
          <div class="col-sm">
            <img
              style={{
                height: "100px",
                width: "100px",
                maxWidth: "100px",
                borderRadius: "86px",
                margin: "5px",
              }}
              alt="No Image"
              src={`${
                item.user.accountDetails[0].userProfile
                  ? item.user.accountDetails[0].userProfile.startsWith("http")
                    ? encodeURI(item.user.accountDetails[0].userProfile)
                    : encodeURI2(item.user.accountDetails[0].userProfile)
                  : "/placeholder.jpg"
              }`}
            />
          </div>
          <div class="col-sm vertical-align">
            <h2 className="h4 pb-1 mb-2">
              <Link
                to={`/employer/dashboard/profile/${item.userId}`}
                className="text-capitalize"
              >
                {"ID : " + item.userId}
              </Link>
            </h2>
          </div>
          <div class="col-sm vertical-align">
            <i className="vector "></i>
            <p className="description" style={{ marginBottom: "0" }}>
              {item?.user?.speciality}
            </p>
          </div>
          <div class="col-sm vertical-align">
            <i className="note "></i>
            <p
              className="description"
              style={{ marginBottom: "0" }}
            >{`${item?.user?.experience} Years`}</p>
          </div>
          <div class="col-sm vertical-align">
            <i className="star "></i>
            <p className="description" style={{ marginBottom: "0" }}>
              {item?.user?.speciality}
            </p>
          </div>
          <div class="col-sm vertical-align">
            {item.state}
            <button
              type="button"
              disabled={isDisabled}
              className="btn btn-outline-primary"
              style={{ float: "right" }}
              onClick={() => removeShortListed(item.userId)}
            >
              Remove Candidate
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
