import "react-toastify/dist/ReactToastify.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import MainRouter from "./MainRouter/MainRouter";
import React from "react";
import { ToastContainer } from "react-toastify";
import UserState from "./context/user/UserState";

function App() {
  return (
    <UserState>
      <MainRouter />
      <ToastContainer />
    </UserState>
  );
}

export default App;
