import { RESET_MODAL, SET_MODAL } from "../Actions/types";

import initialState from "../InitialState/initialState";

export default function setModalReducer(
  state = initialState?.modal,
  { type, payload }
) {
  switch (type) {
    case SET_MODAL:
      return { ...payload };
    case RESET_MODAL:
      return { ...payload };
    default:
      return { ...state };
  }
}
