import React from "react";
import TestDescription from "./TestDescription";
import UnrealModal from "./UnrealModal";
import { useParams } from "react-router-dom";

export default function TestDetails() {
  const { id } = useParams();
  return (
    <>
      <TestDescription id={id} />
      <UnrealModal id={id} />
    </>
  );
}
