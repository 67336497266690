import { validateEmail } from "./utils/helperFunctions";

export const getValidation = ({ value, name, checked }) => {
  const currentError = { errorMsg: "", isError: false };
  switch (name) {
    case "fullName":
      if (value?.length > 15) {
        currentError.errorMsg = "Must be 15 characters or less";
        currentError.isError = true;
      } else if (value?.length === 0) {
        currentError.errorMsg = "Required";
        currentError.isError = true;
      } else {
        currentError.errorMsg = "";
        currentError.isError = false;
      }
      break;
    case "contact":
      if (value?.length > 10) {
        currentError.errorMsg = "Must be 10 or less";
        currentError.isError = true;
      } else if (value?.length === 0) {
        currentError.errorMsg = "Required";
        currentError.isError = true;
      } else {
        currentError.errorMsg = "";
        currentError.isError = false;
      }
      break;
    case "email":
      let flag = validateEmail(value);
      if (value?.length === 0) {
        currentError.errorMsg = "Required";
        currentError.isError = true;
      } else if (!flag) {
        currentError.errorMsg = "Invalid Email address";
        currentError.isError = !flag;
      } else {
        currentError.errorMsg = "";
        currentError.isError = false;
      }
      break;

    default:
  }
  return currentError;
};
export const EMPLOYER = "employer";
export const USER = "user";
export const CLIENT_ID = "77a5ydv1tzo7ue";
export const SCOPE = "r_liteprofile r_emailaddress";
export const REDIRECT_URL = "linkedin";
export const SERVER_ERROR_CODE = {
  ERR_BAD_REQUEST: "ERR_BAD_REQUEST",
};
