import "./employerReview.scss";

import BodyTemplate from "../commomComponent/BodyTemplate";
import { Button } from "react-bootstrap";
import CustomCard from "../../../Components/common/card/CustomCard";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function EmployerReviewLandingPage(props) {
  const navigate = useNavigate();
  const { isApproved } = useSelector((state) => state?.employer?.approval);

  const Content = () => {
    return (
      <div className="employerReview">
        <div>
          <p className="description">Call With Visceral- Hire the Best</p>
        </div>
        <div>
          <Button onClick={() => navigate("/employer/calendly")}>
            Reschedule Slot<i className="editSchdedue"></i>
          </Button>
        </div>
      </div>
    );
  };
  const body = BodyTemplate({
    content: <Content />,
    title: "Scheduled calles",
  });
  const showAlert = isApproved
    ? {}
    : {
        isActive: true,
        message: "Your Profile is under review by the Visceral team",
      };

  return (
    <CustomCard CardBody={body} showAlert={showAlert} override="empReview" />
  );
}

export default EmployerReviewLandingPage;
