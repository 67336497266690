import "./customcard.scss";

import Card from "react-bootstrap/Card";
import React from "react";

export default function CustomCard({
  extendedClass,
  title,
  description,
  isActive,
  onClick,
}) {
  return (
    <Card className={`customcard ${isActive && "active"}`} onClick={onClick}>
      <Card.Body>
        <Card.Title className="d-flex">
          <i className={`workbag_svg workbag_icon ${extendedClass}`}></i>
          <label>{title}</label>
        </Card.Title>
        <Card.Text>{description}</Card.Text>
      </Card.Body>
    </Card>
  );
}
