import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "react-bootstrap";
import Chip from "../../../Components/common/chip/Chip";
import CustomCard from "../../../Components/common/card/CustomCard";
import HeaderTemplate from "../commomComponent/HeaderTemplate";
import { SET_EMPLOYER_HIRING } from "../../../Redux/Actions/types";
import bodyTemplate from "../commomComponent/BodyTemplate";
import { getValidation } from "../../../utils/helperFunctions";
import { useNavigate } from "react-router-dom";

function Hiring(props) {
  const {
    company,
    email,
    companyIndex,
    seedIndex,
    publicListedIndex,
    hiringNeed,
    companysize,
  } = useSelector((state) => state?.employer?.hiring);
  const [error, setError] = useState({
    email: { errorMsg: "", isError: false },
    company: { errorMsg: "", isError: false },
  });
  const dispatch = useDispatch();

  const seeds = [
    { title: "Yes", isActive: false },
    { title: "No", isActive: false },
  ];
  const publiclisted = [
    { title: "Yes", isActive: false },
    { title: "No", isActive: false },
  ];
  if (seedIndex > -1) {
    seeds[seedIndex].isActive = true;
  }
  if (publicListedIndex > -1) {
    publiclisted[publicListedIndex].isActive = true;
  }
  if (companyIndex > -1) {
    companysize[companyIndex].isActive = true;
  }
  const onChange = (e) => {
    dispatch({
      type: SET_EMPLOYER_HIRING,
      payload: { [e?.currentTarget?.name]: e?.currentTarget?.value },
    });
    setError({
      ...error,
      [e?.currentTarget?.name]: getValidation(e?.currentTarget),
    });
  };

  let isDisabled =
    !company ||
    !email ||
    !hiringNeed?.some((d) => d?.isActive) ||
    !seeds?.some((d) => d?.isActive) ||
    !publiclisted?.some((d) => d?.isActive) ||
    !companysize?.some((d) => d?.isActive) ||
    Object.keys(error).some((d) => error[d]?.isError);
  const handleContactClick = async (values) => {
    navigate(`/employer/suitablecandidate/`);
  };
  const navigate = useNavigate();
  const content = (
    <form className="needs-validation" noValidate="">
      <div className="row">
        <div className="col-12">
          <div className="position-relative mb-4">
            <label htmlFor="name" className="form-label fs-base">
              What your work email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className={`form-control form-control-lg $`}
              required=""
              onChange={onChange}
              value={email}
            />
            <div className="invalid-feedback position-absolute start-0 top-100 d-block">
              {error?.email?.isError && error?.email?.errorMsg}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="position-relative mb-4 d-flex flex-column">
            <label htmlFor="name" className="form-label fs-base">
              What is your company name?
            </label>
            <input
              type="text"
              id="name"
              name="company"
              className={`form-control form-control-lg `}
              required=""
              onChange={onChange}
              value={company}
            />
            <div className="invalid-feedback position-absolute start-0 top-100 d-block">
              {error?.company?.isError && error?.company?.errorMsg}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="position-relative mb-4 d-flex flex-column">
            <label htmlFor="name" className="form-label fs-base">
              Your hiring needs?
            </label>
            <div className="d-flex">
              {hiringNeed?.map(({ title, isActive }, index) => {
                return (
                  <Chip
                    title={title}
                    isActive={isActive}
                    handleChange={(e) => {
                      const et = {
                        currentTarget: {
                          value: hiringNeed?.map((d, internalIndex) => {
                            if (internalIndex === index) {
                              d.isActive = !d?.isActive;
                            }
                            return d;
                          }),
                          name: "hiringNeed",
                        },
                      };
                      onChange(et);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="position-relative mb-4 d-flex flex-column">
            <label htmlFor="name" className="form-label fs-base">
              Are you Seed or Venture capital funded?
            </label>
            <div className="d-flex">
              {seeds?.map(({ title, isActive }, internalIdex) => {
                return (
                  <Chip
                    title={title}
                    isActive={isActive}
                    handleChange={(e) => {
                      const t = {
                        currentTarget: {
                          name: "seedIndex",
                          value: internalIdex,
                        },
                      };
                      onChange(t);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="position-relative mb-4 d-flex flex-column">
            <label htmlFor="name" className="form-label fs-base">
              Are you a publicly listed Post/ IPO Company?
            </label>
            <div className="d-flex">
              {publiclisted?.map(({ title, isActive }, internalIdex) => {
                return (
                  <Chip
                    title={title}
                    isActive={isActive}
                    handleChange={(e) => {
                      const t = {
                        currentTarget: {
                          name: "publicListedIndex",
                          value: internalIdex,
                        },
                      };
                      onChange(t);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="position-relative mb-4 d-flex flex-column">
            <label htmlFor="name" className="form-label fs-base">
              What is your company size?
            </label>
            <div className="d-flex">
              {companysize?.map(({ title, isActive }, internalIdex) => {
                return (
                  <Chip
                    title={title}
                    isActive={isActive}
                    handleChange={(e) => {
                      const t = {
                        currentTarget: {
                          name: "companyIndex",
                          value: internalIdex,
                        },
                      };
                      onChange(t);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <Button
            className="w-75"
            onClick={handleContactClick}
            disabled={isDisabled}
          >
            Continue
          </Button>
        </div>
      </div>
    </form>
  );
  const body = bodyTemplate({
    content: content,
    title:
      "This will help us narrow down our search to start building your team...",
    subtitle: "We we need some details about yourself and your company.",
  });
  const header = HeaderTemplate({
    naviagteTo: "/employer/tellusmore",
    title: "Hiring Requirements..",
  });

  return <CustomCard CardBody={body} Header={header} />;
}

export default Hiring;
