import React from 'react'
import EmployerCandidateList from './EmployerCandidateList'

function Dashboard() {
  return (
    <div>
        <EmployerCandidateList />
    </div>
  )
}

export default Dashboard