import React from "react";

function Messages(props) {
  return (
    <div>
      <div
        style={{
          width: "55%",
          margin: "13% 26%",
          height: "100px",
          border: "1px solid rgba(166, 166, 166, 0.32)",
          // boxShadow: "0px 26px 51px rgba(0, 0, 0, 0.06)",
          boxShadow: "0px 8px 18px -8px rgba(99, 102, 241, 0.9)",
          borderRadius: "10px",
          padding: "3% 17%",
        }}
      >
        FEATURE COMING SOON.....
      </div>
    </div>
  );
}

export default Messages;
