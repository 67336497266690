import "./dashboard.scss";

import { RESET_AUTH, SET_SIGNUP_CREDS } from "../../Redux/Actions/types";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "react-bootstrap";
import PageContent from "./PageContent";
import React from "react";
import Steps from "../../Components/common/circularSteps/Steps";
import WhatyouGet from "./WhatyouGet";
import { getApi } from "../../utils/ApiMethods";
import leftTiltedVector from "../../Assets/img/tiltedArrow/leftTiltedVector.png";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Dashboard(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isProfileComplete } = useSelector((state) => state?.user);
  window.scrollTo(0, 0);
  const getUserAccountDetails = async () => {
    const response = await getApi(`accountDetails/userDetails`);
    if (response?.data?.status === 1) {
      const { data } = response?.data;
      dispatch({
        type: SET_SIGNUP_CREDS,
        payload: {
          isProfileComplete: data?.isProfileComplete,
          isProfile: data?.isProfile,
          isConfirm: data?.isConfirm,
        },
      });
    }
  };
  useEffect(() => {
    try {
      getUserAccountDetails();
    } catch (error) {
      dispatch({ type: RESET_AUTH });
      localStorage.removeItem("token");
      navigate("/sign-in");
    }
  }, []);
  return (
    <div className="dashboard">
      <div className="dashboard-heading">
        <h1>
          Find your <span style={{ color: "#6366f1" }}>New Job</span> today
        </h1>
        <label>
          Welcome to Visceral. To be eligible for jobs you must first complete
          your profile. Don't worry we'll guide you through the steps...
        </label>
      </div>
      <div className="dashboard-stages">
        <div className="stage stage-1">
          <Steps count="1" isActive={isProfileComplete} />
          <label className="stage-title">Complete your profile</label>
          <label className="stage-description">
            Provide us a little more information to match you with eligible jobs
          </label>
          {!isProfileComplete && (
            <Button onClick={() => navigate("/completeprofile")}>
              Complete Now
            </Button>
          )}
        </div>
        <div className="stage-dashed-line"></div>
        <div className="stage stage-2">
          <Steps count="2" />
          <label className="stage-title">Get Tested</label>
          <label className="stage-description">
            Take a test to view jobs you are being considered for
          </label>
          <Button
            onClick={() => navigate("/test")}
            disabled={!isProfileComplete}
          >
            Test Your Skills
          </Button>
        </div>
        <img
          className="leftArrow arrow"
          src={leftTiltedVector}
          alt="Girl in a jacket"
        />
      </div>
      <PageContent
        title={"Find what interests you and choose your test"}
        description={
          "Unreal Engine is big and beautiful, start with what you think you know and take any of our tests on that."
        }
      />
      <PageContent
        title={"Learn by doing"}
        description={
          "Confused where to start? Our tests are ordered in a way that helps you determine what stage of knowledge and practise you are, so you don’t get confused which part of the learning ladder you are on."
        }
      />
      <PageContent
        title={"Get instant results"}
        description="Our tests allow you to determine what your qualification level is, and you get a certificate to show for it."
      />
      <PageContent
        title={"Put your learning into practice, find your dream job!"}
        description={
          "Our team at Visceral helps you be hired through remote work. Our world class platform is your desktop in the cloud, where you can collaborate with peers or fly solo! We take care of all the billing, customer and project management. Cha Ching!"
        }
      />
      <WhatyouGet />
    </div>
  );
}

export default Dashboard;
